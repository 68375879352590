export default [
  {
    label: 'Lori Cassone',
    alias: 'Lori J. Cassone',
    value: 'Cassone, Lori Jean',
    license: 990975,
    states: ['CT', 'MA'],
  },
  {
    label: 'Mark Harrington',
    alias: 'Mark Harrington',
    value: 'Harrington, Mark',
    license: 121586,
    states: ['CT'],
  },
  {
    label: 'Paula Recine',
    alias: 'Paula Recine',
    value: 'Recine, Paula Luzzi',
    license: 1894705,
    states: ['CT'],
  },
  {
    label: 'Samir Doshi',
    alias: 'Samir Doshi',
    value: 'Doshi, Samir Rajnikant',
    license: 96256,
    states: ['CT'],
  },
];
