import { isEmpty } from 'lodash';
import { LoanStatus } from '../constants/enums';
import {
  amortizationTypeOptions,
  applicationTypeOptions,
  countryOptions,
  documentationTypeOptions,
  financedUnitCountOptions,
  lenderOptions,
  lienPriorityTypeOptions,
  loanPurposeOptions,
  loanStatusOptions,
  loanTypeOptions,
  mortgageTypeOptions,
  propertyTypeOptions,
  receivedFromOptions,
  serviceLoanTypeOptions,
  stateOptions,
  usageTypeOptions,
  reverseMortgagePurposeOptions,
  reverseMortgageTypeOptions,
} from '../selectOptions';
import loanOfficerOptions from '../loanOriginators';
import { convertJSDateForServer, convertJSDateTimeForServer } from './dateUtils';
import { capitalizeString, isString } from './index';

export const updateFormState = (key, value, state, stateFn) => {
  stateFn({
    ...state,
    [key]: value,
  });
};

// When loanStatus is set to one of the following options:
// - Approved but not accepted,
// - Denied,
// - Withdrawn,
// - Incomplete,
// - Pre-approval requests denied,
// - Pre-approval requests approved but not accepted
// - Closed and funded
// should show modal
export const checkShouldShowLoanStatusWarning = (status) => {
  if (!status) return false;
  const { value } = status;
  switch (value) {
    case LoanStatus.In_Process.value:
      return false;
    default:
      return true;
  }
};

export const getInitialFileFormState = (data) => {
  const {
    applicationDate,
    closingDate,
    borrowerDob,
    loanStatus,
    lender,
    subjectPropType,
    mortgageType,
    lienPriorityType,
    loanPurpose,
    amortizationType,
    usageType,
    loanType,
    applicationType,
    documentationType,
    serviceLoanType,
    borrowerFirstName,
    borrowerLastName,
    borrowerPhone,
    borrowerEmail,
    borrowerAddress,
    borrowerCity,
    borrowerZip,
    borrowerState,
    borrowerCountry,
    coBorrowerFirstName,
    coBorrowerLastName,
    coBorrowerEmail,
    coBorrowerPhone,
    coBorrowerDob,
    subjectPropAddress,
    subjectPropCity,
    subjectPropCounty,
    subjectPropZip,
    subjectPropState,
    financedUnitCount,
    subjectPropValuation,
    interestRate,
    amortizationPeriod,
    term,
    loanAmount,
    loanAmountLocked,
    loanOriginatorName,
    closingLoanAmount,
    brokerFeesCollected,
    receivedFrom,
    isRepurchase,
    isHoepa,
    homeImprovement,
    createdAt,
    updatedAt,
    importDate,
    importFile,
    reverseMortgageType,
    reverseMortgagePurpose,
    isReverseMortgage,
    ...rest
  } = data;

  return {
    ...rest,
    applicationDate: applicationDate ?? null,
    closingDate: closingDate ?? null,
    borrowerDob: borrowerDob ?? null,
    loanStatus: loanStatusOptions.find((it) => it.value === loanStatus) ?? null,
    lender: lenderOptions.find((it) => it.value === lender) ?? null,
    subjectPropType: propertyTypeOptions.find((it) => it.value === subjectPropType) ?? null,
    usageType: usageTypeOptions.find((it) => it.value === usageType) ?? null,
    amortizationType: amortizationTypeOptions.find((it) => it.value === amortizationType) ?? null,
    mortgageType: mortgageTypeOptions.find((it) => it.value === mortgageType) ?? null,
    lienPriorityType: lienPriorityTypeOptions.find((it) => it.value === lienPriorityType) ?? null,
    loanPurpose: loanPurposeOptions.find((it) => it.value === loanPurpose) ?? null,
    loanType: loanTypeOptions.find((it) => it.value === loanType) ?? null,
    applicationType: applicationTypeOptions.find((it) => it.value === applicationType) ?? null,
    documentationType: documentationTypeOptions.find((it) => it.value === documentationType) ?? null,
    serviceLoanType: serviceLoanTypeOptions.find((it) => it.value === serviceLoanType) ?? null,
    financedUnitCount: financedUnitCountOptions.find((it) => it.value === financedUnitCount) ?? null,
    loanOriginatorName: loanOfficerOptions.find((it) => it.value === loanOriginatorName) ?? null,
    receivedFrom: receivedFromOptions.find((it) => it.value === receivedFrom) ?? null,
    borrowerState: stateOptions.find((it) => it.value === borrowerState) ?? null,
    borrowerCountry: countryOptions.find((it) => it.value === borrowerCountry) ?? null,
    subjectPropState: stateOptions.find((it) => it.value === subjectPropState) ?? null,
    reverseMortgageType: reverseMortgageTypeOptions.find((it) => it.value === reverseMortgageType) ?? null,
    reverseMortgagePurpose: reverseMortgagePurposeOptions.find((it) => it.value === reverseMortgagePurpose) ?? null,
    isRepurchase: isRepurchase ?? false,
    isHoepa: isHoepa ?? false,
    homeImprovement: homeImprovement ?? false,
    isReverseMortgage: isReverseMortgage ?? false,
    borrowerFirstName: borrowerFirstName ?? '',
    borrowerLastName: borrowerLastName ?? '',
    borrowerPhone: borrowerPhone ?? '',
    borrowerEmail: borrowerEmail ?? '',
    borrowerAddress: borrowerAddress ?? '',
    borrowerCity: borrowerCity ?? '',
    borrowerZip: borrowerZip ?? '',
    coBorrowerFirstName: coBorrowerFirstName ?? '',
    coBorrowerLastName: coBorrowerLastName ?? '',
    coBorrowerEmail: coBorrowerEmail ?? '',
    coBorrowerPhone: coBorrowerPhone ?? '',
    coBorrowerDob: coBorrowerDob ?? '',
    subjectPropAddress: subjectPropAddress ?? '',
    subjectPropCity: subjectPropCity ?? '',
    subjectPropCounty: subjectPropCounty ?? '',
    subjectPropZip: subjectPropZip ?? '',
    subjectPropValuation: subjectPropValuation ?? 0,
    interestRate: interestRate ?? 0,
    amortizationPeriod: amortizationPeriod ?? '',
    term: term ?? '',
    loanAmount: loanAmount ?? 0,
    loanAmountLocked: loanAmountLocked ?? false,
    closingLoanAmount: closingLoanAmount ?? 0,
    brokerFeesCollected: brokerFeesCollected ?? 0,
  };
};

const nameFields = ['borrowerFirstName', 'borrowerLastName', 'coBorrowerFirstName', 'coBorrowerLastName'];
const numberfields = ['financedUnitCount', 'amortizationPeriod'];
const floatFields = ['brokerFeesCollected', 'closingLoanAmount', 'loanAmount', 'subjectPropValuation'];
const dateFields = ['applicationDate', 'closingDate', 'borrowerDob', 'coBorrowerDob'];
const dateTimeFields = [];
const emailFields = ['borrowerEmail', 'coBorrowerEmail'];
const autoCompleteFields = [
  'loanStatus',
  'lender',
  'subjectPropType',
  'usageType',
  'amortizationType',
  'mortgageType',
  'lienPriorityType',
  'loanPurpose',
  'loanType',
  'applicationType',
  'documentationType',
  'serviceLoanType',
  'financedUnitCount',
  'loanOriginatorName',
  'receivedFrom',
  'borrowerState',
  'borrowerCountry',
  'subjectPropState',
  'reverseMortgageType',
  'reverseMortgagePurpose',
];

export const normalizeData = (data, isReverseMortgage, isAdmin) => {
  const normalized = Object.keys(data).reduce((acc, nx) => {
    let value = data[nx] ?? null;
    if (autoCompleteFields.includes(nx)) {
      value = data[nx]?.value || null;
    }
    if (dateFields.includes(nx) && (!isString(value) || isEmpty(value))) {
      value = value ? convertJSDateForServer(value) : null;
    }
    if (dateTimeFields.includes(nx) && (!isString(value) || isEmpty(value))) {
      value = value ? convertJSDateTimeForServer(value) : null;
    }
    if (numberfields.includes(nx)) {
      const parsed = parseInt(value, 10);
      value = !Number.isNaN(parsed) ? parsed : 0;
    }
    if (floatFields.includes(nx)) {
      const parsed = parseFloat(value);
      value = !Number.isNaN(parsed) ? parsed : 0;
    }
    if (nameFields.includes(nx)) {
      value = value ? capitalizeString(value) : null;
    }
    if (emailFields.includes(nx)) {
      value = value || null;
    }
    return {
      ...acc,
      [nx]: value,
    };
  }, {});
  const { isLocked, ...rest } = normalized;
  return {
    ...rest,
    ...(isAdmin ? { isLocked } : {}),
    isReverseMortgage,
  };
};
