import React, { useState, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import { API, graphqlOperation } from 'aws-amplify';
import { Link, useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { Box, Button } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import { updateFile } from '../graphql/mutations';
import { useAuthContext } from '../context/AuthContext';
import { useFileContext } from '../context/FileContext';
import { useFileMutateContext } from '../context/FileMutateContext';
import ActionModal from './ActionModal';
import { stringBooleanToBool } from '../utils/enumUtils';
import { cacheKeys } from '../conf';
import { StringBoolean } from '../constants/enums';

const unlockFile = async (id, tenantId, queryClient, navigate) => {
  const res = await API.graphql(
    graphqlOperation(updateFile, {
      input: {
        id,
        isLocked: false,
      },
    })
  );
  if (res && res.data) {
    // setIsLocked(false);
    // setIsEditable(true);
    queryClient.invalidateQueries([cacheKeys.getFiles, tenantId]);
    navigate(`/file/${id}/edit`);
  }
};

const PageActionsFile = (props) => {
  const { data, isLoading, mode, formId } = props;
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { tenantId, isAdmin } = useAuthContext();
  const { deleteFile } = useFileMutateContext();
  const { checkFileDeleteStatus } = useFileContext();
  const {
    id,
    isLocked: iL,
    isDeleted: iD,
  } = mode === 'VIEW' || mode === 'EDIT'
    ? data
    : { id: uuidv4(), isLocked: false, isDeleted: StringBoolean.False.value };

  const isDeleted = useMemo(() => (isLoading ? false : stringBooleanToBool(iD)), [iD, isLoading]);
  const isEditable = useMemo(() => (mode === 'VIEW' ? false : !iL), [iL, mode]);
  const isLocked = useMemo(() => iL, [iL]);

  // Set file delete modal state
  const [fileDeleteModalOpen, setFileDeleteModalOpen] = useState(false);
  const [isDeletable, setIsDeletable] = useState(true);

  // When "Delete" is clicked show modal with these props
  const fileDeleteModalProps = useMemo(
    () => ({
      modalCtaColor: 'error',
      modalCtaText: 'Permanently Delete File',
      modalMessage: isDeletable
        ? 'Deleting this file will remove it from the system and it will not be able to be retrieved'
        : 'You cannot delete this file because it has been included in a report',
      modalMessageType: 'error',
      modalTextAlign: 'center',
      modalWidth: '500px',
      modalOpen: fileDeleteModalOpen,
      modalOnClick: () => {
        setFileDeleteModalOpen(false);
        if (isDeletable) {
          deleteFile(data);
        }
      },
      modalOnCancel: () => setFileDeleteModalOpen(false),
      showCta: isDeletable,
      modalCancelText: isDeletable ? 'Cancel' : 'Close',
    }),
    [data, deleteFile, fileDeleteModalOpen, isDeletable]
  );

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: '20px',
          '.MuiButton-root': {
            marginLeft: '15px',
          },
        }}
      >
        {
          // Delete button
          isAdmin && !isDeleted && isEditable && mode === 'EDIT' ? (
            <Button
              variant="contained"
              startIcon={<DeleteOutlinedIcon />}
              color="error"
              disableElevation
              onClick={async () => {
                const isDeletableCheck = await checkFileDeleteStatus(id);
                setIsDeletable(isDeletableCheck);
                setFileDeleteModalOpen(true);
              }}
              disabled={isLoading}
            >
              Delete
            </Button>
          ) : (
            ''
          )
        }
        {
          // Edit button for all users
          !isLocked && !isEditable && mode === 'VIEW' ? (
            <Button variant="contained" disableElevation component={Link} to={`/file/${id}/edit`} disabled={isLoading}>
              Edit
            </Button>
          ) : (
            ''
          )
        }
        {
          // Cancel button
          isEditable && (mode === 'EDIT' || mode === 'CREATE') ? (
            <Button
              variant="outlined"
              disableElevation
              component={Link}
              to={mode === 'EDIT' ? `/file/${id}` : '/'}
              disabled={isLoading}
            >
              Cancel
            </Button>
          ) : (
            ''
          )
        }
        {
          // Save button
          isEditable && (mode === 'EDIT' || mode === 'CREATE') ? (
            <Button variant="contained" disableElevation type="submit" form={formId} disabled={isLoading}>
              {mode === 'EDIT' ? 'Save' : 'Create'}
            </Button>
          ) : (
            ''
          )
        }
        {
          // Unlock and edit button for admins
          isAdmin && isLocked && !isEditable && mode === 'VIEW' ? (
            <Button
              variant="contained"
              startIcon={<LockOpenOutlinedIcon />}
              disableElevation
              onClick={() => unlockFile(id, tenantId, queryClient, navigate)}
              disabled={isLoading}
            >
              Unlock and Edit
            </Button>
          ) : (
            ''
          )
        }
      </Box>
      <ActionModal {...fileDeleteModalProps} />
    </>
  );
};

PageActionsFile.propTypes = {
  mode: PropTypes.oneOf(['VIEW', 'EDIT', 'CREATE']).isRequired,
  isLoading: PropTypes.bool.isRequired,
  formId: PropTypes.string.isRequired,
  data: PropTypes.shape(),
  doSubmit: PropTypes.func,
};

PageActionsFile.defaultProps = {
  data: {},
  doSubmit: null,
};

export default PageActionsFile;
