// import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import conf from 'conf/index';

const { CancelToken } = axios;

export const axiosCancelSource = () => CancelToken.source();

export const authenticatedAxios = async (customHeaders = {}) => {
  let client = null;
  try {
    const session = await Auth.currentSession();
    const {
      idToken: { jwtToken },
    } = session;

    client = axios.create({
      baseURL: conf.xhr.baseUrl,
      headers: {
        ...conf.xhr.headers,
        ...customHeaders,
        Authorization: `Bearer ${jwtToken}`,
      },
    });
  } catch {
    // console.error(err);
  }
  return client;
};

export const openAxios = async (customHeaders = {}, customConf = {}) => {
  let client = null;
  try {
    client = axios.create({
      baseURL: conf.xhr.baseUrl,
      headers: {
        ...conf.xhr.headers,
        ...customHeaders,
      },
      ...customConf,
    });
  } catch {
    // console.error(err);
  }
  return client;
};
