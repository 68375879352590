/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getTenant = /* GraphQL */ `
  query GetTenant($id: ID!) {
    getTenant(id: $id) {
      id
      companyName
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const listTenants = /* GraphQL */ `
  query ListTenants($filter: ModelTenantFilterInput, $limit: Int, $nextToken: String) {
    listTenants(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyName
        isDeleted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      roles
      tenants
      userName
      nickName
      isSystemAdmin
      isAdmin
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers($filter: ModelUserFilterInput, $limit: Int, $nextToken: String) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        roles
        tenants
        userName
        nickName
        isSystemAdmin
        isAdmin
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFile = /* GraphQL */ `
  query GetFile($id: ID!) {
    getFile(id: $id) {
      id
      tenantId
      isDeleted
      borrowerFirstName
      borrowerLastName
      interestRate
      loanOriginatorName
      applicationDate
      closingDate
      loanType
      lender
      borrowerDob
      borrowerEmail
      borrowerAddress
      borrowerCity
      borrowerCountry
      borrowerPhone
      borrowerState
      borrowerZip
      coBorrowerFirstName
      coBorrowerLastName
      coBorrowerEmail
      coBorrowerPhone
      coBorrowerDob
      amortizationType
      applicationType
      documentationType
      lienPriorityType
      loanPurpose
      loanStatus
      mortgageType
      receivedFrom
      reverseMortgagePurpose
      reverseMortgageType
      serviceLoanType
      usageType
      financedUnitCount
      amortizationPeriod
      brokerFeesCollected
      closingLoanAmount
      loanAmount
      subjectPropValuation
      isHoepa
      homeImprovement
      includedInReport
      isReverseMortgage
      loanAmountLocked
      isRepurchase
      term
      subjectPropAddress
      subjectPropCity
      subjectPropCounty
      subjectPropState
      subjectPropZip
      subjectPropType
      importDate
      isLocked
      importFile
      createdAt
      updatedAt
      statusLogs {
        nextToken
      }
      reports {
        nextToken
      }
    }
  }
`;
export const listFiles = /* GraphQL */ `
  query ListFiles($filter: ModelFileFilterInput, $limit: Int, $nextToken: String) {
    listFiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        isDeleted
        borrowerFirstName
        borrowerLastName
        interestRate
        loanOriginatorName
        applicationDate
        closingDate
        loanType
        lender
        borrowerDob
        borrowerEmail
        borrowerAddress
        borrowerCity
        borrowerCountry
        borrowerPhone
        borrowerState
        borrowerZip
        coBorrowerFirstName
        coBorrowerLastName
        coBorrowerEmail
        coBorrowerPhone
        coBorrowerDob
        amortizationType
        applicationType
        documentationType
        lienPriorityType
        loanPurpose
        loanStatus
        mortgageType
        receivedFrom
        reverseMortgagePurpose
        reverseMortgageType
        serviceLoanType
        usageType
        financedUnitCount
        amortizationPeriod
        brokerFeesCollected
        closingLoanAmount
        loanAmount
        subjectPropValuation
        isHoepa
        homeImprovement
        includedInReport
        isReverseMortgage
        loanAmountLocked
        isRepurchase
        term
        subjectPropAddress
        subjectPropCity
        subjectPropCounty
        subjectPropState
        subjectPropZip
        subjectPropType
        importDate
        isLocked
        importFile
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listFilesByTenant = /* GraphQL */ `
  query ListFilesByTenant(
    $tenantId: ID
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFilesByTenant(
      tenantId: $tenantId
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        isDeleted
        borrowerFirstName
        borrowerLastName
        interestRate
        loanOriginatorName
        applicationDate
        closingDate
        loanType
        lender
        borrowerDob
        borrowerEmail
        borrowerAddress
        borrowerCity
        borrowerCountry
        borrowerPhone
        borrowerState
        borrowerZip
        coBorrowerFirstName
        coBorrowerLastName
        coBorrowerEmail
        coBorrowerPhone
        coBorrowerDob
        amortizationType
        applicationType
        documentationType
        lienPriorityType
        loanPurpose
        loanStatus
        mortgageType
        receivedFrom
        reverseMortgagePurpose
        reverseMortgageType
        serviceLoanType
        usageType
        financedUnitCount
        amortizationPeriod
        brokerFeesCollected
        closingLoanAmount
        loanAmount
        subjectPropValuation
        isHoepa
        homeImprovement
        includedInReport
        isReverseMortgage
        loanAmountLocked
        isRepurchase
        term
        subjectPropAddress
        subjectPropCity
        subjectPropCounty
        subjectPropState
        subjectPropZip
        subjectPropType
        importDate
        isLocked
        importFile
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listFilesByTenantWithLogs = /* GraphQL */ `
  query ListFilesByTenant(
    $tenantId: ID
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFilesByTenant(
      tenantId: $tenantId
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        isDeleted
        borrowerFirstName
        borrowerLastName
        interestRate
        loanOriginatorName
        applicationDate
        closingDate
        loanType
        lender
        borrowerDob
        borrowerEmail
        borrowerAddress
        borrowerCity
        borrowerCountry
        borrowerPhone
        borrowerState
        borrowerZip
        coBorrowerFirstName
        coBorrowerLastName
        coBorrowerEmail
        coBorrowerPhone
        coBorrowerDob
        amortizationType
        applicationType
        documentationType
        lienPriorityType
        loanPurpose
        loanStatus
        mortgageType
        receivedFrom
        reverseMortgagePurpose
        reverseMortgageType
        serviceLoanType
        usageType
        financedUnitCount
        amortizationPeriod
        brokerFeesCollected
        closingLoanAmount
        loanAmount
        subjectPropValuation
        isHoepa
        homeImprovement
        includedInReport
        isReverseMortgage
        loanAmountLocked
        isRepurchase
        term
        subjectPropAddress
        subjectPropCity
        subjectPropCounty
        subjectPropState
        subjectPropZip
        subjectPropType
        importDate
        isLocked
        importFile
        createdAt
        updatedAt
        statusLogs {
          items {
            id
            tenantId
            dateTime
            state
            loanStatus
            isDeleted
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const searchFiles = /* GraphQL */ `
  query SearchFiles(
    $tenantId: ID
    $isDeleted: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    searchFiles(
      tenantId: $tenantId
      isDeleted: $isDeleted
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        isDeleted
        borrowerFirstName
        borrowerLastName
        interestRate
        loanOriginatorName
        applicationDate
        closingDate
        loanType
        lender
        borrowerDob
        borrowerEmail
        borrowerAddress
        borrowerCity
        borrowerCountry
        borrowerPhone
        borrowerState
        borrowerZip
        coBorrowerFirstName
        coBorrowerLastName
        coBorrowerEmail
        coBorrowerPhone
        coBorrowerDob
        amortizationType
        applicationType
        documentationType
        lienPriorityType
        loanPurpose
        loanStatus
        mortgageType
        receivedFrom
        reverseMortgagePurpose
        reverseMortgageType
        serviceLoanType
        usageType
        financedUnitCount
        amortizationPeriod
        brokerFeesCollected
        closingLoanAmount
        loanAmount
        subjectPropValuation
        isHoepa
        homeImprovement
        includedInReport
        isReverseMortgage
        loanAmountLocked
        isRepurchase
        term
        subjectPropAddress
        subjectPropCity
        subjectPropCounty
        subjectPropState
        subjectPropZip
        subjectPropType
        importDate
        isLocked
        importFile
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFileStatusLog = /* GraphQL */ `
  query GetFileStatusLog($id: ID!) {
    getFileStatusLog(id: $id) {
      id
      tenantId
      fileId
      dateTime
      state
      loanStatus
      isDeleted
      createdAt
      updatedAt
      file {
        id
        tenantId
        isDeleted
        borrowerFirstName
        borrowerLastName
        interestRate
        loanOriginatorName
        applicationDate
        closingDate
        loanType
        lender
        borrowerDob
        borrowerEmail
        borrowerAddress
        borrowerCity
        borrowerCountry
        borrowerPhone
        borrowerState
        borrowerZip
        coBorrowerFirstName
        coBorrowerLastName
        coBorrowerEmail
        coBorrowerPhone
        coBorrowerDob
        amortizationType
        applicationType
        documentationType
        lienPriorityType
        loanPurpose
        loanStatus
        mortgageType
        receivedFrom
        reverseMortgagePurpose
        reverseMortgageType
        serviceLoanType
        usageType
        financedUnitCount
        amortizationPeriod
        brokerFeesCollected
        closingLoanAmount
        loanAmount
        subjectPropValuation
        isHoepa
        homeImprovement
        includedInReport
        isReverseMortgage
        loanAmountLocked
        isRepurchase
        term
        subjectPropAddress
        subjectPropCity
        subjectPropCounty
        subjectPropState
        subjectPropZip
        subjectPropType
        importDate
        isLocked
        importFile
        createdAt
        updatedAt
      }
    }
  }
`;
export const listFileStatusLogs = /* GraphQL */ `
  query ListFileStatusLogs($filter: ModelFileStatusLogFilterInput, $limit: Int, $nextToken: String) {
    listFileStatusLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        fileId
        dateTime
        state
        loanStatus
        isDeleted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listFileStatusLog = /* GraphQL */ `
  query ListFileStatusLog(
    $tenantId: ID
    $dateTimeLoanStatus: ModelFileStatusLogFileStatusLogQueryCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFileStatusLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFileStatusLog(
      tenantId: $tenantId
      dateTimeLoanStatus: $dateTimeLoanStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        fileId
        dateTime
        state
        loanStatus
        isDeleted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listFileStatusLogByFileId = /* GraphQL */ `
  query ListFileStatusLogByFileId(
    $tenantId: ID
    $fileId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFileStatusLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFileStatusLogByFileId(
      tenantId: $tenantId
      fileId: $fileId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        fileId
        dateTime
        state
        loanStatus
        isDeleted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getReport = /* GraphQL */ `
  query GetReport($id: ID!) {
    getReport(id: $id) {
      id
      tenantId
      state
      quarter
      year
      reportDateTime
      submittedDateTime
      data
      loansInProcess
      loansClosed
      isSubmitted
      isDeleted
      reportXMLFile
      reportPDFFile
      createdAt
      updatedAt
      files {
        nextToken
      }
    }
  }
`;
export const listReports = /* GraphQL */ `
  query ListReports($filter: ModelReportFilterInput, $limit: Int, $nextToken: String) {
    listReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        state
        quarter
        year
        reportDateTime
        submittedDateTime
        data
        loansInProcess
        loansClosed
        isSubmitted
        isDeleted
        reportXMLFile
        reportPDFFile
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listReportsByTenant = /* GraphQL */ `
  query ListReportsByTenant(
    $tenantId: ID
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReportsByTenant(
      tenantId: $tenantId
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        state
        quarter
        year
        reportDateTime
        submittedDateTime
        data
        loansInProcess
        loansClosed
        isSubmitted
        isDeleted
        reportXMLFile
        reportPDFFile
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchReports = /* GraphQL */ `
  query SearchReports(
    $tenantId: ID
    $isDeletedStateReportDateTimeIsSubmitted: ModelReportReportSearchCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    searchReports(
      tenantId: $tenantId
      isDeletedStateReportDateTimeIsSubmitted: $isDeletedStateReportDateTimeIsSubmitted
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        state
        quarter
        year
        reportDateTime
        submittedDateTime
        data
        loansInProcess
        loansClosed
        isSubmitted
        isDeleted
        reportXMLFile
        reportPDFFile
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getReportFile = /* GraphQL */ `
  query GetReportFile($id: ID!) {
    getReportFile(id: $id) {
      id
      tenantId
      reportId
      fileId
      isDeleted
      createdAt
      updatedAt
      file {
        id
        tenantId
        isDeleted
        borrowerFirstName
        borrowerLastName
        interestRate
        loanOriginatorName
        applicationDate
        closingDate
        loanType
        lender
        borrowerDob
        borrowerEmail
        borrowerAddress
        borrowerCity
        borrowerCountry
        borrowerPhone
        borrowerState
        borrowerZip
        coBorrowerFirstName
        coBorrowerLastName
        coBorrowerEmail
        coBorrowerPhone
        coBorrowerDob
        amortizationType
        applicationType
        documentationType
        lienPriorityType
        loanPurpose
        loanStatus
        mortgageType
        receivedFrom
        reverseMortgagePurpose
        reverseMortgageType
        serviceLoanType
        usageType
        financedUnitCount
        amortizationPeriod
        brokerFeesCollected
        closingLoanAmount
        loanAmount
        subjectPropValuation
        isHoepa
        homeImprovement
        includedInReport
        isReverseMortgage
        loanAmountLocked
        isRepurchase
        term
        subjectPropAddress
        subjectPropCity
        subjectPropCounty
        subjectPropState
        subjectPropZip
        subjectPropType
        importDate
        isLocked
        importFile
        createdAt
        updatedAt
      }
      report {
        id
        tenantId
        state
        quarter
        year
        reportDateTime
        submittedDateTime
        data
        loansInProcess
        loansClosed
        isSubmitted
        isDeleted
        reportXMLFile
        reportPDFFile
        createdAt
        updatedAt
      }
    }
  }
`;
export const listReportFiles = /* GraphQL */ `
  query ListReportFiles($filter: ModelReportFileFilterInput, $limit: Int, $nextToken: String) {
    listReportFiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        reportId
        fileId
        isDeleted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listReportFilesByTenant = /* GraphQL */ `
  query ListReportFilesByTenant(
    $tenantId: ID
    $isDeleted: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReportFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReportFilesByTenant(
      tenantId: $tenantId
      isDeleted: $isDeleted
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        reportId
        fileId
        isDeleted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listReportFilesByReportId = /* GraphQL */ `
  query ListReportFilesByReportId(
    $tenantId: ID
    $reportId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReportFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReportFilesByReportId(
      tenantId: $tenantId
      reportId: $reportId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        reportId
        fileId
        isDeleted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listReportFilesByFileId = /* GraphQL */ `
  query ListReportFilesByFileId(
    $tenantId: ID
    $fileId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReportFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReportFilesByFileId(
      tenantId: $tenantId
      fileId: $fileId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        reportId
        fileId
        isDeleted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
