import React from 'react';
import FormImport from '../forms/FormImport';
import Main from '../components/Main';
import TableImports from '../tables/TableImports';
import { FileImportProvider } from '../context/FileImportContext';

const Imports = () => (
  <Main pagetitle="Imports">
    <FileImportProvider>
      <FormImport />
    </FileImportProvider>
    <TableImports />
  </Main>
);

export default Imports;
