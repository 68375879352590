import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Storage } from 'aws-amplify';
import Button from '@mui/material/Button';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { DataGrid } from '@mui/x-data-grid';
import { Checkbox, FormControlLabel, Paper } from '@mui/material';
import { useAuthContext } from '../context/AuthContext';
import { getFormattedDate } from '../utils/dateUtils';
import { stringBooleanToBool } from '../utils/enumUtils';
import { useReportContext } from '../context/ReportContext';

const TableReports = (props) => {
  const { isAdmin } = useAuthContext();
  const { isLoading, isUpdating, getReports, submitReport } = useReportContext();
  const { locked } = props;

  // Sets initial table page size on load
  const [pageSize, setPageSize] = useState(10);

  const [isDownloading, setIsDownloading] = useState(false);

  // Table columns
  const reportsTableColumns = useMemo(
    () => [
      {
        field: 'reportDateTime',
        type: 'date',
        headerName: 'Report date',
        flex: 1,
        renderCell: (val) => getFormattedDate(val.value),
      },
      {
        field: 'year',
        headerName: 'Year',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'quarter',
        headerName: 'Quarter',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'state',
        headerName: 'State',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'loansInProcess',
        headerName: 'In process',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'loansClosed',
        headerName: 'Closed and funded',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'isSubmitted',
        type: 'boolean',
        headerName: 'Submitted',
        flex: 1,
        minWidth: 95,
        disableClickEventBubbling: true,
        align: 'center',
        renderCell: (cellValues) => {
          const isSubmitted = stringBooleanToBool(cellValues.value);

          if (!isSubmitted) {
            return (
              <FormControlLabel
                label=""
                control={<Checkbox checked={isSubmitted} />}
                onChange={async () => {
                  await submitReport(cellValues.row, !isSubmitted);
                }}
              />
            );
          }

          if (isSubmitted) {
            if (locked || !isAdmin) {
              return (
                <CheckBoxIcon
                  color="disabled"
                  sx={{
                    margin: '9px',
                  }}
                />
              );
            }
            return (
              <FormControlLabel
                label=""
                control={<Checkbox checked={isSubmitted} />}
                onChange={async () => {
                  await submitReport(cellValues.row, !isSubmitted);
                }}
                disabled={locked}
              />
            );
          }
          return null;
        },
      },
      {
        field: 'downloadPdf',
        headerName: '',
        width: 160,
        disableClickEventBubbling: true,
        align: 'center',
        headerClassName: 'hide-children',
        renderCell: (cellValues) => {
          const { reportPDFFile } = cellValues.row;
          return reportPDFFile ? (
            <Button
              variant="contained"
              disableElevation
              onClick={async () => {
                setIsDownloading(true);
                const uri = await Storage.get(reportPDFFile, {
                  level: 'public',
                  contentType: 'application/pdf',
                  download: false,
                });
                setIsDownloading(false);
                window.open(uri, '_blank');
              }}
            >
              Download PDF
            </Button>
          ) : null;
        },
      },
    ],
    [isAdmin, locked, submitReport]
  );

  return (
    <Paper
      sx={{
        minHeight: '300px',
        position: 'relative',

        // Tables
        '.MuiDataGrid-root': {
          '.MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
            '&:focus, &:focus-within': {
              outline: 'none',
            },
          },
          '.table-heading--empty *': {
            display: 'none',
          },
          '.MuiDataGrid-cell--textLeft': {
            paddingLeft: '16px',
          },
          '.MuiDataGrid-cell--textRight': {
            paddingLeft: '16px',
          },
          '.MuiDataGrid-columnsContainer': {
            borderRadius: '4px 4px 0 0',
            backgroundColor: '#dbdbdb',
          },
          '.MuiFormControlLabel-root': {
            margin: 0,
          },
          '.MuiDataGrid-overlay': {
            paddingTop: '64px',
          },
        },
      }}
    >
      <DataGrid
        loading={isLoading || isUpdating || isDownloading}
        columns={reportsTableColumns}
        rows={getReports}
        disableSelectionOnClick
        rowHeight={55}
        autoHeight
        pageSize={pageSize}
        onPageSizeChange={(p) => setPageSize(p)}
        rowsPerPageOptions={[10, 25, 50, 100]}
        pagination
      />
    </Paper>
  );
};

TableReports.propTypes = {
  locked: PropTypes.bool.isRequired,
};

export default TableReports;
