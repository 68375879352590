/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createTenant = /* GraphQL */ `
  mutation CreateTenant($input: CreateTenantInput!, $condition: ModelTenantConditionInput) {
    createTenant(input: $input, condition: $condition) {
      id
      companyName
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const updateTenant = /* GraphQL */ `
  mutation UpdateTenant($input: UpdateTenantInput!, $condition: ModelTenantConditionInput) {
    updateTenant(input: $input, condition: $condition) {
      id
      companyName
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const deleteTenant = /* GraphQL */ `
  mutation DeleteTenant($input: DeleteTenantInput!, $condition: ModelTenantConditionInput) {
    deleteTenant(input: $input, condition: $condition) {
      id
      companyName
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser($input: CreateUserInput!, $condition: ModelUserConditionInput) {
    createUser(input: $input, condition: $condition) {
      id
      roles
      tenants
      userName
      nickName
      isSystemAdmin
      isAdmin
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput!, $condition: ModelUserConditionInput) {
    updateUser(input: $input, condition: $condition) {
      id
      roles
      tenants
      userName
      nickName
      isSystemAdmin
      isAdmin
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser($input: DeleteUserInput!, $condition: ModelUserConditionInput) {
    deleteUser(input: $input, condition: $condition) {
      id
      roles
      tenants
      userName
      nickName
      isSystemAdmin
      isAdmin
      createdAt
      updatedAt
    }
  }
`;
export const createFile = /* GraphQL */ `
  mutation CreateFile($input: CreateFileInput!, $condition: ModelFileConditionInput) {
    createFile(input: $input, condition: $condition) {
      id
      tenantId
      isDeleted
      borrowerFirstName
      borrowerLastName
      interestRate
      loanOriginatorName
      applicationDate
      closingDate
      loanType
      lender
      borrowerDob
      borrowerEmail
      borrowerAddress
      borrowerCity
      borrowerCountry
      borrowerPhone
      borrowerState
      borrowerZip
      coBorrowerFirstName
      coBorrowerLastName
      coBorrowerEmail
      coBorrowerPhone
      coBorrowerDob
      amortizationType
      applicationType
      documentationType
      lienPriorityType
      loanPurpose
      loanStatus
      mortgageType
      receivedFrom
      reverseMortgagePurpose
      reverseMortgageType
      serviceLoanType
      usageType
      financedUnitCount
      amortizationPeriod
      brokerFeesCollected
      closingLoanAmount
      loanAmount
      subjectPropValuation
      isHoepa
      homeImprovement
      includedInReport
      isReverseMortgage
      loanAmountLocked
      isRepurchase
      term
      subjectPropAddress
      subjectPropCity
      subjectPropCounty
      subjectPropState
      subjectPropZip
      subjectPropType
      importDate
      isLocked
      importFile
      createdAt
      updatedAt
      statusLogs {
        nextToken
      }
      reports {
        nextToken
      }
    }
  }
`;
export const updateFile = /* GraphQL */ `
  mutation UpdateFile($input: UpdateFileInput!, $condition: ModelFileConditionInput) {
    updateFile(input: $input, condition: $condition) {
      id
      tenantId
      isDeleted
      borrowerFirstName
      borrowerLastName
      interestRate
      loanOriginatorName
      applicationDate
      closingDate
      loanType
      lender
      borrowerDob
      borrowerEmail
      borrowerAddress
      borrowerCity
      borrowerCountry
      borrowerPhone
      borrowerState
      borrowerZip
      coBorrowerFirstName
      coBorrowerLastName
      coBorrowerEmail
      coBorrowerPhone
      coBorrowerDob
      amortizationType
      applicationType
      documentationType
      lienPriorityType
      loanPurpose
      loanStatus
      mortgageType
      receivedFrom
      reverseMortgagePurpose
      reverseMortgageType
      serviceLoanType
      usageType
      financedUnitCount
      amortizationPeriod
      brokerFeesCollected
      closingLoanAmount
      loanAmount
      subjectPropValuation
      isHoepa
      homeImprovement
      includedInReport
      isReverseMortgage
      loanAmountLocked
      isRepurchase
      term
      subjectPropAddress
      subjectPropCity
      subjectPropCounty
      subjectPropState
      subjectPropZip
      subjectPropType
      importDate
      isLocked
      importFile
      createdAt
      updatedAt
      statusLogs {
        nextToken
      }
      reports {
        nextToken
      }
    }
  }
`;
export const deleteFile = /* GraphQL */ `
  mutation DeleteFile($input: DeleteFileInput!, $condition: ModelFileConditionInput) {
    deleteFile(input: $input, condition: $condition) {
      id
      tenantId
      isDeleted
      borrowerFirstName
      borrowerLastName
      interestRate
      loanOriginatorName
      applicationDate
      closingDate
      loanType
      lender
      borrowerDob
      borrowerEmail
      borrowerAddress
      borrowerCity
      borrowerCountry
      borrowerPhone
      borrowerState
      borrowerZip
      coBorrowerFirstName
      coBorrowerLastName
      coBorrowerEmail
      coBorrowerPhone
      coBorrowerDob
      amortizationType
      applicationType
      documentationType
      lienPriorityType
      loanPurpose
      loanStatus
      mortgageType
      receivedFrom
      reverseMortgagePurpose
      reverseMortgageType
      serviceLoanType
      usageType
      financedUnitCount
      amortizationPeriod
      brokerFeesCollected
      closingLoanAmount
      loanAmount
      subjectPropValuation
      isHoepa
      homeImprovement
      includedInReport
      isReverseMortgage
      loanAmountLocked
      isRepurchase
      term
      subjectPropAddress
      subjectPropCity
      subjectPropCounty
      subjectPropState
      subjectPropZip
      subjectPropType
      importDate
      isLocked
      importFile
      createdAt
      updatedAt
      statusLogs {
        nextToken
      }
      reports {
        nextToken
      }
    }
  }
`;
export const createFileStatusLog = /* GraphQL */ `
  mutation CreateFileStatusLog($input: CreateFileStatusLogInput!, $condition: ModelFileStatusLogConditionInput) {
    createFileStatusLog(input: $input, condition: $condition) {
      id
      tenantId
      fileId
      dateTime
      state
      loanStatus
      isDeleted
      createdAt
      updatedAt
      file {
        id
        tenantId
        isDeleted
        borrowerFirstName
        borrowerLastName
        interestRate
        loanOriginatorName
        applicationDate
        closingDate
        loanType
        lender
        borrowerDob
        borrowerEmail
        borrowerAddress
        borrowerCity
        borrowerCountry
        borrowerPhone
        borrowerState
        borrowerZip
        coBorrowerFirstName
        coBorrowerLastName
        coBorrowerEmail
        coBorrowerPhone
        coBorrowerDob
        amortizationType
        applicationType
        documentationType
        lienPriorityType
        loanPurpose
        loanStatus
        mortgageType
        receivedFrom
        reverseMortgagePurpose
        reverseMortgageType
        serviceLoanType
        usageType
        financedUnitCount
        amortizationPeriod
        brokerFeesCollected
        closingLoanAmount
        loanAmount
        subjectPropValuation
        isHoepa
        homeImprovement
        includedInReport
        isReverseMortgage
        loanAmountLocked
        isRepurchase
        term
        subjectPropAddress
        subjectPropCity
        subjectPropCounty
        subjectPropState
        subjectPropZip
        subjectPropType
        importDate
        isLocked
        importFile
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateFileStatusLog = /* GraphQL */ `
  mutation UpdateFileStatusLog($input: UpdateFileStatusLogInput!, $condition: ModelFileStatusLogConditionInput) {
    updateFileStatusLog(input: $input, condition: $condition) {
      id
      tenantId
      fileId
      dateTime
      state
      loanStatus
      isDeleted
      createdAt
      updatedAt
      file {
        id
        tenantId
        isDeleted
        borrowerFirstName
        borrowerLastName
        interestRate
        loanOriginatorName
        applicationDate
        closingDate
        loanType
        lender
        borrowerDob
        borrowerEmail
        borrowerAddress
        borrowerCity
        borrowerCountry
        borrowerPhone
        borrowerState
        borrowerZip
        coBorrowerFirstName
        coBorrowerLastName
        coBorrowerEmail
        coBorrowerPhone
        coBorrowerDob
        amortizationType
        applicationType
        documentationType
        lienPriorityType
        loanPurpose
        loanStatus
        mortgageType
        receivedFrom
        reverseMortgagePurpose
        reverseMortgageType
        serviceLoanType
        usageType
        financedUnitCount
        amortizationPeriod
        brokerFeesCollected
        closingLoanAmount
        loanAmount
        subjectPropValuation
        isHoepa
        homeImprovement
        includedInReport
        isReverseMortgage
        loanAmountLocked
        isRepurchase
        term
        subjectPropAddress
        subjectPropCity
        subjectPropCounty
        subjectPropState
        subjectPropZip
        subjectPropType
        importDate
        isLocked
        importFile
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteFileStatusLog = /* GraphQL */ `
  mutation DeleteFileStatusLog($input: DeleteFileStatusLogInput!, $condition: ModelFileStatusLogConditionInput) {
    deleteFileStatusLog(input: $input, condition: $condition) {
      id
      tenantId
      fileId
      dateTime
      state
      loanStatus
      isDeleted
      createdAt
      updatedAt
      file {
        id
        tenantId
        isDeleted
        borrowerFirstName
        borrowerLastName
        interestRate
        loanOriginatorName
        applicationDate
        closingDate
        loanType
        lender
        borrowerDob
        borrowerEmail
        borrowerAddress
        borrowerCity
        borrowerCountry
        borrowerPhone
        borrowerState
        borrowerZip
        coBorrowerFirstName
        coBorrowerLastName
        coBorrowerEmail
        coBorrowerPhone
        coBorrowerDob
        amortizationType
        applicationType
        documentationType
        lienPriorityType
        loanPurpose
        loanStatus
        mortgageType
        receivedFrom
        reverseMortgagePurpose
        reverseMortgageType
        serviceLoanType
        usageType
        financedUnitCount
        amortizationPeriod
        brokerFeesCollected
        closingLoanAmount
        loanAmount
        subjectPropValuation
        isHoepa
        homeImprovement
        includedInReport
        isReverseMortgage
        loanAmountLocked
        isRepurchase
        term
        subjectPropAddress
        subjectPropCity
        subjectPropCounty
        subjectPropState
        subjectPropZip
        subjectPropType
        importDate
        isLocked
        importFile
        createdAt
        updatedAt
      }
    }
  }
`;
export const createReport = /* GraphQL */ `
  mutation CreateReport($input: CreateReportInput!, $condition: ModelReportConditionInput) {
    createReport(input: $input, condition: $condition) {
      id
      tenantId
      state
      quarter
      year
      reportDateTime
      submittedDateTime
      data
      loansInProcess
      loansClosed
      isSubmitted
      isDeleted
      reportXMLFile
      reportPDFFile
      createdAt
      updatedAt
      files {
        nextToken
      }
    }
  }
`;
export const updateReport = /* GraphQL */ `
  mutation UpdateReport($input: UpdateReportInput!, $condition: ModelReportConditionInput) {
    updateReport(input: $input, condition: $condition) {
      id
      tenantId
      state
      quarter
      year
      reportDateTime
      submittedDateTime
      data
      loansInProcess
      loansClosed
      isSubmitted
      isDeleted
      reportXMLFile
      reportPDFFile
      createdAt
      updatedAt
      files {
        nextToken
      }
    }
  }
`;
export const deleteReport = /* GraphQL */ `
  mutation DeleteReport($input: DeleteReportInput!, $condition: ModelReportConditionInput) {
    deleteReport(input: $input, condition: $condition) {
      id
      tenantId
      state
      quarter
      year
      reportDateTime
      submittedDateTime
      data
      loansInProcess
      loansClosed
      isSubmitted
      isDeleted
      reportXMLFile
      reportPDFFile
      createdAt
      updatedAt
      files {
        nextToken
      }
    }
  }
`;
export const createReportFile = /* GraphQL */ `
  mutation CreateReportFile($input: CreateReportFileInput!, $condition: ModelReportFileConditionInput) {
    createReportFile(input: $input, condition: $condition) {
      id
      tenantId
      reportId
      fileId
      isDeleted
      createdAt
      updatedAt
      file {
        id
        tenantId
        isDeleted
        borrowerFirstName
        borrowerLastName
        interestRate
        loanOriginatorName
        applicationDate
        closingDate
        loanType
        lender
        borrowerDob
        borrowerEmail
        borrowerAddress
        borrowerCity
        borrowerCountry
        borrowerPhone
        borrowerState
        borrowerZip
        coBorrowerFirstName
        coBorrowerLastName
        coBorrowerEmail
        coBorrowerPhone
        coBorrowerDob
        amortizationType
        applicationType
        documentationType
        lienPriorityType
        loanPurpose
        loanStatus
        mortgageType
        receivedFrom
        reverseMortgagePurpose
        reverseMortgageType
        serviceLoanType
        usageType
        financedUnitCount
        amortizationPeriod
        brokerFeesCollected
        closingLoanAmount
        loanAmount
        subjectPropValuation
        isHoepa
        homeImprovement
        includedInReport
        isReverseMortgage
        loanAmountLocked
        isRepurchase
        term
        subjectPropAddress
        subjectPropCity
        subjectPropCounty
        subjectPropState
        subjectPropZip
        subjectPropType
        importDate
        isLocked
        importFile
        createdAt
        updatedAt
      }
      report {
        id
        tenantId
        state
        quarter
        year
        reportDateTime
        submittedDateTime
        data
        loansInProcess
        loansClosed
        isSubmitted
        isDeleted
        reportXMLFile
        reportPDFFile
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateReportFile = /* GraphQL */ `
  mutation UpdateReportFile($input: UpdateReportFileInput!, $condition: ModelReportFileConditionInput) {
    updateReportFile(input: $input, condition: $condition) {
      id
      tenantId
      reportId
      fileId
      isDeleted
      createdAt
      updatedAt
      file {
        id
        tenantId
        isDeleted
        borrowerFirstName
        borrowerLastName
        interestRate
        loanOriginatorName
        applicationDate
        closingDate
        loanType
        lender
        borrowerDob
        borrowerEmail
        borrowerAddress
        borrowerCity
        borrowerCountry
        borrowerPhone
        borrowerState
        borrowerZip
        coBorrowerFirstName
        coBorrowerLastName
        coBorrowerEmail
        coBorrowerPhone
        coBorrowerDob
        amortizationType
        applicationType
        documentationType
        lienPriorityType
        loanPurpose
        loanStatus
        mortgageType
        receivedFrom
        reverseMortgagePurpose
        reverseMortgageType
        serviceLoanType
        usageType
        financedUnitCount
        amortizationPeriod
        brokerFeesCollected
        closingLoanAmount
        loanAmount
        subjectPropValuation
        isHoepa
        homeImprovement
        includedInReport
        isReverseMortgage
        loanAmountLocked
        isRepurchase
        term
        subjectPropAddress
        subjectPropCity
        subjectPropCounty
        subjectPropState
        subjectPropZip
        subjectPropType
        importDate
        isLocked
        importFile
        createdAt
        updatedAt
      }
      report {
        id
        tenantId
        state
        quarter
        year
        reportDateTime
        submittedDateTime
        data
        loansInProcess
        loansClosed
        isSubmitted
        isDeleted
        reportXMLFile
        reportPDFFile
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteReportFile = /* GraphQL */ `
  mutation DeleteReportFile($input: DeleteReportFileInput!, $condition: ModelReportFileConditionInput) {
    deleteReportFile(input: $input, condition: $condition) {
      id
      tenantId
      reportId
      fileId
      isDeleted
      createdAt
      updatedAt
      file {
        id
        tenantId
        isDeleted
        borrowerFirstName
        borrowerLastName
        interestRate
        loanOriginatorName
        applicationDate
        closingDate
        loanType
        lender
        borrowerDob
        borrowerEmail
        borrowerAddress
        borrowerCity
        borrowerCountry
        borrowerPhone
        borrowerState
        borrowerZip
        coBorrowerFirstName
        coBorrowerLastName
        coBorrowerEmail
        coBorrowerPhone
        coBorrowerDob
        amortizationType
        applicationType
        documentationType
        lienPriorityType
        loanPurpose
        loanStatus
        mortgageType
        receivedFrom
        reverseMortgagePurpose
        reverseMortgageType
        serviceLoanType
        usageType
        financedUnitCount
        amortizationPeriod
        brokerFeesCollected
        closingLoanAmount
        loanAmount
        subjectPropValuation
        isHoepa
        homeImprovement
        includedInReport
        isReverseMortgage
        loanAmountLocked
        isRepurchase
        term
        subjectPropAddress
        subjectPropCity
        subjectPropCounty
        subjectPropState
        subjectPropZip
        subjectPropType
        importDate
        isLocked
        importFile
        createdAt
        updatedAt
      }
      report {
        id
        tenantId
        state
        quarter
        year
        reportDateTime
        submittedDateTime
        data
        loansInProcess
        loansClosed
        isSubmitted
        isDeleted
        reportXMLFile
        reportPDFFile
        createdAt
        updatedAt
      }
    }
  }
`;
