import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Modal, Typography } from '@mui/material';

const ActionModal = ({
  showCta,
  showCancel,
  modalContent,
  modalCtaColor,
  modalCtaText,
  modalMessage,
  modalMessageType,
  modalOnClick,
  modalOnCancel,
  modalOpen,
  modalTextAlign,
  modalTitle,
  modalWidth,
  modalCtaDisabled,
  modalCancelText,
}) =>
  modalOpen ? (
    <Modal open={modalOpen} onClose={modalOnCancel}>
      <Box
        width={modalWidth}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          padding: '24px',
          borderRadius: '4px',
          backgroundColor: 'background.paper',
          transform: 'translate(-50%, -50%)',
          '&:focus-visible': {
            outline: 'none',
          },
        }}
      >
        {modalTitle && (
          <Typography
            component="h3"
            variant="h6"
            color={modalMessageType}
            sx={{
              margin: '-5px 0',
            }}
          >
            {modalTitle}
          </Typography>
        )}
        {modalMessage && (
          <Typography
            variant="body1"
            color={modalMessageType}
            align={modalTextAlign}
            sx={{
              marginTop: '14px',
            }}
          >
            {modalMessage}
          </Typography>
        )}
        {modalContent && <div>{modalContent}</div>}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            paddingTop: '36px',
          }}
        >
          {showCancel && (
            <Button
              variant="text"
              onClick={modalOnCancel}
              sx={{
                marginRight: '16px',
              }}
            >
              {modalCancelText}
            </Button>
          )}
          {showCta && (
            <Button
              variant="contained"
              color={modalCtaColor}
              disableElevation
              onClick={modalOnClick}
              disabled={modalCtaDisabled}
            >
              {modalCtaText}
            </Button>
          )}
        </Box>
      </Box>
    </Modal>
  ) : null;

ActionModal.propTypes = {
  showCta: PropTypes.bool,
  showCancel: PropTypes.bool,
  modalCtaDisabled: PropTypes.bool,
  modalContent: PropTypes.node,
  modalCtaColor: PropTypes.oneOf(['primary', 'error', 'secondary', 'success']), // Action button color if different from primary
  modalCtaText: PropTypes.string.isRequired, // Action button text
  modalCancelText: PropTypes.string, // Main modal text
  modalMessage: PropTypes.string, // Main modal text
  modalMessageType: PropTypes.oneOf(['', 'error']), // Makes main modal text red
  modalOnClick: PropTypes.func, // What happens when action button is clicked
  modalOnCancel: PropTypes.func, // What happens when the "cancel" button is clicked
  modalOpen: PropTypes.bool.isRequired, // Modal state
  modalTextAlign: PropTypes.oneOf(['center', 'inherit', 'justify', 'left', 'right']), // Alignment of main modal text
  modalTitle: PropTypes.string, // Modal title. If this is left empty the modal only has main text
  modalWidth: PropTypes.string, // A specific width can be set for each modal. This should be specified in width and unit of measure
};

ActionModal.defaultProps = {
  modalCancelText: 'Cancel',
  modalTitle: '',
  modalContent: '',
  modalWidth: '',
  modalCtaColor: 'primary',
  modalMessageType: '',
  modalMessage: '',
  modalTextAlign: 'center',
  modalOnClick: null,
  modalOnCancel: null,
  modalCtaDisabled: false,
  showCancel: true,
  showCta: true,
};

export default ActionModal;
