import React from 'react';
import { QueryClientProvider } from 'react-query';
import { client } from 'clients/defaultClient';
import { AuthProvider } from 'context/AuthContext';
import { AppProvider } from 'context/AppContext';
import AppLauncher from './AppLauncher';

const App = () => (
  <QueryClientProvider client={client}>
    <AppProvider>
      <AuthProvider>
        <AppLauncher />
      </AuthProvider>
    </AppProvider>
  </QueryClientProvider>
);

export default App;
