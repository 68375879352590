const {
  NODE_ENV,
  // REACT_APP_VERSION,
  // REACT_APP_SENTRY_DSN,
  REACT_APP_DATE_SYSTEM_FORMAT,
  REACT_APP_DATE_FORMAT,
  REACT_APP_TIME_FORMAT,
  REACT_APP_TIME_SYSTEM_FORMAT,
  REACT_APP_TIME_ZONE,
  REACT_APP_BASE_API_URL,
} = process.env;

const config = {
  // version: REACT_APP_VERSION,
  env: NODE_ENV,
  // sentryDsn: REACT_APP_SENTRY_DSN,
  xhr: {
    headers: {
      Accept: 'application/json, application/pdf, text/plain, */*',
      'Content-Type': 'application/json; charset=UTF-8',
    },
    baseUrl: REACT_APP_BASE_API_URL,
  },
  formats: {
    dateSystem: REACT_APP_DATE_SYSTEM_FORMAT,
    date: REACT_APP_DATE_FORMAT,
    time: REACT_APP_TIME_FORMAT,
    timeSystem: REACT_APP_TIME_SYSTEM_FORMAT,
  },
  timeZone: REACT_APP_TIME_ZONE,
};

export default config;

export const cacheKeys = {
  getFiles: 'getFiles',
  searchFiles: 'searchFiles',
  getReports: 'getReports',
  getTenant: 'getTenant',
  getUser: 'getUser',
  reportFiles: 'reportFiles',
};
