import React, { useMemo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useQueryClient } from 'react-query';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import { useAuthContext } from './AuthContext';
import { useAlertContext } from './AlertContext';
import { cacheKeys } from '../conf';
import { createFile } from '../graphql/mutations';
import { searchFiles } from '../graphql/queries';
import { boolToStringBoolean } from '../utils/enumUtils';
import { LoanStatus } from '../constants/enums';

const FileImportContext = React.createContext({
  isLoading: false,
  error: null,
  getFiles: () => {},
});

export const FileImportProvider = ({ children }) => {
  const { tenantId } = useAuthContext();
  const queryClient = useQueryClient();
  const { openAlert } = useAlertContext();
  const [isLoading, setIsLoading] = useState(false);

  const doImport = useCallback(async (input) => {
    try {
      await API.graphql(
        graphqlOperation(createFile, {
          input: {
            ...input.fileData,
            importFile: input.fileName,
            loanStatus: LoanStatus.In_Process.value,
            isReverseMortgage: false,
            isDeleted: boolToStringBoolean(false),
            isLocked: false,
          },
        })
      );
      // console.log(res);
      await Storage.put(input.fileKey, input.fileObject, {
        level: 'protected',
        contentType: 'application/xml',
        contentDisposition: 'attachment',
      });
      return {
        isSuccess: true,
        message: input.fileName,
      };
    } catch (error) {
      return {
        isSuccess: false,
        message: `Failed to import file ${input.fileName} with the following error: ${JSON.stringify(error)}`,
      };
    }
  }, []);

  const checkForDuplicate = useCallback(
    async ({
      applicationDate,
      borrowerFirstName,
      borrowerLastName,
      borrowerDob,
      subjectPropAddress,
      subjectPropCity,
      subjectPropCounty,
      subjectPropZip,
      subjectPropState,
    }) => {
      try {
        const res = await API.graphql(
          graphqlOperation(searchFiles, {
            tenantId,
            isDeleted: { eq: boolToStringBoolean(false) },
            filter: {
              borrowerFirstName: { eq: borrowerFirstName },
              borrowerLastName: { eq: borrowerLastName },
              borrowerDob: { eq: borrowerDob },
              applicationDate: { eq: applicationDate },
              subjectPropAddress: { eq: subjectPropAddress },
              subjectPropCity: { eq: subjectPropCity },
              subjectPropCounty: { eq: subjectPropCounty },
              subjectPropZip: { eq: subjectPropZip },
              subjectPropState: { eq: subjectPropState },
            },
            limit: 100000,
          })
        );
        return res.data?.searchFiles.items.length > 0;
      } catch (e) {
        // console.error(e);
        return false;
      }
    },
    [tenantId]
  );

  const doFilesCreate = useCallback(
    async (imports) => {
      setIsLoading(true);
      const results = await Promise.all(
        imports.map(async (imp) => {
          const {
            applicationDate,
            borrowerFirstName,
            borrowerLastName,
            borrowerDob,
            subjectPropAddress,
            subjectPropCity,
            subjectPropCounty,
            subjectPropZip,
            subjectPropState,
          } = imp.fileData;
          const isDuplicate = await checkForDuplicate({
            applicationDate,
            borrowerFirstName,
            borrowerLastName,
            borrowerDob,
            subjectPropAddress,
            subjectPropCity,
            subjectPropCounty,
            subjectPropZip,
            subjectPropState,
          });
          // console.log(isDuplicate);
          if (isDuplicate) {
            return {
              isSuccess: false,
              message: `File ${imp.fileName} cannot be imported as it already exists in the system`,
            };
          }
          return doImport(imp);
        })
      );
      setIsLoading(false);
      const errors = results.filter((it) => !it.isSuccess);
      const successes = results.filter((it) => it.isSuccess);
      if (successes.length) {
        queryClient.invalidateQueries([cacheKeys.getFiles, tenantId]);
        openAlert(
          'success',
          successes.reduce((ac, nx) => `${ac}${nx.message}, `, 'Successfully imported files: ')
        );
      }
      if (errors.length) {
        openAlert(
          'error',
          errors.reduce((ac, nx) => `${ac} ${nx.message}`, '')
        );
      }
    },
    [checkForDuplicate, doImport, openAlert, queryClient, tenantId]
  );

  const exportValue = useMemo(
    () => ({
      isLoading,
      doFilesCreate,
    }),
    [doFilesCreate, isLoading]
  );

  return <FileImportContext.Provider value={exportValue}>{children}</FileImportContext.Provider>;
};

FileImportProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useFileImportContext = () => React.useContext(FileImportContext);
