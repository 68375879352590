import React, { useMemo, useCallback, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { DateTime } from 'luxon';
// import uniqBy from 'lodash/fp/uniqBy';
import PropTypes from 'prop-types';
import { useQueryClient, useQuery } from 'react-query';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import { openAxios } from '../clients/axiosClient';
import { useAuthContext } from './AuthContext';
import { ModelSortDirection, LoanStatus } from '../constants/enums';
import config, { cacheKeys } from '../conf';
import { createReport, createReportFile, updateFile, updateReport } from '../graphql/mutations';
import { listReportsByTenant, listFilesByTenantWithLogs } from '../graphql/queries';
import { boolToStringBoolean } from '../utils/enumUtils';
import { getDatesForSpecifiedQuarter, getNowAsUtcIso } from '../utils/dateUtils';
import { generateReportData } from '../utils/report';

const ReportContext = React.createContext({
  isLoading: false,
  isError: false,
  isSuccess: false,
  error: null,
  getReports: () => {},
  getReport: () => {},
  createReport: () => {},
  saveReport: () => {},
  deleteReport: () => {},
  submitReport: () => {},
  isGenerating: false,
  isSaving: false,
  isUpdating: false,
  reportUrl: null,
  reportDataAndFile: null,
});

const mapWithLogs = (startDate, endDate, f) => {
  const { statusLogs, ...rest } = f;
  if (!statusLogs.items?.length) return f;
  const { items } = statusLogs;
  const relevantLogs = items
    .map((i) => {
      const { dateTime } = i;
      return {
        ...i,
        dt: DateTime.fromISO(dateTime),
      };
    })
    .filter((i) => i.dt >= startDate && i.dt <= endDate)
    .sort((a, b) => {
      const { dateTime: adt } = a;
      const { dateTime: bdt } = b;
      if (adt < bdt) return 1;
      if (bdt < adt) return -1;
      return 0;
    });
  return {
    ...rest,
    statusLogs: { items: relevantLogs },
  };
};

export const ReportProvider = ({ children }) => {
  const queryClient = useQueryClient();
  const { tenantId, isAuthenticated, refresh } = useAuthContext();
  const [isGenerating, setIsGenerating] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [reportUrl, setReportUrl] = useState({ data: null });
  const [reportDataAndFile, setReportDataAndFile] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);

  const { isLoading, isError, isSuccess, error, data } = useQuery(
    [cacheKeys.getReports, tenantId],
    async () => {
      refresh();
      return API.graphql(
        graphqlOperation(listReportsByTenant, {
          tenantId,
          limit: 10000,
          sortDirection: ModelSortDirection.DESC,
          // filter: { isDeleted: { eq: boolToStringBoolean(false) } },
        })
      );
    },
    {
      enabled: isAuthenticated && !!tenantId,
    }
  );

  const getReports = useMemo(() => (isSuccess ? data.data.listReportsByTenant.items : []), [isSuccess, data]);

  const generatePDF = useCallback(async (d) => {
    const client = await openAxios({}, { responseType: 'blob' });
    const url = `${config.xhr.baseUrl}/pdf`;
    const res = await client.post(url, d);
    return res;
  }, []);

  const saveReport = useCallback(
    async (r, fileBlob) => {
      setIsSaving(true);
      // when saving, for all files in report, if any in process, set loanAmountLocked to true;
      const { state, year, quarter, inProcess, closedAndFunded, allFiles, ...rest } = r.raw;
      const filePath = `${tenantId}/${year}/${quarter}/${state}_${year}_${quarter}_RMLA_Report.pdf`;
      const { key } = await Storage.put(filePath, fileBlob, {
        level: 'public',
        contentType: 'application/pdf',
        contentDisposition: 'attachment',
      });
      const reportId = uuidv4();
      const reportData = {
        id: reportId,
        tenantId,
        state,
        quarter,
        year,
        reportDateTime: getNowAsUtcIso(),
        submittedDateTime: null,
        data: JSON.stringify({ ...rest, state, year, quarter, inProcess, closedAndFunded }),
        loansInProcess: inProcess,
        loansClosed: closedAndFunded,
        isSubmitted: boolToStringBoolean(false),
        isDeleted: boolToStringBoolean(false),
        reportXMLFile: null,
        reportPDFFile: key,
      };
      try {
        await Promise.all(
          // 1. create the report
          [
            API.graphql(
              graphqlOperation(createReport, {
                input: reportData,
              })
            ),
          ]
            .concat(
              // 2. lock loan amount for in process files
              allFiles
                .filter((f) => f.loanStatus === LoanStatus.In_Process.value)
                .map((f) =>
                  API.graphql(
                    graphqlOperation(updateFile, {
                      input: {
                        id: f.id,
                        loanAmountLocked: true,
                      },
                    })
                  )
                )
            )
            .concat(
              // 3. create ReportFile for all files in report
              allFiles.map((f) =>
                API.graphql(
                  graphqlOperation(createReportFile, {
                    input: {
                      tenantId,
                      reportId,
                      fileId: f.id,
                      isDeleted: boolToStringBoolean(false),
                    },
                  })
                )
              )
            )
        );
        queryClient.invalidateQueries([cacheKeys.getReports, tenantId], [cacheKeys.getFiles, tenantId]);
      } catch {
        // console.error(e);
      }
      setReportDataAndFile({ reportData: null, fileBlob: null });
      setIsSaving(false);
    },
    [queryClient, tenantId]
  );

  // status of files for report needs to be as they were at the end of the report quarter
  const doCreateReport = useCallback(
    async ({ state, year, quarter }) => {
      setIsGenerating(true);
      try {
        const { startDate, endDate } = getDatesForSpecifiedQuarter(quarter, year);
        // for AC010
        const latestReport = getReports
          .filter((r) => r.state === state && r.reportDateTime != null)
          .sort((a, b) => {
            const adt = DateTime.fromISO(a.reportDateTime);
            const bdt = DateTime.fromISO(b.reportDateTime);
            if (!adt.isValid || !bdt.isValid) {
              return 0;
            }
            if (adt < bdt) return 1;
            if (bdt < adt) return -1;
            return 0;
          })[0];
        // for AC020
        const receivedWithinQuarter = await API.graphql(
          graphqlOperation(listFilesByTenantWithLogs, {
            tenantId,
            limit: 10000,
            filter: {
              isDeleted: { eq: boolToStringBoolean(false) },
              applicationDate: { between: [startDate.toISODate(), endDate.toISODate()] },
              subjectPropState: { eq: state },
            },
          })
        );
        // for AC100 - AC1000
        const completedWithinQuarter = await API.graphql(
          graphqlOperation(listFilesByTenantWithLogs, {
            tenantId,
            limit: 10000,
            filter: {
              isDeleted: { eq: boolToStringBoolean(false) },
              closingDate: { between: [startDate.toISODate(), endDate.toISODate()] },
              subjectPropState: { eq: state },
            },
          })
        );
        // for AC080
        const inProcessAtEndOfQuarter = await API.graphql(
          graphqlOperation(listFilesByTenantWithLogs, {
            tenantId,
            limit: 10000,
            filter: {
              isDeleted: { eq: boolToStringBoolean(false) },
              subjectPropState: { eq: state },
              applicationDate: { le: endDate.toISODate() },
              not: {
                closingDate: { le: endDate.toISODate() },
              },
            },
          })
        );
        // Status logs to get the correct statuses

        const rcvdWithinQ = receivedWithinQuarter.data?.listFilesByTenant?.items?.map((f) =>
          mapWithLogs(startDate, endDate, f)
        );
        const compWithinQ = completedWithinQuarter.data?.listFilesByTenant?.items?.map((f) =>
          mapWithLogs(startDate, endDate, f)
        );
        const inProcEndOfQ = inProcessAtEndOfQuarter.data?.listFilesByTenant?.items?.map((f) =>
          mapWithLogs(startDate, endDate, f)
        );

        // console.log(startDate.toISODate());
        // console.log(endDate.toISODate());
        // console.log(rcvdWithinQ);
        // console.log(compWithinQ);
        // console.log(inProcEndOfQ);

        const reportData = generateReportData(
          year,
          quarter,
          state,
          latestReport,
          rcvdWithinQ || [],
          compWithinQ || [],
          inProcEndOfQ || []
        );
        const pdfRes = await generatePDF(reportData.formatted);
        const fileBlob = pdfRes.data;
        setReportUrl(URL.createObjectURL(fileBlob));
        setReportDataAndFile({ reportData, fileBlob });
        setIsGenerating(false);
      } catch {
        setIsGenerating(false);
      }
    },
    [getReports, tenantId, generatePDF]
  );

  const doSubmitReport = useCallback(
    async (reportData, isSubmitted) => {
      const { id, tenantId: tId, isDeleted, state, reportDateTime } = reportData;
      setIsUpdating(true);
      try {
        await API.graphql(
          graphqlOperation(updateReport, {
            input: {
              id,
              tenantId: tId,
              isDeleted,
              state,
              reportDateTime,
              submittedDateTime: getNowAsUtcIso(),
              isSubmitted: boolToStringBoolean(isSubmitted),
            },
          })
        );
        queryClient.invalidateQueries([cacheKeys.getReports, tenantId]);
      } catch (e) {
        // console.error(e);
      }
      setIsUpdating(false);
    },
    [queryClient, tenantId]
  );

  const doDeleteReport = useCallback(
    async (reportId) => {
      setIsUpdating(true);
      try {
        await API.graphql(
          graphqlOperation(updateReport, {
            input: {
              id: reportId,
              isDeleted: boolToStringBoolean(true),
            },
          })
        );
        queryClient.invalidateQueries([cacheKeys.getReports, tenantId]);
      } catch (e) {
        // console.error(e);
      }
      setIsUpdating(false);
    },
    [queryClient, tenantId]
  );

  const exportValue = useMemo(
    () => ({
      isLoading,
      isError,
      isSuccess,
      error,
      getReports,
      createReport: doCreateReport,
      submitReport: doSubmitReport,
      deleteReport: doDeleteReport,
      saveReport,
      isGenerating,
      isSaving,
      isUpdating,
      reportUrl,
      reportDataAndFile,
    }),
    [
      isLoading,
      isError,
      isSuccess,
      error,
      getReports,
      doCreateReport,
      doSubmitReport,
      doDeleteReport,
      saveReport,
      isGenerating,
      isSaving,
      isUpdating,
      reportUrl,
      reportDataAndFile,
    ]
  );

  return <ReportContext.Provider value={exportValue}>{children}</ReportContext.Provider>;
};

ReportProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useReportContext = () => React.useContext(ReportContext);
