import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useQueryClient, useMutation } from 'react-query';
import { API, graphqlOperation } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import { useAuthContext } from './AuthContext';
import { useAlertContext } from './AlertContext';
import { cacheKeys } from '../conf';
import { createFile } from '../graphql/mutations';
import { searchFiles } from '../graphql/queries';
import { normalizeData } from '../utils/formUtils';
import { boolToStringBoolean } from '../utils/enumUtils';
import { StringBoolean } from '../constants/enums';

const FileCreateContext = React.createContext({
  isLoading: false,
  isSuccess: false,
  isError: false,
  data: null,
  error: null,
  createFile: () => {},
});

export const FileCreateProvider = ({ children }) => {
  const navigate = useNavigate();
  const { tenantId, isAdmin } = useAuthContext();
  const queryClient = useQueryClient();
  const { openAlert } = useAlertContext();

  const { isLoading, isSuccess, data, isError, error, mutate } = useMutation(
    (input) =>
      API.graphql(
        graphqlOperation(createFile, {
          input: {
            ...input,
          },
        })
      ),
    {
      onSuccess: (_, input) => {
        queryClient.invalidateQueries([cacheKeys.getFiles, tenantId]);
        openAlert('success', `File for ${input.borrowerLastName}, ${input.borrowerFirstName} created.`);
        setTimeout(() => {
          navigate(`/file/${input.id}`);
        }, 3100);
      },
    }
  );

  const checkForDuplicate = useCallback(
    async ({
      applicationDate,
      borrowerFirstName,
      borrowerLastName,
      subjectPropAddress,
      subjectPropCity,
      subjectPropCounty,
      subjectPropZip,
      subjectPropState,
    }) => {
      try {
        const res = await API.graphql(
          graphqlOperation(searchFiles, {
            tenantId,
            filter: {
              isDeleted: { eq: boolToStringBoolean(false) },
              borrowerFirstName: { eq: borrowerFirstName },
              borrowerLastName: { eq: borrowerLastName },
              applicationDate: { eq: applicationDate },
              subjectPropAddress: { eq: subjectPropAddress },
              subjectPropCity: { eq: subjectPropCity },
              subjectPropCounty: { eq: subjectPropCounty },
              subjectPropZip: { eq: subjectPropZip },
              subjectPropState: { eq: subjectPropState },
            },
            limit: 100000,
          })
        );
        return res.data?.searchFiles.items.length > 0;
      } catch (e) {
        // console.error(e);
        return false;
      }
    },
    [tenantId]
  );

  const doFileCreate = useCallback(
    async (d, shouldLogStatusChange, isReverseMortgage) => {
      const normalizedData = normalizeData(d, isReverseMortgage, isAdmin);
      const isDuplicate = await checkForDuplicate(normalizedData);
      if (isDuplicate) {
        openAlert(
          'error',
          `File for ${normalizedData.borrowerLastName}, ${normalizedData.borrowerFirstName} cannot be created as it already exists in the system.`
        );
        return;
      }
      mutate({
        ...normalizedData,
        id: uuidv4(),
        tenantId,
        isDeleted: StringBoolean.False.value,
        isLocked: false,
      });
    },
    [checkForDuplicate, isAdmin, mutate, openAlert, tenantId]
  );

  const exportValue = useMemo(
    () => ({
      isLoading,
      isSuccess,
      isError,
      data,
      error,
      createFile: doFileCreate,
    }),
    [data, doFileCreate, error, isError, isLoading, isSuccess]
  );

  return <FileCreateContext.Provider value={exportValue}>{children}</FileCreateContext.Provider>;
};

FileCreateProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useFileCreateContext = () => React.useContext(FileCreateContext);
