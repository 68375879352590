export const ModelSortDirection = {
  DESC: 'DESC',
  ASC: 'ASC',
};

export const StringBoolean = {
  True: {
    label: 'Yes',
    value: 'True',
  },
  False: {
    label: 'No',
    value: 'False',
  },
};

export const AmortizationType = {
  Adjustable: {
    label: 'Adjustable',
    value: 'Adjustable',
  },
  Balloon: {
    label: 'Balloon',
    value: 'Balloon',
  },
  Fixed: {
    label: 'Fixed',
    value: 'Fixed',
  },
};

export const ApplicationType = {
  Brokered_Loan: {
    label: 'Closed brokered loan',
    value: 'Brokered_Loan',
  },
  Closed_Retail_Application: {
    label: 'Closed retail application',
    value: 'Closed_Retail_Application',
  },
  Closed_Wholesale_Application: {
    label: 'Closed wholesale application',
    value: 'Closed_Wholesale_Application',
  },
};

export const DocumentationType = {
  Full: {
    label: 'Full',
    value: 'Full',
  },
  Partial: {
    label: 'Partial',
    value: 'Partial',
  },
  None: {
    label: 'None',
    value: 'None',
  },
};

export const LienPriorityType = {
  First_Lien: {
    label: 'First lien',
    value: 'First_Lien',
  },
  HELOC: {
    label: 'HELOC',
    value: 'HELOC',
  },
  Not_Secured_By_A_Lien: {
    label: 'Not secured by a lien',
    value: 'Not_Secured_By_A_Lien',
  },
  Second_Lien: {
    label: 'Second lien',
    value: 'Second_Lien',
  },
  Simultaneous_Second_Lien: {
    label: 'Simultaneous second lien',
    value: 'Simultaneous_Second_Lien',
  },
  Standalone: {
    label: 'Standalone',
    value: 'Standalone',
  },
};

export const LoanPurpose = {
  Purchase: {
    label: 'Purchase',
    value: 'Purchase',
  },
  Cash_Out_Refinance: {
    label: 'Cash-out refinance',
    value: 'Cash_Out_Refinance',
  },
  Limited_Cash_Out_Refinance: {
    label: 'Limited cash-out refinance',
    value: 'Limited_Cash_Out_Refinance',
  },
  No_Cash_Out_Refinance: {
    label: 'No cash-out refinance',
    value: 'No_Cash_Out_Refinance',
  },
  Other: {
    label: 'Other',
    value: 'Other',
  },
};

export const LoanStatus = {
  Approved_Not_Accepted: {
    label: 'Approved but not accepted',
    value: 'Approved_Not_Accepted',
  },
  Denied: {
    label: 'Denied',
    value: 'Denied',
  },
  Withdrawn: {
    label: 'Withdrawn',
    value: 'Withdrawn',
  },
  Incomplete: {
    label: 'Incomplete',
    value: 'Incomplete',
  },
  Pre_Approval_Denied: {
    label: 'Pre-approval requests denied',
    value: 'Pre_Approval_Denied',
  },
  Pre_Approval_Approved_Not_Accepted: {
    label: 'Pre-approval requests approved but not accepted',
    value: 'Pre_Approval_Approved_Not_Accepted',
  },
  Closed_Funded: {
    label: 'Closed and funded',
    value: 'Closed_Funded',
  },
  In_Process: {
    label: 'In process',
    value: 'In_Process',
  },
};

export const LoanType = {
  QM: {
    label: 'QM',
    value: 'QM',
  },
  Non_QM: {
    label: 'Non-QM',
    value: 'Non_QM',
  },
  Not_Subject_To_QM: {
    label: 'Not subject to QM',
    value: 'Not_Subject_To_QM',
  },
};

export const MortgageType = {
  Conventional: {
    label: 'Conventional',
    value: 'Conventional',
  },
  FHA: {
    label: 'FHA',
    value: 'FHA',
  },
  VA: {
    label: 'VA',
    value: 'VA',
  },
  USDA_RHS: {
    label: 'USDA/RHS',
    value: 'USDARuralDevelopment',
  },
  Other: {
    label: 'Other',
    value: 'Other',
  },
};

export const PropertyType = {
  One_To_Four_Family: {
    label: 'One to four family dwelling',
    value: 'One_To_Four_Family',
  },
  Manufactured_Housing: {
    label: 'Manufactured housing',
    value: 'Manufactured_Housing',
  },
  Multifamily: {
    label: 'Multifamily dwelling',
    value: 'Multifamily',
  },
};

export const ReceivedFrom = {
  Borrower: {
    label: 'Borrower',
    value: 'Borrower',
  },
  Third_Party: {
    label: 'Third party',
    value: 'Third_Party',
  },
};

export const ReverseMortgagePurpose = {
  Home_Purchase: {
    label: 'Home purchase',
    value: 'Home_Purchase',
  },
  Other: {
    label: 'Other',
    value: 'Other',
  },
};

export const ReverseMortgageType = {
  HECM_Standard: {
    label: 'HECM-standard',
    value: 'HECM_Standard',
  },
  HECM_Saver: {
    label: 'HECM-saver',
    value: 'HECM_Saver',
  },
  Propriatary_Other: {
    label: 'Proprietary/Other',
    value: 'Propriatary_Other',
  },
};

export const ServiceLoanType = {
  Released: {
    label: 'Released',
    value: 'Released',
  },
  Retained: {
    label: 'Retained',
    value: 'Retained',
  },
};

export const UsageType = {
  FHA_Secondary: {
    label: 'FHA Secondary',
    value: 'FHA_Secondary',
  },
  Investment: {
    label: 'Investment',
    value: 'Investment',
  },
  Primary_Residence: {
    label: 'Primary residence',
    value: 'Primary_Residence',
  },
  Second_Home: {
    label: 'Second home',
    value: 'Second_Home',
  },
};

export const Country = {
  US: {
    label: 'US',
    value: 'US',
  },
};

export const State = {
  CT: {
    label: 'CT',
    value: 'CT',
  },
  MA: {
    label: 'MA',
    value: 'MA',
  },
};

export const AllStates = {
  AL: { label: 'Alabama', value: 'AL' },
  AK: { label: 'Alaska', value: 'AK' },
  AZ: { label: 'Arizona', value: 'AZ' },
  AR: { label: 'Arkansas', value: 'AR' },
  CA: { label: 'California', value: 'CA' },
  CO: { label: 'Colorado', value: 'CO' },
  CT: { label: 'Connecticut', value: 'CT' },
  DE: { label: 'Delaware', value: 'DE' },
  DC: { label: 'District Of Columbia', value: 'DC' },
  FL: { label: 'Florida', value: 'FL' },
  GA: { label: 'Georgia', value: 'GA' },
  HI: { label: 'Hawaii', value: 'HI' },
  ID: { label: 'Idaho', value: 'ID' },
  IL: { label: 'Illinois', value: 'IL' },
  IN: { label: 'Indiana', value: 'IN' },
  IA: { label: 'Iowa', value: 'IA' },
  KS: { label: 'Kansas', value: 'KS' },
  KY: { label: 'Kentucky', value: 'KY' },
  LA: { label: 'Louisiana', value: 'LA' },
  ME: { label: 'Maine', value: 'ME' },
  MD: { label: 'Maryland', value: 'MD' },
  MA: { label: 'Massachusetts', value: 'MA' },
  MI: { label: 'Michigan', value: 'MI' },
  MN: { label: 'Minnesota', value: 'MN' },
  MS: { label: 'Mississippi', value: 'MS' },
  MO: { label: 'Missouri', value: 'MO' },
  MT: { label: 'Montana', value: 'MT' },
  NE: { label: 'Nebraska', value: 'NE' },
  NV: { label: 'Nevada', value: 'NV' },
  NH: { label: 'New Hampshire', value: 'NH' },
  NJ: { label: 'New Jersey', value: 'NJ' },
  NM: { label: 'New Mexico', value: 'NM' },
  NY: { label: 'New York', value: 'NY' },
  NC: { label: 'North Carolina', value: 'NC' },
  ND: { label: 'North Dakota', value: 'ND' },
  OH: { label: 'Ohio', value: 'OH' },
  OK: { label: 'Oklahoma', value: 'OK' },
  OR: { label: 'Oregon', value: 'OR' },
  PA: { label: 'Pennsylvania', value: 'PA' },
  RI: { label: 'Rhode Island', value: 'RI' },
  SC: { label: 'South Carolina', value: 'SC' },
  SD: { label: 'South Dakota', value: 'SD' },
  TN: { label: 'Tennessee', value: 'TN' },
  TX: { label: 'Texas', value: 'TX' },
  UT: { label: 'Utah', value: 'UT' },
  VT: { label: 'Vermont', value: 'VT' },
  VA: { label: 'Virginia', value: 'VA' },
  WA: { label: 'Washington', value: 'WA' },
  WV: { label: 'West Virginia', value: 'WV' },
  WI: { label: 'Wisconsin', value: 'WI' },
  WY: { label: 'Wyoming', value: 'WY' },
};

export const Quarter = {
  Q1: {
    label: 'Q1',
    value: 'Q1',
  },
  Q2: {
    label: 'Q2',
    value: 'Q2',
  },
  Q3: {
    label: 'Q3',
    value: 'Q3',
  },
  Q4: {
    label: 'Q4',
    value: 'Q4',
  },
};
