import React, { useState } from 'react';
import Main from '../components/Main';
import PageActionsReports from '../components/PageActionsReports';
import TableReports from '../tables/TableReports';

const Reports = () => {
  // Locked state
  const [locked, setLockedState] = useState(true);

  return (
    <Main pagetitle="Reports">
      <PageActionsReports locked={locked} setLockedState={setLockedState} />
      <TableReports locked={locked} setLockedState={setLockedState} />
    </Main>
  );
};

export default Reports;
