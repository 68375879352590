import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import {
  Autocomplete,
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  FormGroup,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  CircularProgress,
} from '@mui/material';
import { isEmpty } from 'utils/index';
import { checkShouldShowLoanStatusWarning, updateFormState, getInitialFileFormState } from 'utils/formUtils';
import { LoanStatus, MortgageType, State } from 'constants/enums';
import ActionModal from '../components/ActionModal';
import {
  amortizationTypeOptions,
  applicationTypeOptions,
  countryOptions,
  documentationTypeOptions,
  financedUnitCountOptions,
  lenderOptions,
  lienPriorityTypeOptions,
  loanPurposeOptions,
  loanStatusOptions,
  loanTypeOptions,
  mortgageTypeOptions,
  propertyTypeOptions,
  receivedFromOptions,
  serviceLoanTypeOptions,
  stateOptions,
  AllStatesOptions,
  usageTypeOptions,
} from '../selectOptions';
import loanOfficerOptions from '../loanOriginators';
import { useAlertContext } from '../context/AlertContext';

const FormFile = (props) => {
  const { mode, data, isLoading, formId, onSubmit } = props;
  const { openAlert } = useAlertContext();

  const hasData = useMemo(() => !isEmpty(data) || mode === 'CREATE', [data, mode]);

  const editable = useMemo(() => mode === 'EDIT' || mode === 'CREATE', [mode]);

  // Set file close modal state
  const [fileCloseModalOpen, setFileCloseModalOpen] = useState(false);

  const [fileCreateModalOpen, setFileCreateModalOpen] = useState(false);

  const [fileEditModalOpen, setFileEditModalOpen] = useState(false);

  const [shouldLogStatusChange, setShouldLogStatusChange] = useState(false);

  const [isAllRequired, setIsAllRequired] = useState(false);

  const [isMinRequired, setIsMinRequired] = useState(false);

  const [formState, setFormState] = useState(getInitialFileFormState({}));

  useEffect(() => {
    setFormState(getInitialFileFormState({ ...(data || {}) }));
  }, [data]);

  const {
    amortizationType,
    amortizationPeriod,
    applicationDate,
    applicationType,
    brokerFeesCollected,
    borrowerAddress,
    borrowerCity,
    borrowerCountry,
    borrowerDob,
    borrowerEmail,
    borrowerFirstName,
    borrowerLastName,
    borrowerPhone,
    borrowerState,
    borrowerZip,
    coBorrowerFirstName,
    coBorrowerLastName,
    coBorrowerEmail,
    coBorrowerPhone,
    coBorrowerDob,
    closingDate,
    closingLoanAmount,
    documentationType,
    financedUnitCount,
    interestRate,
    isHoepa,
    isRepurchase,
    homeImprovement,
    lender,
    loanOriginatorName,
    lienPriorityType,
    loanAmount,
    loanAmountLocked,
    loanPurpose,
    loanStatus,
    loanType,
    mortgageType,
    receivedFrom,
    serviceLoanType,
    subjectPropAddress,
    subjectPropCity,
    subjectPropCounty,
    subjectPropState,
    subjectPropZip,
    subjectPropValuation,
    subjectPropType,
    term,
    usageType,
  } = formState;

  const shouldShowCoBorrowerFields = useMemo(
    () =>
      mode === 'CREATE' ||
      mode === 'EDIT' ||
      !!coBorrowerDob ||
      !!coBorrowerFirstName ||
      !!coBorrowerLastName ||
      !!coBorrowerPhone ||
      !!coBorrowerEmail,
    [coBorrowerDob, coBorrowerEmail, coBorrowerFirstName, coBorrowerLastName, coBorrowerPhone, mode]
  );

  const isServiceLoanDisabled = useMemo(() => {
    if (subjectPropState?.value === State.CT.value) return true;
    return !editable;
  }, [editable, subjectPropState]);

  const isServiceLoanRequired = useMemo(() => subjectPropState?.value !== State.CT.value, [subjectPropState]);

  const fileCloseModalProps = useMemo(
    () => ({
      modalCtaText: 'Change Loan Status and Lock File',
      modalMessage: `Changing the loan status to ${loanStatus?.label} will lock the file and only an admin will be able to edit it going forward.`,
      modalTextAlign: 'center',
      modalWidth: '520px',
      modalOpen: fileCloseModalOpen,
      modalOnClick: () => {
        setFileCloseModalOpen(false);
        // set isLocked true as that's what this modal is for
        onSubmit({ ...formState, isLocked: true }, shouldLogStatusChange, false);
      },
      modalOnCancel: () => setFileCloseModalOpen(false),
    }),
    [fileCloseModalOpen, formState, loanStatus?.label, onSubmit, shouldLogStatusChange]
  );

  const fileCreateModalProps = useMemo(
    () => ({
      modalCtaText: 'Create forward mortgage',
      modalMessage: `Confirm creation of forward mortgage file?`,
      modalContent:
        mortgageType?.value &&
        (mortgageType?.value === MortgageType.FHA.value || mortgageType?.value === MortgageType.VA.value) ? (
          <p>Please ensure MIP/Funding Fee is added to the base loan amount</p>
        ) : null,
      modalTextAlign: 'center',
      modalWidth: '400px',
      modalOpen: fileCreateModalOpen,
      modalOnClick: () => {
        setFileCreateModalOpen(false);
        onSubmit({ ...formState }, false, false);
      },
      modalOnCancel: () => setFileCreateModalOpen(false),
    }),
    [fileCreateModalOpen, formState, mortgageType, onSubmit]
  );

  const fileEditModalProps = useMemo(
    () => ({
      modalCtaText: 'Save Changes',
      modalMessage: `Please ensure MIP/Funding Fee is added to the base loan amount`,
      modalTextAlign: 'center',
      modalWidth: '400px',
      modalOpen: fileEditModalOpen,
      modalOnClick: () => {
        setFileEditModalOpen(false);
        onSubmit({ ...formState }, shouldLogStatusChange, false);
      },
      modalOnCancel: () => setFileEditModalOpen(false),
    }),
    [fileEditModalOpen, formState, onSubmit, shouldLogStatusChange]
  );

  return isLoading || !hasData ? (
    <Box
      sx={{
        minHeight: '206px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <>
      <Box
        onSubmit={(e) => {
          e.preventDefault();
          if (!!closingDate && !isEmpty(loanStatus) && loanStatus.value === LoanStatus.In_Process.value) {
            openAlert('error', 'Loan status cannot be in process if a completion/closing date has been entered');
            return false;
          }
          if (e.target.reportValidity()) {
            if (mode === 'EDIT') {
              const shouldLock = checkShouldShowLoanStatusWarning(loanStatus);
              if (shouldLock) {
                setFileCloseModalOpen(true);
              } else if (
                mortgageType?.value &&
                (mortgageType?.value === MortgageType.FHA.value || mortgageType?.value === MortgageType.VA.value)
              ) {
                setFileEditModalOpen(true);
              } else {
                onSubmit(formState, shouldLogStatusChange, false);
              }
            } else {
              setFileCreateModalOpen(true);
            }
          }
          return true;
        }}
        id={formId}
        component="form"
        sx={{
          // Paper
          '.MuiPaper-root': {
            padding: '20px',
            '+ .MuiPaper-root': {
              marginTop: '20px',
            },
            '&.MuiAutocomplete-paper': {
              padding: 0,
            },
          },

          // FormGroup
          '.MuiFormGroup-root': {
            flexDirection: 'row',
            '+ .MuiFormGroup-root': {
              marginTop: '20px',
            },
            h6: {
              flex: '0 0 100%',
              fontWeight: 'bold',
              color: '#145ca4',
            },
          },

          // Textfield
          '.MuiTextField-root': {
            margin: '20px 20px 0 0',
          },

          // Autocomplete
          '.MuiAutocomplete-root': {
            margin: '20px 20px 0 0',
            '.MuiFormControl-root': {
              margin: 0,
            },
          },

          // Required
          '.MuiInputLabel-asterisk': {
            color: '#d32f2f',
          },

          // Disabled Styling
          'label.Mui-disabled': {
            color: '#666666',
          },
          legend: {
            color: '#666666',
          },
          '.MuiFormControlLabel-label': {
            '&.Mui-disabled': {
              color: '#212121 !important',
            },
          },
          '.MuiInputBase-input': {
            '&.Mui-disabled': {
              color: '#212121',
            },
          },
          '.MuiRadio-root': {
            '&.Mui-disabled': {
              '& svg': {
                fill: 'rgba(0, 0, 0, 0.6)',
              },
            },
          },
        }}
      >
        <Paper>
          <Typography component="h3" variant="h6">
            Status
          </Typography>
          <FormGroup>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                id="applicationDate"
                label="Application date"
                value={applicationDate}
                disabled={!editable}
                onChange={(v) => {
                  updateFormState('applicationDate', v, formState, setFormState);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    required
                    error={isAllRequired ? !applicationDate : undefined}
                    sx={{
                      width: '185px',
                    }}
                  />
                )}
                required={isAllRequired}
              />
            </LocalizationProvider>
            <Autocomplete
              id="loanStatus"
              size="small"
              disablePortal
              options={loanStatusOptions}
              value={loanStatus}
              disabled={!editable}
              renderInput={(params) => <TextField {...params} label="Loan status" required />}
              sx={{
                width: '440px',
              }}
              onChange={(_, v) => {
                // if (loanStatus !=== 'In process') { Add required prop to all form fields
                setIsAllRequired(v?.value !== LoanStatus.In_Process.value);
                // if (loanStatus === 'Closed and funded') { add inputProps={{ min: 1 }} to closingLoanAmount and brokerFeesCollected }
                setIsMinRequired(v.value === LoanStatus.Closed_Funded.value);
                setShouldLogStatusChange(true);
                updateFormState('loanStatus', v, formState, setFormState);
              }}
              required={isAllRequired}
              error={isAllRequired ? !loanStatus?.value : undefined}
            />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                id="closingDate"
                label="Completion/closing date"
                value={closingDate}
                disabled={!editable}
                onChange={(v) => {
                  updateFormState('closingDate', v, formState, setFormState);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    sx={{
                      width: '245px',
                    }}
                    required={isAllRequired}
                    error={isAllRequired ? !closingDate : undefined}
                  />
                )}
                required={isAllRequired}
              />
            </LocalizationProvider>
            <Autocomplete
              id="lender"
              size="small"
              disablePortal
              options={lenderOptions}
              value={lender}
              disabled={!editable}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Lender"
                  required={isAllRequired}
                  error={isAllRequired ? !lender?.value : undefined}
                />
              )}
              sx={{
                width: '230px',
              }}
              onChange={(_, v) => {
                updateFormState('lender', v, formState, setFormState);
              }}
              required={isAllRequired}
            />
          </FormGroup>
        </Paper>

        <Paper>
          <Typography component="h3" variant="h6">
            Borrower
          </Typography>
          <FormGroup>
            <TextField
              id="borrowerFirstName"
              label="First Name"
              value={borrowerFirstName}
              size="small"
              disabled={!editable}
              required
              sx={{
                width: '150px',
              }}
              onChange={(e) => {
                updateFormState('borrowerFirstName', e.target.value, formState, setFormState);
              }}
              error={isAllRequired ? !borrowerFirstName : undefined}
            />
            <TextField
              id="borrowerLastName"
              label="Last Name"
              value={borrowerLastName}
              size="small"
              disabled={!editable}
              required
              sx={{
                width: '150px',
              }}
              onChange={(e) => {
                updateFormState('borrowerLastName', e.target.value, formState, setFormState);
              }}
              error={isAllRequired ? !borrowerLastName : undefined}
            />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                id="borrowerDob"
                label="DOB"
                value={borrowerDob}
                disabled={!editable}
                onChange={(v) => {
                  updateFormState('borrowerDob', v, formState, setFormState);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    error={isAllRequired ? !borrowerDob : undefined}
                    sx={{
                      width: '150px',
                    }}
                    required={isAllRequired}
                  />
                )}
                required={isAllRequired}
              />
            </LocalizationProvider>
            <TextField
              id="borrowerPhone"
              type="number"
              label="Phone"
              value={borrowerPhone}
              size="small"
              disabled={!editable}
              required
              sx={{
                width: '150px',
              }}
              onChange={(e) => {
                updateFormState('borrowerPhone', e.target.value, formState, setFormState);
              }}
              inputProps={{
                pattern: /[+0-9]/,
              }}
              error={isAllRequired ? !borrowerPhone : undefined}
            />
            <TextField
              id="borrowerEmail"
              label="Email"
              value={borrowerEmail}
              size="small"
              disabled={!editable}
              required
              sx={{
                width: '260px',
              }}
              onChange={(e) => {
                updateFormState('borrowerEmail', e.target.value, formState, setFormState);
              }}
              error={isAllRequired ? !borrowerEmail : undefined}
            />
          </FormGroup>
          <FormGroup>
            <TextField
              id="borrowerAddress"
              label="Address"
              value={borrowerAddress}
              size="small"
              disabled={!editable}
              sx={{
                width: '185px',
              }}
              onChange={(e) => {
                updateFormState('borrowerAddress', e.target.value, formState, setFormState);
              }}
              required={isAllRequired}
              error={isAllRequired ? !borrowerAddress : undefined}
            />
            <TextField
              id="borrowerCity"
              label="City"
              value={borrowerCity}
              size="small"
              disabled={!editable}
              sx={{
                width: '150px',
              }}
              onChange={(e) => {
                updateFormState('borrowerCity', e.target.value, formState, setFormState);
              }}
              required={isAllRequired}
              error={isAllRequired ? !borrowerCity : undefined}
            />
            <TextField
              id="borrowerZip"
              label="ZIP"
              value={borrowerZip}
              size="small"
              disabled={!editable}
              inputProps={{
                maxLength: 6,
              }}
              sx={{
                width: '90px',
              }}
              onChange={(e) => {
                updateFormState('borrowerZip', e.target.value, formState, setFormState);
              }}
              required={isAllRequired}
              error={isAllRequired ? !borrowerZip : undefined}
            />
            <Autocomplete
              id="borrowerState"
              size="small"
              disablePortal
              value={borrowerState}
              options={AllStatesOptions}
              disabled={!editable}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="State"
                  error={isAllRequired ? !borrowerState?.value : undefined}
                  sx={{
                    width: '190px',
                  }}
                  required={isAllRequired}
                />
              )}
              onChange={(_, v) => {
                updateFormState('borrowerState', v, formState, setFormState);
              }}
              required={isAllRequired}
            />
            <Autocomplete
              id="borrowerCountry"
              size="small"
              disablePortal
              value={borrowerCountry}
              options={countryOptions}
              disabled={!editable}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Country"
                  error={isAllRequired ? !borrowerCountry?.value : undefined}
                  sx={{
                    width: '120px',
                  }}
                  required={isAllRequired}
                />
              )}
              onChange={(_, v) => {
                updateFormState('borrowerCountry', v, formState, setFormState);
              }}
              required={isAllRequired}
            />
          </FormGroup>
        </Paper>

        {shouldShowCoBorrowerFields ? (
          <Paper>
            <Typography component="h3" variant="h6">
              Co-borrower
            </Typography>
            <FormGroup>
              <TextField
                id="coBorrowerFirstName"
                label="First Name"
                value={coBorrowerFirstName}
                size="small"
                disabled={!editable}
                required={!!coBorrowerLastName}
                sx={{
                  width: '150px',
                }}
                onChange={(e) => {
                  updateFormState('coBorrowerFirstName', e.target.value, formState, setFormState);
                }}
                error={isAllRequired ? !coBorrowerFirstName : undefined}
              />
              <TextField
                id="coBorrowerLastName"
                label="Last Name"
                value={coBorrowerLastName}
                size="small"
                disabled={!editable}
                required={!!coBorrowerFirstName}
                sx={{
                  width: '150px',
                }}
                onChange={(e) => {
                  updateFormState('coBorrowerLastName', e.target.value, formState, setFormState);
                }}
                error={isAllRequired ? !coBorrowerLastName : undefined}
              />
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  id="coBorrowerDob"
                  label="DOB"
                  value={coBorrowerDob}
                  disabled={!editable}
                  onChange={(v) => {
                    updateFormState('coBorrowerDob', v, formState, setFormState);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      error={
                        !!coBorrowerLastName && !!coBorrowerFirstName && isAllRequired ? !coBorrowerDob : undefined
                      }
                      sx={{
                        width: '150px',
                      }}
                      required={!!coBorrowerLastName && !!coBorrowerFirstName && isAllRequired}
                    />
                  )}
                  required={!!coBorrowerLastName && !!coBorrowerFirstName && isAllRequired}
                />
              </LocalizationProvider>
              <TextField
                id="coBorrowerPhone"
                type="number"
                label="Phone"
                value={coBorrowerPhone}
                size="small"
                disabled={!editable}
                required={!!coBorrowerLastName && !!coBorrowerFirstName && isAllRequired}
                sx={{
                  width: '150px',
                }}
                onChange={(e) => {
                  updateFormState('coBorrowerPhone', e.target.value, formState, setFormState);
                }}
                inputProps={{
                  pattern: /[+0-9]/,
                }}
                error={!!coBorrowerLastName && !!coBorrowerFirstName && isAllRequired ? !coBorrowerPhone : undefined}
              />
              <TextField
                id="coBorrowerEmail"
                label="Email"
                value={coBorrowerEmail}
                size="small"
                disabled={!editable}
                required={!!coBorrowerLastName && !!coBorrowerFirstName && isAllRequired}
                sx={{
                  width: '260px',
                }}
                onChange={(e) => {
                  updateFormState('coBorrowerEmail', e.target.value, formState, setFormState);
                }}
                error={!!coBorrowerLastName && !!coBorrowerFirstName && isAllRequired ? !coBorrowerEmail : undefined}
              />
            </FormGroup>
          </Paper>
        ) : null}

        <Paper>
          <Typography component="h3" variant="h6">
            Subject Property
          </Typography>
          <FormGroup>
            <TextField
              id="subjectPropAddress"
              label="Address"
              value={subjectPropAddress}
              size="small"
              disabled={!editable}
              sx={{
                width: '185px',
              }}
              onChange={(e) => {
                updateFormState('subjectPropAddress', e.target.value, formState, setFormState);
              }}
              required
              error={isAllRequired ? !subjectPropAddress : undefined}
            />
            <TextField
              id="subjectPropCity"
              label="City"
              value={subjectPropCity}
              size="small"
              disabled={!editable}
              sx={{
                width: '150px',
              }}
              onChange={(e) => {
                updateFormState('subjectPropCity', e.target.value, formState, setFormState);
              }}
              required
              error={isAllRequired ? !subjectPropCity : undefined}
            />
            <TextField
              id="subjectPropCounty"
              label="County"
              value={subjectPropCounty}
              size="small"
              disabled={!editable}
              sx={{
                width: '150px',
              }}
              onChange={(e) => {
                updateFormState('subjectPropCounty', e.target.value, formState, setFormState);
              }}
              required
              error={isAllRequired ? !subjectPropCounty : undefined}
            />
            <TextField
              id="subjectPropZip"
              label="ZIP"
              value={subjectPropZip}
              size="small"
              disabled={!editable}
              inputProps={{
                maxLength: 6,
              }}
              sx={{
                width: '90px',
              }}
              onChange={(e) => {
                updateFormState('subjectPropZip', e.target.value, formState, setFormState);
              }}
              required
              error={isAllRequired ? !subjectPropZip : undefined}
            />
            <Autocomplete
              id="subjectPropState"
              size="small"
              disablePortal
              value={subjectPropState}
              options={stateOptions}
              disabled={!editable}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="State"
                  error={isAllRequired ? !subjectPropState?.value : undefined}
                  sx={{
                    width: '100px',
                  }}
                  required
                />
              )}
              onChange={(_, v) => {
                const subjectPropStateValue = {
                  ...formState,
                  ...(v?.value === State.CT.value ? { serviceLoanType: null } : {}),
                  subjectPropState: v,
                };
                setFormState(subjectPropStateValue);
              }}
            />
          </FormGroup>

          <FormGroup>
            <Autocomplete
              id="financedUnitCount"
              size="small"
              disablePortal
              value={financedUnitCount}
              options={financedUnitCountOptions}
              disabled={!editable}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Financed unit count"
                  error={isAllRequired ? !financedUnitCount?.value : undefined}
                  sx={{
                    width: '200px',
                  }}
                  required={isAllRequired}
                />
              )}
              onChange={(_, v) => {
                updateFormState('financedUnitCount', v, formState, setFormState);
              }}
            />
            <TextField
              id="subjectPropValuation"
              type="number"
              label="Valuation"
              value={subjectPropValuation}
              size="small"
              disabled={!editable}
              sx={{
                width: '120px',
                '.MuiInputLabel-root': {
                  backgroundColor: '#fff',
                },
                ':after': {
                  content: `'$'`,
                  position: 'absolute',
                  top: '9px',
                  left: '16px',
                },
                input: {
                  paddingLeft: '28px',
                },
              }}
              onChange={(e) => {
                updateFormState('subjectPropValuation', e.target.value, formState, setFormState);
              }}
              required={isAllRequired}
              error={isAllRequired ? !subjectPropValuation || subjectPropValuation < 1 : undefined}
            />
            <Autocomplete
              id="subjectPropType"
              size="small"
              disablePortal
              value={subjectPropType}
              options={propertyTypeOptions}
              disabled={!editable}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Property type"
                  error={isAllRequired ? !subjectPropType?.value : undefined}
                  sx={{
                    width: '275px',
                  }}
                  required={isAllRequired}
                />
              )}
              onChange={(_, v) => {
                updateFormState('subjectPropType', v, formState, setFormState);
              }}
            />
            <Autocomplete
              id="usageType"
              size="small"
              disablePortal
              value={usageType}
              options={usageTypeOptions}
              disabled={!editable}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Usage"
                  error={isAllRequired ? !usageType?.value : undefined}
                  sx={{
                    width: '235px',
                  }}
                  required={isAllRequired}
                />
              )}
              onChange={(_, v) => {
                updateFormState('usageType', v, formState, setFormState);
              }}
            />
          </FormGroup>
        </Paper>

        <Paper>
          <Typography component="h3" variant="h6">
            Application
          </Typography>
          <FormGroup>
            <TextField
              id="interestRate"
              label="Interest rate"
              value={interestRate}
              size="small"
              disabled={!editable}
              required
              sx={{
                width: '125px',
                '.MuiInputLabel-root': {
                  paddingRight: '10px',
                  backgroundColor: '#fff',
                },
                input: {
                  paddingRight: '30px',
                },
                ':after': {
                  content: `'%'`,
                  position: 'absolute',
                  top: '9px',
                  right: '16px',
                  fontWeight: 'bold',
                },
              }}
              onChange={(e) => {
                updateFormState('interestRate', e.target.value, formState, setFormState);
              }}
              error={isAllRequired ? !interestRate : undefined}
            />
            <Autocomplete
              id="amortizationType"
              size="small"
              disablePortal
              value={amortizationType}
              options={amortizationTypeOptions}
              disabled={!editable}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Amortization type"
                  error={isAllRequired ? !amortizationType?.value : undefined}
                  sx={{
                    width: '190px',
                  }}
                  required={isAllRequired}
                />
              )}
              onChange={(_, v) => {
                updateFormState('amortizationType', v, formState, setFormState);
              }}
            />
            <TextField
              id="amortizationPeriod"
              type="number"
              label="Amortization (months)"
              value={amortizationPeriod}
              size="small"
              disabled={!editable}
              sx={{
                width: '205px',
              }}
              onChange={(e) => {
                updateFormState('amortizationPeriod', e.target.value, formState, setFormState);
              }}
              required={isAllRequired}
              error={isAllRequired ? !amortizationPeriod : undefined}
            />
            <TextField
              id="term"
              type="number"
              label="Due (months)"
              value={term}
              size="small"
              disabled={!editable}
              sx={{
                width: '175px',
              }}
              onChange={(e) => {
                updateFormState('term', e.target.value, formState, setFormState);
              }}
              required={isAllRequired}
              error={isAllRequired ? !term : undefined}
            />
            <TextField
              id="loanAmount"
              type="number"
              label="Loan amount"
              value={loanAmount}
              size="small"
              disabled={loanAmountLocked ? true : !editable}
              sx={{
                width: '140px',
                '.MuiInputLabel-root': {
                  backgroundColor: '#fff',
                },
                ':after': {
                  content: `'$'`,
                  position: 'absolute',
                  top: '9px',
                  left: '16px',
                },
                input: {
                  paddingLeft: '28px',
                },
              }}
              onChange={(e) => {
                updateFormState('loanAmount', e.target.value, formState, setFormState);
              }}
              required={isAllRequired}
              error={isAllRequired ? !loanAmount || loanAmount < 1 : undefined}
            />
            <Autocomplete
              id="mortgageType"
              size="small"
              disablePortal
              value={mortgageType}
              options={mortgageTypeOptions}
              disabled={!editable}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Mortgage type"
                  required
                  error={isAllRequired ? !mortgageType?.value : undefined}
                  sx={{
                    width: '180px',
                  }}
                />
              )}
              onChange={(_, v) => {
                updateFormState('mortgageType', v, formState, setFormState);
              }}
            />
            <Autocomplete
              id="serviceLoanType"
              size="small"
              disablePortal
              value={serviceLoanType}
              options={serviceLoanTypeOptions}
              disabled={isServiceLoanDisabled}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Service loan type"
                  required={isServiceLoanRequired}
                  error={isAllRequired ? !serviceLoanType?.value : undefined}
                  sx={{
                    width: '185px',
                  }}
                />
              )}
              onChange={(_, v) => {
                updateFormState('serviceLoanType', v, formState, setFormState);
              }}
            />
            <Autocomplete
              id="lienPriorityType"
              size="small"
              disablePortal
              value={lienPriorityType}
              options={lienPriorityTypeOptions}
              disabled={!editable}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Lien Priority Type"
                  error={isAllRequired ? !lienPriorityType?.value : undefined}
                  sx={{
                    width: '275px',
                  }}
                  required={isAllRequired}
                />
              )}
              onChange={(_, v) => {
                updateFormState('lienPriorityType', v, formState, setFormState);
              }}
            />
            <Autocomplete
              id="loanPurpose"
              size="small"
              disablePortal
              value={loanPurpose}
              options={loanPurposeOptions}
              disabled={!editable}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Loan purpose"
                  error={isAllRequired ? !loanPurpose?.value : undefined}
                  sx={{
                    width: '275px',
                  }}
                  required={isAllRequired}
                />
              )}
              onChange={(_, v) => {
                updateFormState('loanPurpose', v, formState, setFormState);
              }}
            />
          </FormGroup>
        </Paper>

        <Paper>
          <Typography component="h3" variant="h6">
            Compliance
          </Typography>
          <FormGroup>
            <TextField
              id="closingLoanAmount"
              type="number"
              label="Closing loan amount"
              value={closingLoanAmount}
              size="small"
              disabled={!editable}
              sx={{
                width: '190px',
                '.MuiInputLabel-root': {
                  backgroundColor: '#fff',
                },
                ':after': {
                  content: `'$'`,
                  position: 'absolute',
                  top: '9px',
                  left: '16px',
                },
                input: {
                  paddingLeft: '28px',
                },
              }}
              onChange={(e) => {
                updateFormState('closingLoanAmount', e.target.value, formState, setFormState);
              }}
              required={isAllRequired}
              inputProps={{ min: isAllRequired ? 1 : 0 }}
              error={isAllRequired ? !closingLoanAmount || closingLoanAmount < 1 : undefined}
            />
            <TextField
              id="brokerFeesCollected"
              type="number"
              label="Broker fees collected"
              value={brokerFeesCollected}
              size="small"
              disabled={!editable}
              sx={{
                width: '185px',
                '.MuiInputLabel-root': {
                  backgroundColor: '#fff',
                },
                ':after': {
                  content: `'$'`,
                  position: 'absolute',
                  top: '9px',
                  left: '16px',
                },
                input: {
                  paddingLeft: '28px',
                },
              }}
              onChange={(e) => {
                updateFormState('brokerFeesCollected', e.target.value, formState, setFormState);
              }}
              required={isMinRequired}
              inputProps={{ min: isMinRequired ? 1 : 0 }}
              error={isMinRequired ? !brokerFeesCollected || brokerFeesCollected < 1 : undefined}
            />
            <Autocomplete
              id="loanType"
              size="small"
              disablePortal
              value={loanType}
              options={loanTypeOptions}
              disabled={!editable}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Loan type"
                  required
                  error={isAllRequired ? !loanType?.value : undefined}
                  sx={{
                    width: '215px',
                  }}
                />
              )}
              onChange={(_, v) => {
                updateFormState('loanType', v, formState, setFormState);
              }}
            />
            <Autocomplete
              id="applicationType"
              size="small"
              disablePortal
              value={applicationType}
              options={applicationTypeOptions}
              disabled={!editable}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Application type"
                  error={isAllRequired ? !applicationType?.value : undefined}
                  sx={{
                    width: '295px',
                  }}
                  required={isAllRequired}
                />
              )}
              onChange={(_, v) => {
                updateFormState('applicationType', v, formState, setFormState);
              }}
            />
            <Autocomplete
              id="receivedFrom"
              size="small"
              disablePortal
              value={receivedFrom}
              options={receivedFromOptions}
              disabled={!editable}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Received from"
                  required
                  error={isAllRequired ? !receivedFrom?.value : undefined}
                  sx={{
                    width: '200px',
                  }}
                />
              )}
              onChange={(_, v) => {
                updateFormState('receivedFrom', v, formState, setFormState);
              }}
            />
            <Autocomplete
              id="documentationType"
              size="small"
              disablePortal
              value={documentationType}
              options={documentationTypeOptions}
              disabled={!editable}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Documentation"
                  error={isAllRequired ? !documentationType?.value : undefined}
                  sx={{
                    width: '170px',
                  }}
                  required={isAllRequired}
                />
              )}
              onChange={(_, v) => {
                updateFormState('documentationType', v, formState, setFormState);
              }}
            />
            <Autocomplete
              id="loanOriginatorName"
              size="small"
              disablePortal
              value={loanOriginatorName}
              options={loanOfficerOptions}
              disabled={!editable}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Loan officer"
                  required
                  error={isAllRequired ? !loanOriginatorName?.value : undefined}
                  sx={{
                    width: '200px',
                  }}
                />
              )}
              onChange={(_, v) => {
                updateFormState('loanOriginatorName', v, formState, setFormState);
              }}
            />
          </FormGroup>

          <FormGroup>
            <FormControl
              sx={{
                flexDirection: 'row',
                alignItems: 'center',
                marginRight: '20px',
              }}
            >
              <FormLabel
                component="legend"
                sx={{
                  marginRight: '14px',
                }}
              >
                Repurchase
              </FormLabel>
              <RadioGroup
                value={isRepurchase}
                name="repurchase"
                onChange={(e) => {
                  updateFormState('isRepurchase', e.target.value === 'true', formState, setFormState);
                }}
                required={isAllRequired}
              >
                <FormControlLabel value control={<Radio disabled={!editable} />} label="Yes" />
                <FormControlLabel value={false} control={<Radio disabled={!editable} />} label="No" />
              </RadioGroup>
            </FormControl>
            <FormControl
              sx={{
                flexDirection: 'row',
                alignItems: 'center',
                marginRight: '20px',
              }}
            >
              <FormLabel
                component="legend"
                sx={{
                  marginRight: '14px',
                }}
              >
                HOEPA
              </FormLabel>
              <RadioGroup
                value={isHoepa}
                name="hoepa"
                onChange={(e) => {
                  updateFormState('isHoepa', e.target.value === 'true', formState, setFormState);
                }}
                required={isAllRequired}
              >
                <FormControlLabel value control={<Radio disabled={!editable} />} label="Yes" />
                <FormControlLabel value={false} control={<Radio disabled={!editable} />} label="No" />
              </RadioGroup>
            </FormControl>
            <FormControl
              sx={{
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <FormLabel
                component="legend"
                sx={{
                  marginRight: '14px',
                }}
              >
                Home improvement
              </FormLabel>
              <RadioGroup
                value={homeImprovement}
                name="homeImprovement"
                onChange={(e) => {
                  updateFormState('homeImprovement', e.target.value === 'true', formState, setFormState);
                }}
                required={isAllRequired}
              >
                <FormControlLabel value control={<Radio disabled={!editable} />} label="Yes" />
                <FormControlLabel value={false} control={<Radio disabled={!editable} />} label="No" />
              </RadioGroup>
            </FormControl>
          </FormGroup>
        </Paper>
      </Box>
      <ActionModal {...fileCloseModalProps} />
      <ActionModal {...fileCreateModalProps} />
      <ActionModal {...fileEditModalProps} />
    </>
  );
};

FormFile.propTypes = {
  mode: PropTypes.oneOf(['VIEW', 'EDIT', 'CREATE']).isRequired,
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.shape(),
  formId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
};

FormFile.defaultProps = {
  data: {},
  onSubmit: null,
};

export default FormFile;
