import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import AlertToast from './AlertToast';

const Content = ({ children }) => (
  <Box
    component="main"
    sx={{
      minHeight: '100vh',
      flexGrow: 1,
      position: 'relative',
      padding: '96px 32px 32px 32px',
      background: '#f5f5f5',
    }}
  >
    <AlertToast />
    {children}
  </Box>
);

Content.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Content;
