import {
  AmortizationType,
  ApplicationType,
  DocumentationType,
  LienPriorityType,
  LoanPurpose,
  LoanStatus,
  LoanType,
  MortgageType,
  PropertyType,
  ReceivedFrom,
  ReverseMortgagePurpose,
  ReverseMortgageType,
  ServiceLoanType,
  UsageType,
  Country,
  State,
  AllStates,
  Quarter,
} from 'constants/enums';

// Amortization type options
export const amortizationTypeOptions = [
  {
    label: AmortizationType.Adjustable.label,
    value: AmortizationType.Adjustable.value,
  },
  {
    label: AmortizationType.Balloon.label,
    value: AmortizationType.Balloon.value,
  },
  {
    label: AmortizationType.Fixed.label,
    value: AmortizationType.Fixed.value,
  },
];

// Application type options
export const applicationTypeOptions = [
  {
    label: ApplicationType.Brokered_Loan.label,
    value: ApplicationType.Brokered_Loan.value,
  },
  {
    label: ApplicationType.Closed_Retail_Application.label,
    value: ApplicationType.Closed_Retail_Application.value,
  },
  {
    label: ApplicationType.Closed_Wholesale_Application.label,
    value: ApplicationType.Closed_Wholesale_Application.value,
  },
];

// Countries options
export const countryOptions = [
  {
    label: Country.US.label,
    value: Country.US.value,
  },
];

// State options
export const stateOptions = [
  {
    label: State.CT.label,
    value: State.CT.value,
  },
  {
    label: State.MA.label,
    value: State.MA.value,
  },
];

// All states options
export const AllStatesOptions = [
  {
    label: AllStates.AL.label,
    value: AllStates.AL.value,
  },
  {
    label: AllStates.AK.label,
    value: AllStates.AK.value,
  },
  {
    label: AllStates.AZ.label,
    value: AllStates.AZ.value,
  },
  {
    label: AllStates.AR.label,
    value: AllStates.AR.value,
  },
  {
    label: AllStates.CA.label,
    value: AllStates.CA.value,
  },
  {
    label: AllStates.CO.label,
    value: AllStates.CO.value,
  },
  {
    label: AllStates.CT.label,
    value: AllStates.CT.value,
  },
  {
    label: AllStates.DE.label,
    value: AllStates.DE.value,
  },
  {
    label: AllStates.DC.label,
    value: AllStates.DC.value,
  },
  {
    label: AllStates.FL.label,
    value: AllStates.FL.value,
  },
  {
    label: AllStates.GA.label,
    value: AllStates.GA.value,
  },
  {
    label: AllStates.HI.label,
    value: AllStates.HI.value,
  },
  {
    label: AllStates.ID.label,
    value: AllStates.ID.value,
  },
  {
    label: AllStates.IL.label,
    value: AllStates.IL.value,
  },
  {
    label: AllStates.IN.label,
    value: AllStates.IN.value,
  },
  {
    label: AllStates.IA.label,
    value: AllStates.IA.value,
  },
  {
    label: AllStates.KS.label,
    value: AllStates.KS.value,
  },
  {
    label: AllStates.KY.label,
    value: AllStates.KY.value,
  },
  {
    label: AllStates.LA.label,
    value: AllStates.LA.value,
  },
  {
    label: AllStates.ME.label,
    value: AllStates.ME.value,
  },
  {
    label: AllStates.MD.label,
    value: AllStates.MD.value,
  },
  {
    label: AllStates.MA.label,
    value: AllStates.MA.value,
  },
  {
    label: AllStates.MI.label,
    value: AllStates.MI.value,
  },
  {
    label: AllStates.MN.label,
    value: AllStates.MN.value,
  },
  {
    label: AllStates.MS.label,
    value: AllStates.MS.value,
  },
  {
    label: AllStates.MO.label,
    value: AllStates.MO.value,
  },
  {
    label: AllStates.MT.label,
    value: AllStates.MT.value,
  },
  {
    label: AllStates.NE.label,
    value: AllStates.NE.value,
  },
  {
    label: AllStates.NV.label,
    value: AllStates.NV.value,
  },
  {
    label: AllStates.NH.label,
    value: AllStates.NH.value,
  },
  {
    label: AllStates.NJ.label,
    value: AllStates.NJ.value,
  },
  {
    label: AllStates.NM.label,
    value: AllStates.NM.value,
  },
  {
    label: AllStates.NY.label,
    value: AllStates.NY.value,
  },
  {
    label: AllStates.NC.label,
    value: AllStates.NC.value,
  },
  {
    label: AllStates.ND.label,
    value: AllStates.ND.value,
  },
  {
    label: AllStates.OH.label,
    value: AllStates.OH.value,
  },
  {
    label: AllStates.OK.label,
    value: AllStates.OK.value,
  },
  {
    label: AllStates.OR.label,
    value: AllStates.OR.value,
  },
  {
    label: AllStates.PA.label,
    value: AllStates.PA.value,
  },
  {
    label: AllStates.RI.label,
    value: AllStates.RI.value,
  },
  {
    label: AllStates.SC.label,
    value: AllStates.SC.value,
  },
  {
    label: AllStates.SD.label,
    value: AllStates.SD.value,
  },
  {
    label: AllStates.TN.label,
    value: AllStates.TN.value,
  },
  {
    label: AllStates.TX.label,
    value: AllStates.TX.value,
  },
  {
    label: AllStates.UT.label,
    value: AllStates.UT.value,
  },
  {
    label: AllStates.VT.label,
    value: AllStates.VT.value,
  },
  {
    label: AllStates.VA.label,
    value: AllStates.VA.value,
  },
  {
    label: AllStates.WA.label,
    value: AllStates.WA.value,
  },
  {
    label: AllStates.WV.label,
    value: AllStates.WV.value,
  },
  {
    label: AllStates.WI.label,
    value: AllStates.WI.value,
  },
  {
    label: AllStates.WY.label,
    value: AllStates.WY.value,
  },
];

// Quarter options
export const quarterOptions = [
  {
    label: Quarter.Q1.label,
    value: Quarter.Q1.value,
  },
  {
    label: Quarter.Q2.label,
    value: Quarter.Q2.value,
  },
  {
    label: Quarter.Q3.label,
    value: Quarter.Q3.value,
  },
  {
    label: Quarter.Q4.label,
    value: Quarter.Q4.value,
  },
];

// Documentation options
export const documentationTypeOptions = [
  {
    label: DocumentationType.Full.label,
    value: DocumentationType.Full.value,
  },
  {
    label: DocumentationType.Partial.label,
    value: DocumentationType.Partial.value,
  },
  {
    label: DocumentationType.None.label,
    value: DocumentationType.None.value,
  },
];

// Financed unit count options
export const financedUnitCountOptions = [
  {
    label: '1',
    value: 1,
  },
  {
    label: '2',
    value: 2,
  },
  {
    label: '3',
    value: 3,
  },
  {
    label: '4',
    value: 4,
  },
];

// Lender options
export const lenderOptions = [
  {
    label: 'ARC',
    value: 'ARC',
  },
  {
    label: 'Caliber',
    value: 'Caliber',
  },
  {
    label: 'Citizen',
    value: 'Citizen',
  },
  {
    label: 'Finance of America',
    value: 'Finance of America',
  },
  {
    label: 'Home Express',
    value: 'Home Express',
  },
  {
    label: 'PennyMAC',
    value: 'PennyMAC',
  },
  {
    label: 'Plaza Home',
    value: 'Plaza Home',
  },
  {
    label: 'REMN',
    value: 'REMN',
  },
  {
    label: 'Stearns',
    value: 'Stearns',
  },
  {
    label: 'UWM',
    value: 'UWM',
  },
];

// Lien priority type options
export const lienPriorityTypeOptions = [
  {
    label: LienPriorityType.First_Lien.label,
    value: LienPriorityType.First_Lien.value,
  },
  {
    label: LienPriorityType.HELOC.label,
    value: LienPriorityType.HELOC.value,
  },
  {
    label: LienPriorityType.Not_Secured_By_A_Lien.label,
    value: LienPriorityType.Not_Secured_By_A_Lien.value,
  },
  {
    label: LienPriorityType.Second_Lien.label,
    value: LienPriorityType.Second_Lien.value,
  },
  {
    label: LienPriorityType.Simultaneous_Second_Lien.label,
    value: LienPriorityType.Simultaneous_Second_Lien.value,
  },
  {
    label: LienPriorityType.Standalone.label,
    value: LienPriorityType.Standalone.value,
  },
];

// Loan purpose options
export const loanPurposeOptions = [
  {
    label: LoanPurpose.Purchase.label,
    value: LoanPurpose.Purchase.value,
  },
  {
    label: LoanPurpose.Cash_Out_Refinance.label,
    value: LoanPurpose.Cash_Out_Refinance.value,
  },
  {
    label: LoanPurpose.Limited_Cash_Out_Refinance.label,
    value: LoanPurpose.Limited_Cash_Out_Refinance.value,
  },
  {
    label: LoanPurpose.No_Cash_Out_Refinance.label,
    value: LoanPurpose.No_Cash_Out_Refinance.value,
  },
  {
    label: LoanPurpose.Other.label,
    value: LoanPurpose.Other.value,
  },
];

// Loan status options
export const loanStatusOptions = [
  {
    label: LoanStatus.Approved_Not_Accepted.label,
    value: LoanStatus.Approved_Not_Accepted.value,
  },
  {
    label: LoanStatus.Denied.label,
    value: LoanStatus.Denied.value,
  },
  {
    label: LoanStatus.Withdrawn.label,
    value: LoanStatus.Withdrawn.value,
  },
  {
    label: LoanStatus.Incomplete.label,
    value: LoanStatus.Incomplete.value,
  },
  {
    label: LoanStatus.Pre_Approval_Denied.label,
    value: LoanStatus.Pre_Approval_Denied.value,
  },
  {
    label: LoanStatus.Pre_Approval_Approved_Not_Accepted.label,
    value: LoanStatus.Pre_Approval_Approved_Not_Accepted.value,
  },
  {
    label: LoanStatus.Closed_Funded.label,
    value: LoanStatus.Closed_Funded.value,
  },
  {
    label: LoanStatus.In_Process.label,
    value: LoanStatus.In_Process.value,
  },
];

// Loan type options
export const loanTypeOptions = [
  {
    label: LoanType.QM.label,
    value: LoanType.QM.value,
  },
  {
    label: LoanType.Non_QM.label,
    value: LoanType.Non_QM.value,
  },
  {
    label: LoanType.Not_Subject_To_QM.label,
    value: LoanType.Not_Subject_To_QM.value,
  },
];

// Mortgage type options
export const mortgageTypeOptions = [
  {
    label: MortgageType.Conventional.label,
    value: MortgageType.Conventional.value,
  },
  {
    label: MortgageType.FHA.label,
    value: MortgageType.FHA.value,
  },
  {
    label: MortgageType.VA.label,
    value: MortgageType.VA.value,
  },
  {
    label: MortgageType.USDA_RHS.label,
    value: MortgageType.USDA_RHS.value,
  },
  {
    label: MortgageType.Other.label,
    value: MortgageType.Other.value,
  },
];

// Property type options
export const propertyTypeOptions = [
  {
    label: PropertyType.One_To_Four_Family.label,
    value: PropertyType.One_To_Four_Family.value,
  },
  {
    label: PropertyType.Manufactured_Housing.label,
    value: PropertyType.Manufactured_Housing.value,
  },
  {
    label: PropertyType.Multifamily.label,
    value: PropertyType.Multifamily.value,
  },
];

// Received from options
export const receivedFromOptions = [
  {
    label: ReceivedFrom.Borrower.label,
    value: ReceivedFrom.Borrower.value,
  },
  {
    label: ReceivedFrom.Third_Party.label,
    value: ReceivedFrom.Third_Party.value,
  },
];

// Reverse mortgage purpose
export const reverseMortgagePurposeOptions = [
  {
    label: ReverseMortgagePurpose.Home_Purchase.label,
    value: ReverseMortgagePurpose.Home_Purchase.value,
  },
  {
    label: ReverseMortgagePurpose.Other.label,
    value: ReverseMortgagePurpose.Other.value,
  },
];

// Reverse mortgage type options
export const reverseMortgageTypeOptions = [
  {
    label: ReverseMortgageType.HECM_Standard.label,
    value: ReverseMortgageType.HECM_Standard.value,
  },
  {
    label: ReverseMortgageType.HECM_Saver.label,
    value: ReverseMortgageType.HECM_Saver.value,
  },
  {
    label: ReverseMortgageType.Propriatary_Other.label,
    value: ReverseMortgageType.Propriatary_Other.value,
  },
];

// Service loan type options
export const serviceLoanTypeOptions = [
  {
    label: ServiceLoanType.Released.label,
    value: ServiceLoanType.Released.value,
  },
  {
    label: ServiceLoanType.Retained.label,
    value: ServiceLoanType.Retained.value,
  },
];

// Usage type options
export const usageTypeOptions = [
  {
    label: UsageType.FHA_Secondary.label,
    value: UsageType.FHA_Secondary.value,
  },
  {
    label: UsageType.Investment.label,
    value: UsageType.Investment.value,
  },
  {
    label: UsageType.Primary_Residence.label,
    value: UsageType.Primary_Residence.value,
  },
  {
    label: UsageType.Second_Home.label,
    value: UsageType.Second_Home.value,
  },
];
