import React, { useEffect } from 'react';
import { Alert, Collapse } from '@mui/material';
import { useAlertContext } from '../context/AlertContext';

export default function AlertToast() {
  const { alertState, closeAlert } = useAlertContext();

  // Set alert state
  const { isOpen, severity, text } = alertState;

  // Clear alert after 3 seconds
  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        closeAlert(severity, text);
      }, 3000);
    }
  }, [isOpen, closeAlert, severity, text]);

  return (
    <Collapse in={isOpen} timeout={500}>
      <Alert
        variant="filled"
        severity={severity}
        sx={{
          marginBottom: '20px',
        }}
      >
        {text}
      </Alert>
    </Collapse>
  );
}
