import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import { API, graphqlOperation } from 'aws-amplify';
import { useAuthContext } from './AuthContext';
import { cacheKeys } from '../conf';
import { listFilesByTenant, listReportFilesByTenant } from '../graphql/queries';
import { boolToStringBoolean } from '../utils/enumUtils';
import { LoanStatus, ModelSortDirection } from '../constants/enums';

const FileContext = React.createContext({
  isLoading: false,
  error: null,
  getFiles: () => {},
  getFile: () => {},
  getInProcessFiles: () => {},
  getImports: () => {},
});

export const FileProvider = ({ children }) => {
  const { tenantId, isAuthenticated, refresh } = useAuthContext();

  const { isLoading, isError, isSuccess, error, data } = useQuery(
    [cacheKeys.getFiles, tenantId],
    async () => {
      refresh();
      return API.graphql(
        graphqlOperation(listFilesByTenant, {
          tenantId,
          limit: 10000,
          sortDirection: ModelSortDirection.DESC,
          filter: {
            isDeleted: { eq: boolToStringBoolean(false) },
          },
        })
      );
    },
    {
      enabled: isAuthenticated && !!tenantId,
    }
  );

  const getFiles = useMemo(() => (isSuccess ? data.data.listFilesByTenant.items : []), [isSuccess, data]);

  const getInProcessFiles = useMemo(
    () =>
      isSuccess ? data.data.listFilesByTenant.items.filter((it) => it.loanStatus === LoanStatus.In_Process.value) : [],
    [isSuccess, data]
  );

  const getFile = useCallback(
    (id) => (isSuccess ? data.data.listFilesByTenant.items.find((it) => it.id === id) : {}),
    [data, isSuccess]
  );

  const getImports = useMemo(
    () =>
      isSuccess
        ? data.data.listFilesByTenant.items
            .filter((f) => !f.isReverseMortgage)
            .map((f) => {
              const { id, borrowerFirstName, borrowerLastName, importFile, importDate, subjectPropState } = f;
              return {
                id,
                borrowerFirstName,
                borrowerLastName,
                importFile,
                importDate,
                subjectPropState,
              };
            })
        : [],
    [isSuccess, data]
  );

  const checkFileDeleteStatus = useCallback(
    async (id) => {
      const res = await API.graphql(
        graphqlOperation(listReportFilesByTenant, {
          tenantId,
          isDeleted: { eq: boolToStringBoolean(false) },
          limit: 10000,
          filter: {
            fileId: { eq: id },
          },
        })
      );
      const result = !res.data?.listReportFilesByTenant?.items?.length;
      // console.log(result);
      return result;
    },
    [tenantId]
  );

  const exportValue = useMemo(
    () => ({
      isLoading,
      isError,
      isSuccess,
      error,
      getFiles,
      getImports,
      getFile,
      getInProcessFiles,
      checkFileDeleteStatus,
    }),
    [checkFileDeleteStatus, error, getFile, getFiles, getImports, getInProcessFiles, isError, isLoading, isSuccess]
  );

  return <FileContext.Provider value={exportValue}>{children}</FileContext.Provider>;
};

FileProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useFileContext = () => React.useContext(FileContext);
