import React from 'react';
import PropTypes from 'prop-types';
import { Box, CssBaseline } from '@mui/material';
import Content from './Content';
import Header from './Header';
import NavMain from './NavMain';

const Main = ({ children, pagetitle }) => (
  <Box
    sx={{
      display: 'flex',
    }}
  >
    <CssBaseline />
    <Header pagetitle={pagetitle} />
    <NavMain />
    <Content>{children}</Content>
  </Box>
);

Main.propTypes = {
  children: PropTypes.node.isRequired,
  pagetitle: PropTypes.string.isRequired,
};

export default Main;
