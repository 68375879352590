import groupBy from 'lodash/fp/groupBy';
import uniqBy from 'lodash/fp/uniqBy';
import {
  LoanStatus,
  MortgageType,
  ApplicationType,
  PropertyType,
  LoanPurpose,
  LienPriorityType,
  ReverseMortgageType,
  ReverseMortgagePurpose,
  LoanType,
  ServiceLoanType,
} from '../constants/enums';
import loanOriginators from '../loanOriginators';
import { getFormattedDateFromString } from './dateUtils';

const numberFormatter = new Intl.NumberFormat('en-US', {
  // style: 'currency',
  // currency: 'USD',
  maximumFractionDigits: 0,
});

const getLastFileStatus = (files) => {
  const res = files.map((f) => {
    const {
      statusLogs: { items },
    } = f;
    if (!items.length) return f;
    const [lastStatusChange] = items;
    // console.log(`Current Status: ${f.loanStatus}`);
    // console.log(`Last Status Change: ${lastStatusChange.loanStatus}`);
    return {
      ...f,
      loanStatus: lastStatusChange.loanStatus,
    };
  });
  return res;
};

const getFilesByStatus = (files) => {
  const filesWithLastStatus = getLastFileStatus(files);
  const grouped = groupBy('loanStatus', filesWithLastStatus);
  return Object.keys(grouped).map((it) => {
    const f = grouped[it];
    return {
      loanStatus: LoanStatus[it].label,
      loanStatusValue: it,
      files: f,
      count: f.length,
    };
  });
};

const getDefaultAC010ForState = (state) => {
  if (state === 'CT') {
    return {
      AC010DRAMT: 4548697,
      AC010DRCT: 18,
      AC010DRAVG: 252927,
      AC010TPAMT: 0,
      AC010TPCT: 0,
      AC010TPAVG: 0,
    };
  }
  return {
    AC010DRAMT: 0,
    AC010DRCT: 0,
    AC010DRAVG: 0,
    AC010TPAMT: 0,
    AC010TPCT: 0,
    AC010TPAVG: 0,
  };
};

const getAC010FromReport = (data) => {
  const parsed = JSON.parse(data);
  const { AC080DRAMT, AC080DRCT, AC080DRAVG, AC080TPAMT, AC080TPCT, AC080TPAVG } = parsed;
  return {
    AC010DRAMT: AC080DRAMT,
    AC010DRCT: AC080DRCT,
    AC010DRAVG: AC080DRAVG,
    AC010TPAMT: AC080TPAMT,
    AC010TPCT: AC080TPCT,
    AC010TPAVG: AC080TPAVG,
  };
};

export const generateReportData = (
  year,
  quarter,
  state,
  latestReport,
  receivedWithinQuarter,
  completedWithinQuarter,
  inProcessAtEndOfQuarter,
  explanatoryNotes = '',
  baseFileUrl = 'https://mcrpotato.com/file'
) => {
  const allFiles = uniqBy(
    (f) => f.id,
    receivedWithinQuarter
      .concat(completedWithinQuarter)
      .concat(inProcessAtEndOfQuarter)
      .map((f) => ({
        id: f.id,
        borrowerFirstName: f.borrowerFirstName,
        borrowerLastName: f.borrowerLastName,
        loanAmount: numberFormatter.format(f.loanAmount || 0),
        closingDate: getFormattedDateFromString(f.closingDate) || '',
        url: `${baseFileUrl}/${f.id}`,
        loanStatus: f.loanStatus,
        statusLogs: f.statusLogs,
      }))
  );
  const forwardComplete = completedWithinQuarter.filter((it) => !it.isReverseMortgage);
  const reverseComplete = completedWithinQuarter.filter((it) => it.isReverseMortgage);
  // const completedGroupedByLoanOfficer = groupBy('loanOriginatorName', completedWithinQuarter);

  // hard-coding AC010 from Q3/2021 for this round
  const { AC010DRAMT, AC010DRCT, AC010DRAVG, AC010TPAMT, AC010TPCT, AC010TPAVG } = latestReport
    ? getAC010FromReport(latestReport.data)
    : getDefaultAC010ForState(state);
  // ACO20
  const receivedGroupedByReceivedFrom = groupBy('receivedFrom', receivedWithinQuarter);
  // Borrower
  const AC020DIRECT = receivedGroupedByReceivedFrom.Borrower || [];
  const AC020DRAMT = AC020DIRECT.reduce((acc, nx) => acc + nx.loanAmount, 0);
  const AC020DRCT = AC020DIRECT.length;
  const AC020DRAVG = AC020DRCT === 0 ? 0 : AC020DRAMT / AC020DRCT;
  // Third-Party
  const AC020TP = receivedGroupedByReceivedFrom.Third_Party || [];
  const AC020TPAMT = AC020TP.reduce((acc, nx) => acc + nx.loanAmount, 0);
  const AC020TPCT = AC020TP.length;
  const AC020TPAVG = AC020TPCT === 0 ? 0 : AC020TPAMT / AC020TPCT;

  const completedGroupedByReceivedFrom = groupBy('receivedFrom', completedWithinQuarter);
  const completedDirect = completedGroupedByReceivedFrom.Borrower || [];
  const completedTP = completedGroupedByReceivedFrom.Third_Party || [];

  // AC030
  const AC030DIRECT = completedDirect.filter((it) => it.loanStatus === LoanStatus.Approved_Not_Accepted.value);
  const AC030DRAMT = AC030DIRECT.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC030DRCT = AC030DIRECT.length;
  const AC030DRAVG = AC030DRCT === 0 ? 0 : AC030DRAMT / AC030DRCT;
  const AC030TP = completedTP.filter((it) => it.loanStatus === LoanStatus.Approved_Not_Accepted.value);
  const AC030TPAMT = AC030TP.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC030TPCT = AC030TP.length;
  const AC030TPAVG = AC030TPCT === 0 ? 0 : AC030TPAMT / AC030TPCT;

  // AC040
  const AC040DIRECT = completedDirect.filter((it) => it.loanStatus === LoanStatus.Denied.value);
  const AC040DRAMT = AC040DIRECT.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC040DRCT = AC040DIRECT.length;
  const AC040DRAVG = AC040DRCT === 0 ? 0 : AC040DRAMT / AC040DRCT;
  const AC040TP = completedTP.filter((it) => it.loanStatus === LoanStatus.Denied.value);
  const AC040TPAMT = AC040TP.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC040TPCT = AC040TP.length;
  const AC040TPAVG = AC040TPCT === 0 ? 0 : AC040TPAMT / AC040TPCT;

  // AC050
  const AC050DIRECT = completedDirect.filter((it) => it.loanStatus === LoanStatus.Withdrawn.value);
  const AC050DRAMT = AC050DIRECT.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC050DRCT = AC050DIRECT.length;
  const AC050DRAVG = AC050DRCT === 0 ? 0 : AC050DRAMT / AC050DRCT;
  const AC050TP = completedTP.filter((it) => it.loanStatus === LoanStatus.Withdrawn.value);
  const AC050TPAMT = AC050TP.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC050TPCT = AC050TP.length;
  const AC050TPAVG = AC050TPCT === 0 ? 0 : AC050TPAMT / AC050TPCT;

  // AC060
  const AC060DIRECT = completedDirect.filter((it) => it.loanStatus === LoanStatus.Incomplete.value);
  const AC060DRAMT = AC060DIRECT.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC060DRCT = AC060DIRECT.length;
  const AC060DRAVG = AC060DRCT === 0 ? 0 : AC060DRAMT / AC060DRCT;
  const AC060TP = completedTP.filter((it) => it.loanStatus === LoanStatus.Incomplete.value);
  const AC060TPAMT = AC060TP.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC060TPCT = AC060TP.length;
  const AC060TPAVG = AC060TPCT === 0 ? 0 : AC060TPAMT / AC060TPCT;

  // AC062
  const AC062DIRECT = completedDirect.filter((it) => it.loanStatus === LoanStatus.Pre_Approval_Denied.value);
  const AC062DRAMT = AC062DIRECT.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC062DRCT = AC062DIRECT.length;
  const AC062DRAVG = AC062DRCT === 0 ? 0 : AC062DRAMT / AC062DRCT;
  const AC062TP = completedTP.filter((it) => it.loanStatus === LoanStatus.Pre_Approval_Denied.value);
  const AC062TPAMT = AC062TP.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC062TPCT = AC062TP.length;
  const AC062TPAVG = AC062TPCT === 0 ? 0 : AC062TPAMT / AC062TPCT;

  // AC064
  const AC064DIRECT = completedDirect.filter(
    (it) => it.loanStatus === LoanStatus.Pre_Approval_Approved_Not_Accepted.value
  );
  const AC064DRAMT = AC064DIRECT.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC064DRCT = AC064DIRECT.length;
  const AC064DRAVG = AC064DRCT === 0 ? 0 : AC064DRAMT / AC064DRCT;
  const AC064TP = completedTP.filter((it) => it.loanStatus === LoanStatus.Pre_Approval_Approved_Not_Accepted.value);
  const AC064TPAMT = AC064TP.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC064TPCT = AC064TP.length;
  const AC064TPAVG = AC064TPCT === 0 ? 0 : AC064TPAMT / AC064TPCT;

  // Closed and Funded
  const CLOSEDDIRECT = completedDirect.filter((it) => it.loanStatus === LoanStatus.Closed_Funded.value);
  const CLOSEDTP = completedTP.filter((it) => it.loanStatus === LoanStatus.Closed_Funded.value);

  // AC065
  // CLOSEDDIRECT.filter((it) => it.loanAmount !== it.closingLoanAmount && it.loanAmountLocked).map((it) => {
  //   console.log(it.borrowerLastName, it.closingLoanAmount, it.loanAmount);
  //   return it;
  // });
  // CLOSEDTP.filter((it) => it.loanAmount !== it.closingLoanAmount && it.loanAmountLocked).map((it) => {
  //   console.log(it.borrowerLastName, it.closingLoanAmount, it.loanAmount);
  //   return it;
  // });
  const AC065DRAMT = CLOSEDDIRECT.filter((it) => it.loanAmount !== it.closingLoanAmount)
    .map((it) => it.closingLoanAmount - it.loanAmount)
    .reduce((acc, nx) => acc + nx, 0);
  const AC065TPAMT = CLOSEDTP.filter((it) => it.loanAmount !== it.closingLoanAmount)
    .map((it) => it.closingLoanAmount - it.loanAmount)
    .reduce((acc, nx) => acc + nx, 0);

  // AC066
  const AC066DRAMT =
    AC010DRAMT + AC020DRAMT - AC030DRAMT - AC040DRAMT - AC050DRAMT - AC060DRAMT - AC062DRAMT - AC064DRAMT + AC065DRAMT;
  const AC066DRCT = AC010DRCT + AC020DRCT - AC030DRCT - AC040DRCT - AC050DRCT - AC060DRCT - AC062DRCT - AC064DRCT;
  const AC066DRAVG = AC066DRCT !== 0 ? AC066DRAMT / AC066DRCT : 0;
  const AC066TPAMT =
    AC010TPAMT + AC020TPAMT - AC030TPAMT - AC040TPAMT - AC050TPAMT - AC060TPAMT - AC062TPAMT - AC064TPAMT + AC065TPAMT;
  const AC066TPCT = AC010TPCT + AC020TPCT - AC030TPCT - AC040TPCT - AC050TPCT - AC060TPCT - AC062TPCT - AC064TPCT;
  const AC066TPAVG = AC066TPCT !== 0 ? AC066TPAMT / AC066TPCT : 0;

  // AC070
  const AC070DRAMT = CLOSEDDIRECT.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC070DRCT = CLOSEDDIRECT.length;
  const AC070DRAVG = AC070DRCT === 0 ? 0 : AC070DRAMT / AC070DRCT;
  const AC070TPAMT = CLOSEDTP.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC070TPCT = CLOSEDTP.length;
  const AC070TPAVG = AC070TPCT === 0 ? 0 : AC070TPAMT / AC070TPCT;

  // AC080
  const AC080GROUPED = groupBy('receivedFrom', inProcessAtEndOfQuarter);
  const AC080DIRECT = AC080GROUPED.Borrower || [];
  const AC080TP = AC080GROUPED.Third_Party || [];
  const AC080DRAMT = AC080DIRECT.reduce((acc, nx) => acc + nx.loanAmount, 0);
  const AC080DRCT = AC080DIRECT.length;
  const AC080DRAVG = AC080DRCT === 0 ? 0 : AC080DRAMT / AC080DRCT;
  const AC080TPAMT = AC080TP.reduce((acc, nx) => acc + nx.loanAmount, 0);
  const AC080TPCT = AC080TP.length;
  const AC080TPAVG = AC080TPCT === 0 ? 0 : AC080TPAMT / AC080TPCT;

  // AC090
  const AC090DRAMT = AC070DRAMT + AC080DRAMT;
  const AC090DRCT = AC070DRCT + AC080DRCT;
  const AC090DRAVG = AC090DRCT === 0 ? 0 : AC090DRAMT / AC090DRCT;
  const AC090TPAMT = AC070TPAMT + AC080TPAMT;
  const AC090TPCT = AC070TPCT + AC080TPCT;
  const AC090TPAVG = AC090TPCT === 0 ? 0 : AC090TPAMT / AC090TPCT;

  // AC100
  // Brokered
  const AC100BROKERED = forwardComplete.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      it.mortgageType === MortgageType.Conventional.value &&
      it.applicationType === ApplicationType.Brokered_Loan.value
  );
  const AC100BRAMT = AC100BROKERED.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC100BRCT = AC100BROKERED.length;
  const AC100BRAVG = AC100BRCT > 0 ? AC100BRAMT / AC100BRCT : 0;

  // Closed-Retail
  const AC100CR = forwardComplete.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      it.mortgageType === MortgageType.Conventional.value &&
      it.applicationType === ApplicationType.Closed_Retail_Application.value
  );
  const AC100CRAAMT = AC100CR.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC100CRACT = AC100CR.length;
  const AC100CRAAVG = AC100CRACT > 0 ? AC100CRAAMT / AC100CRACT : 0;

  // Closed-Wholesale
  const AC100CWA = forwardComplete.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      it.mortgageType === MortgageType.Conventional.value &&
      it.applicationType === ApplicationType.Closed_Wholesale_Application.value
  );
  const AC100CWAAMT = AC100CWA.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC100CWACT = AC100CWA.length;
  const AC100CWAAVG = AC100CWACT > 0 ? AC100CWAAMT / AC100CWACT : 0;

  // AC110
  // Brokered
  const AC110BROKERED = forwardComplete.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      it.mortgageType === MortgageType.FHA.value &&
      it.applicationType === ApplicationType.Brokered_Loan.value
  );
  const AC110BRAMT = AC110BROKERED.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC110BRCT = AC110BROKERED.length;
  const AC110BRAVG = AC110BRCT > 0 ? AC110BRAMT / AC110BRCT : 0;

  // Closed-Retail
  const AC110CR = forwardComplete.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      it.mortgageType === MortgageType.FHA.value &&
      it.applicationType === ApplicationType.Closed_Retail_Application.value
  );
  const AC110CRAAMT = AC110CR.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC110CRACT = AC110CR.length;
  const AC110CRAAVG = AC110CRACT > 0 ? AC110CRAAMT / AC110CRACT : 0;

  // Closed-Wholesale
  const AC110CWA = forwardComplete.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      it.mortgageType === MortgageType.FHA.value &&
      it.applicationType === ApplicationType.Closed_Wholesale_Application.value
  );
  const AC110CWAAMT = AC110CWA.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC110CWACT = AC110CWA.length;
  const AC110CWAAVG = AC110CWACT > 0 ? AC110CWAAMT / AC110CWACT : 0;

  // AC120
  // Brokered
  const AC120BROKERED = forwardComplete.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      it.mortgageType === MortgageType.VA.value &&
      it.applicationType === ApplicationType.Brokered_Loan.value
  );
  const AC120BRAMT = AC120BROKERED.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC120BRCT = AC120BROKERED.length;
  const AC120BRAVG = AC120BRCT > 0 ? AC120BRAMT / AC120BRCT : 0;

  // Closed-Retail
  const AC120CR = forwardComplete.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      it.mortgageType === MortgageType.VA.value &&
      it.applicationType === ApplicationType.Closed_Retail_Application.value
  );
  const AC120CRAAMT = AC120CR.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC120CRACT = AC120CR.length;
  const AC120CRAAVG = AC120CRACT > 0 ? AC120CRAAMT / AC120CRACT : 0;

  // Closed-Wholesale
  const AC120CWA = forwardComplete.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      it.mortgageType === MortgageType.VA.value &&
      it.applicationType === ApplicationType.Closed_Wholesale_Application.value
  );
  const AC120CWAAMT = AC120CWA.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC120CWACT = AC120CWA.length;
  const AC120CWAAVG = AC120CWACT > 0 ? AC120CWAAMT / AC120CWACT : 0;

  // AC130
  // Brokered
  const AC130BROKERED = forwardComplete.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      it.mortgageType === MortgageType.USDA_RHS.value &&
      it.applicationType === ApplicationType.Brokered_Loan.value
  );
  const AC130BRAMT = AC130BROKERED.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC130BRCT = AC130BROKERED.length;
  const AC130BRAVG = AC130BRCT > 0 ? AC130BRAMT / AC130BRCT : 0;

  // Closed-Retail
  const AC130CR = forwardComplete.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      it.mortgageType === MortgageType.USDA_RHS.value &&
      it.applicationType === ApplicationType.Closed_Retail_Application.value
  );
  const AC130CRAAMT = AC130CR.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC130CRACT = AC130CR.length;
  const AC130CRAAVG = AC130CRACT > 0 ? AC130CRAAMT / AC130CRACT : 0;

  // Closed-Wholesale
  const AC130CWA = forwardComplete.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      it.mortgageType === MortgageType.USDA_RHS.value &&
      it.applicationType === ApplicationType.Closed_Wholesale_Application.value
  );
  const AC130CWAAMT = AC130CWA.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC130CWACT = AC130CWA.length;
  const AC130CWAAVG = AC130CWACT > 0 ? AC130CWAAMT / AC130CWACT : 0;

  // AC190
  const AC190BRAMT = AC100BRAMT + AC110BRAMT + AC120BRAMT + AC130BRAMT;
  const AC190BRCT = AC100BRCT + AC110BRCT + AC120BRCT + AC130BRCT;
  const AC190BRAVG = AC190BRCT > 0 ? AC190BRAMT / AC190BRCT : 0;
  const AC190CRAAMT = AC100CRAAMT + AC110CRAAMT + AC120CRAAMT + AC130CRAAMT;
  const AC190CRACT = AC100CRACT + AC110CRACT + AC120CRACT + AC130CRACT;
  const AC190CRAAVG = AC190CRACT > 0 ? AC190CRAAMT / AC190CRACT : 0;
  const AC190CWAAMT = AC100CWAAMT + AC110CWAAMT + AC120CWAAMT + AC130CWAAMT;
  const AC190CWACT = AC100CWACT + AC110CWACT + AC120CWACT + AC130CWACT;
  const AC190CWAAVG = AC190CWACT > 0 ? AC190CWAAMT / AC190CWACT : 0;

  // AC200
  // Brokered
  const AC200BROKERED = forwardComplete.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      it.subjectPropType === PropertyType.One_To_Four_Family.value &&
      it.applicationType === ApplicationType.Brokered_Loan.value
  );
  const AC200BRAMT = AC200BROKERED.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC200BRCT = AC200BROKERED.length;
  const AC200BRAVG = AC200BRCT > 0 ? AC200BRAMT / AC200BRCT : 0;

  // Closed-Retail
  const AC200CR = forwardComplete.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      it.subjectPropType === PropertyType.One_To_Four_Family.value &&
      it.applicationType === ApplicationType.Closed_Retail_Application.value
  );
  const AC200CRAAMT = AC200CR.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC200CRACT = AC200CR.length;
  const AC200CRAAVG = AC200CRACT > 0 ? AC200CRAAMT / AC200CRACT : 0;

  // Closed-Wholesale
  const AC200CWA = forwardComplete.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      it.subjectPropType === PropertyType.One_To_Four_Family.value &&
      it.applicationType === ApplicationType.Closed_Wholesale_Application.value
  );
  const AC200CWAAMT = AC200CWA.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC200CWACT = AC200CWA.length;
  const AC200CWAAVG = AC200CWACT > 0 ? AC200CWAAMT / AC200CWACT : 0;

  // AC210
  // Brokered
  const AC210BROKERED = forwardComplete.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      it.subjectPropType === PropertyType.Manufactured_Housing.value &&
      it.applicationType === ApplicationType.Brokered_Loan.value
  );
  const AC210BRAMT = AC210BROKERED.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC210BRCT = AC210BROKERED.length;
  const AC210BRAVG = AC210BRCT > 0 ? AC210BRAMT / AC210BRCT : 0;

  // Closed-Retail
  const AC210CR = forwardComplete.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      it.subjectPropType === PropertyType.Manufactured_Housing.value &&
      it.applicationType === ApplicationType.Closed_Retail_Application.value
  );
  const AC210CRAAMT = AC210CR.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC210CRACT = AC210CR.length;
  const AC210CRAAVG = AC210CRACT > 0 ? AC210CRAAMT / AC210CRACT : 0;

  // Closed-Wholesale
  const AC210CWA = forwardComplete.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      it.subjectPropType === PropertyType.Manufactured_Housing.value &&
      it.applicationType === ApplicationType.Closed_Wholesale_Application.value
  );
  const AC210CWAAMT = AC210CWA.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC210CWACT = AC210CWA.length;
  const AC210CWAAVG = AC210CWACT > 0 ? AC210CWAAMT / AC210CWACT : 0;

  // AC220
  // Brokered
  const AC220BROKERED = forwardComplete.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      it.subjectPropType === PropertyType.Multifamily.value &&
      it.applicationType === ApplicationType.Brokered_Loan.value
  );
  const AC220BRAMT = AC220BROKERED.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC220BRCT = AC220BROKERED.length;
  const AC220BRAVG = AC220BRCT > 0 ? AC220BRAMT / AC220BRCT : 0;

  // Closed-Retail
  const AC220CR = forwardComplete.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      it.subjectPropType === PropertyType.Multifamily.value &&
      it.applicationType === ApplicationType.Closed_Retail_Application.value
  );
  const AC220CRAAMT = AC220CR.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC220CRACT = AC220CR.length;
  const AC220CRAAVG = AC220CRACT > 0 ? AC220CRAAMT / AC220CRACT : 0;

  // Closed-Wholesale
  const AC220CWA = forwardComplete.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      it.subjectPropType === PropertyType.Multifamily.value &&
      it.applicationType === ApplicationType.Closed_Wholesale_Application.value
  );
  const AC220CWAAMT = AC220CWA.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC220CWACT = AC220CWA.length;
  const AC220CWAAVG = AC220CWACT > 0 ? AC220CWAAMT / AC220CWACT : 0;

  // AC290
  const AC290BRAMT = AC200BRAMT + AC210BRAMT + AC220BRAMT;
  const AC290BRCT = AC200BRCT + AC210BRCT + AC220BRCT;
  const AC290BRAVG = AC290BRCT > 0 ? AC290BRAMT / AC290BRCT : 0;
  const AC290CRAAMT = AC200CRAAMT + AC210CRAAMT + AC220CRAAMT;
  const AC290CRACT = AC200CRACT + AC210CRACT + AC220CRACT;
  const AC290CRAAVG = AC290CRACT > 0 ? AC290CRAAMT / AC290CRACT : 0;
  const AC290CWAAMT = AC200CWAAMT + AC210CWAAMT + AC220CWAAMT;
  const AC290CWACT = AC200CWACT + AC210CWACT + AC220CWACT;
  const AC290CWAAVG = AC290CWACT > 0 ? AC290CWAAMT / AC290CWACT : 0;

  // AC300
  // Brokered
  const AC300BROKERED = forwardComplete.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      it.loanPurpose === LoanPurpose.Purchase.value &&
      it.applicationType === ApplicationType.Brokered_Loan.value
  );
  const AC300BRAMT = AC300BROKERED.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC300BRCT = AC300BROKERED.length;
  const AC300BRAVG = AC300BRCT > 0 ? AC300BRAMT / AC300BRCT : 0;

  // Closed-Retail
  const AC300CR = forwardComplete.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      it.loanPurpose === LoanPurpose.Purchase.value &&
      it.applicationType === ApplicationType.Closed_Retail_Application.value
  );
  const AC300CRAAMT = AC300CR.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC300CRACT = AC300CR.length;
  const AC300CRAAVG = AC300CRACT > 0 ? AC300CRAAMT / AC300CRACT : 0;

  // Closed-Wholesale
  const AC300CWA = forwardComplete.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      it.loanPurpose === LoanPurpose.Purchase.value &&
      it.applicationType === ApplicationType.Closed_Wholesale_Application.value
  );
  const AC300CWAAMT = AC300CWA.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC300CWACT = AC300CWA.length;
  const AC300CWAAVG = AC300CWACT > 0 ? AC300CWAAMT / AC300CWACT : 0;

  // AC310
  // Brokered
  const AC310BROKERED = forwardComplete.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      it.homeImprovement &&
      it.applicationType === ApplicationType.Brokered_Loan.value
  );
  const AC310BRAMT = AC310BROKERED.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC310BRCT = AC310BROKERED.length;
  const AC310BRAVG = AC310BRCT > 0 ? AC310BRAMT / AC310BRCT : 0;

  // Closed-Retail
  const AC310CR = forwardComplete.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      it.homeImprovement &&
      it.applicationType === ApplicationType.Closed_Retail_Application.value
  );
  const AC310CRAAMT = AC310CR.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC310CRACT = AC310CR.length;
  const AC310CRAAVG = AC310CRACT > 0 ? AC310CRAAMT / AC310CRACT : 0;

  // Closed-Wholesale
  const AC310CWA = forwardComplete.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      it.homeImprovement &&
      it.applicationType === ApplicationType.Closed_Wholesale_Application.value
  );
  const AC310CWAAMT = AC310CWA.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC310CWACT = AC310CWA.length;
  const AC310CWAAVG = AC310CWACT > 0 ? AC310CWAAMT / AC310CWACT : 0;

  // AC320
  // Brokered
  const AC320BROKERED = forwardComplete.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      (it.loanPurpose === LoanPurpose.Limited_Cash_Out_Refinance.value ||
        it.loanPurpose === LoanPurpose.Cash_Out_Refinance.value ||
        it.loanPurpose === LoanPurpose.No_Cash_Out_Refinance.value ||
        it.loanPurpose === LoanPurpose.Other.value) &&
      it.applicationType === ApplicationType.Brokered_Loan.value
  );
  const AC320BRAMT = AC320BROKERED.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC320BRCT = AC320BROKERED.length;
  const AC320BRAVG = AC320BRCT > 0 ? AC320BRAMT / AC320BRCT : 0;

  // Closed-Retail
  const AC320CR = forwardComplete.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      (it.loanPurpose === LoanPurpose.Limited_Cash_Out_Refinance.value ||
        it.loanPurpose === LoanPurpose.Cash_Out_Refinance.value ||
        it.loanPurpose === LoanPurpose.No_Cash_Out_Refinance.value ||
        it.loanPurpose === LoanPurpose.Other.value) &&
      it.applicationType === ApplicationType.Closed_Retail_Application.value
  );
  const AC320CRAAMT = AC320CR.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC320CRACT = AC320CR.length;
  const AC320CRAAVG = AC320CRACT > 0 ? AC320CRAAMT / AC320CRACT : 0;

  // Closed-Wholesale
  const AC320CWA = forwardComplete.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      (it.loanPurpose === LoanPurpose.Limited_Cash_Out_Refinance.value ||
        it.loanPurpose === LoanPurpose.Cash_Out_Refinance.value ||
        it.loanPurpose === LoanPurpose.No_Cash_Out_Refinance.value ||
        it.loanPurpose === LoanPurpose.Other.value) &&
      it.applicationType === ApplicationType.Closed_Wholesale_Application.value
  );
  const AC320CWAAMT = AC320CWA.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC320CWACT = AC320CWA.length;
  const AC320CWAAVG = AC320CWACT > 0 ? AC320CWAAMT / AC320CWACT : 0;

  // AC390
  const AC390BRAMT = AC300BRAMT + AC310BRAMT + AC320BRAMT;
  const AC390BRCT = AC300BRCT + AC310BRCT + AC320BRCT;
  const AC390BRAVG = AC390BRCT > 0 ? AC390BRAMT / AC390BRCT : 0;
  const AC390CRAAMT = AC300CRAAMT + AC310CRAAMT + AC320CRAAMT;
  const AC390CRACT = AC300CRACT + AC310CRACT + AC320CRACT;
  const AC390CRAAVG = AC390CRACT > 0 ? AC390CRAAMT / AC390CRACT : 0;
  const AC390CWAAMT = AC300CWAAMT + AC310CWAAMT + AC320CWAAMT;
  const AC390CWACT = AC300CWACT + AC310CWACT + AC320CWACT;
  const AC390CWAAVG = AC390CWACT > 0 ? AC390CWAAMT / AC390CWACT : 0;

  // AC400
  // Brokered
  const AC400BROKERED = forwardComplete.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      it.isHoepa &&
      it.applicationType === ApplicationType.Brokered_Loan.value
  );
  const AC400BRAMT = AC400BROKERED.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC400BRCT = AC400BROKERED.length;
  const AC400BRAVG = AC400BRCT > 0 ? AC400BRAMT / AC400BRCT : 0;

  // Closed-Retail
  const AC400CR = forwardComplete.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      it.isHoepa &&
      it.applicationType === ApplicationType.Closed_Retail_Application.value
  );
  const AC400CRAAMT = AC400CR.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC400CRACT = AC400CR.length;
  const AC400CRAAVG = AC400CRACT > 0 ? AC400CRAAMT / AC400CRACT : 0;

  // Closed-Wholesale
  const AC400CWA = forwardComplete.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      it.isHoepa &&
      it.applicationType === ApplicationType.Closed_Wholesale_Application.value
  );
  const AC400CWAAMT = AC400CWA.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC400CWACT = AC400CWA.length;
  const AC400CWAAVG = AC400CWACT > 0 ? AC400CWAAMT / AC400CWACT : 0;

  // AC500
  // Brokered
  const AC500BROKERED = forwardComplete.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      it.lienPriorityType === LienPriorityType.First_Lien.value &&
      it.applicationType === ApplicationType.Brokered_Loan.value
  );
  const AC500BRAMT = AC500BROKERED.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC500BRCT = AC500BROKERED.length;
  const AC500BRAVG = AC500BRCT > 0 ? AC500BRAMT / AC500BRCT : 0;

  // Closed-Retail
  const AC500CR = forwardComplete.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      it.lienPriorityType === LienPriorityType.First_Lien.value &&
      it.applicationType === ApplicationType.Closed_Retail_Application.value
  );
  const AC500CRAAMT = AC500CR.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC500CRACT = AC500CR.length;
  const AC500CRAAVG = AC500CRACT > 0 ? AC500CRAAMT / AC500CRACT : 0;

  // Closed-Wholesale
  const AC500CWA = forwardComplete.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      it.lienPriorityType === LienPriorityType.First_Lien.value &&
      it.applicationType === ApplicationType.Closed_Wholesale_Application.value
  );
  const AC500CWAAMT = AC500CWA.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC500CWACT = AC500CWA.length;
  const AC500CWAAVG = AC500CWACT > 0 ? AC500CWAAMT / AC500CWACT : 0;

  // AC510
  // Brokered
  const AC510BROKERED = forwardComplete.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      (it.lienPriorityType === LienPriorityType.Standalone.value ||
        it.lienPriorityType === LienPriorityType.HELOC.value ||
        it.lienPriorityType === LienPriorityType.Second_Lien.value ||
        it.lienPriorityType === LienPriorityType.Simultaneous_Second_Lien.value) &&
      it.applicationType === ApplicationType.Brokered_Loan.value
  );
  const AC510BRAMT = AC510BROKERED.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC510BRCT = AC510BROKERED.length;
  const AC510BRAVG = AC510BRCT > 0 ? AC510BRAMT / AC510BRCT : 0;

  // Closed-Retail
  const AC510CR = forwardComplete.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      (it.lienPriorityType === LienPriorityType.Standalone.value ||
        it.lienPriorityType === LienPriorityType.HELOC.value ||
        it.lienPriorityType === LienPriorityType.Second_Lien.value ||
        it.lienPriorityType === LienPriorityType.Simultaneous_Second_Lien.value) &&
      it.applicationType === ApplicationType.Closed_Retail_Application.value
  );
  const AC510CRAAMT = AC510CR.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC510CRACT = AC510CR.length;
  const AC510CRAAVG = AC510CRACT > 0 ? AC510CRAAMT / AC510CRACT : 0;

  // Closed-Wholesale
  const AC510CWA = forwardComplete.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      (it.lienPriorityType === LienPriorityType.Standalone.value ||
        it.lienPriorityType === LienPriorityType.HELOC.value ||
        it.lienPriorityType === LienPriorityType.Second_Lien.value ||
        it.lienPriorityType === LienPriorityType.Simultaneous_Second_Lien.value) &&
      it.applicationType === ApplicationType.Closed_Wholesale_Application.value
  );
  const AC510CWAAMT = AC510CWA.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC510CWACT = AC510CWA.length;
  const AC510CWAAVG = AC510CWACT > 0 ? AC510CWAAMT / AC510CWACT : 0;

  // AC520
  // Brokered
  const AC520BROKERED = forwardComplete.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      it.lienPriorityType === LienPriorityType.Not_Secured_By_A_Lien.value &&
      it.applicationType === ApplicationType.Brokered_Loan.value
  );
  const AC520BRAMT = AC520BROKERED.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC520BRCT = AC520BROKERED.length;
  const AC520BRAVG = AC520BRCT > 0 ? AC520BRAMT / AC520BRCT : 0;

  // Closed-Retail
  const AC520CR = forwardComplete.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      it.lienPriorityType === LienPriorityType.Not_Secured_By_A_Lien.value &&
      it.applicationType === ApplicationType.Closed_Retail_Application.value
  );
  const AC520CRAAMT = AC520CR.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC520CRACT = AC520CR.length;
  const AC520CRAAVG = AC520CRACT > 0 ? AC520CRAAMT / AC520CRACT : 0;

  // Closed-Wholesale
  const AC520CWA = forwardComplete.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      it.lienPriorityType === LienPriorityType.Not_Secured_By_A_Lien.value &&
      it.applicationType === ApplicationType.Closed_Wholesale_Application.value
  );
  const AC520CWAAMT = AC520CWA.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC520CWACT = AC520CWA.length;
  const AC520CWAAVG = AC520CWACT > 0 ? AC520CWAAMT / AC520CWACT : 0;

  // AC590
  const AC590BRAMT = AC500BRAMT + AC510BRAMT + AC520BRAMT;
  const AC590BRCT = AC500BRCT + AC510BRCT + AC520BRCT;
  const AC590BRAVG = AC590BRCT > 0 ? AC590BRAMT / AC590BRCT : 0;
  const AC590CRAAMT = AC500CRAAMT + AC510CRAAMT + AC520CRAAMT;
  const AC590CRACT = AC500CRACT + AC510CRACT + AC520CRACT;
  const AC590CRAAVG = AC590CRACT > 0 ? AC590CRAAMT / AC590CRACT : 0;
  const AC590CWAAMT = AC500CWAAMT + AC510CWAAMT + AC520CWAAMT;
  const AC590CWACT = AC500CWACT + AC510CWACT + AC520CWACT;
  const AC590CWAAVG = AC590CWACT > 0 ? AC590CWAAMT / AC590CWACT : 0;

  // AC600
  const AC600BRAMT = forwardComplete
    .filter(
      (it) =>
        it.loanStatus === LoanStatus.Closed_Funded.value && it.applicationType === ApplicationType.Brokered_Loan.value
    )
    .reduce((acc, nx) => acc + nx.brokerFeesCollected, 0);

  // AC610
  const AC610CRAAMT = forwardComplete
    .filter(
      (it) =>
        it.loanStatus === LoanStatus.Closed_Funded.value &&
        it.applicationType === ApplicationType.Closed_Retail_Application.value
    )
    .reduce((acc, nx) => acc + nx.brokerFeesCollected, 0);
  const AC610CWAAMT = forwardComplete
    .filter(
      (it) =>
        it.loanStatus === LoanStatus.Closed_Funded.value &&
        it.applicationType === ApplicationType.Closed_Wholesale_Application.value
    )
    .reduce((acc, nx) => acc + nx.brokerFeesCollected, 0);

  // AC700
  // Brokered
  const AC700BROKERED = reverseComplete.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      it.reverseMortgageType === ReverseMortgageType.HECM_Standard.value &&
      it.applicationType === ApplicationType.Brokered_Loan.value
  );
  const AC700BRAMT = AC700BROKERED.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC700BRCT = AC700BROKERED.length;
  const AC700BRAVG = AC700BRCT > 0 ? AC700BRAMT / AC700BRCT : 0;

  // Closed-Retail
  const AC700CR = reverseComplete.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      it.reverseMortgageType === ReverseMortgageType.HECM_Standard.value &&
      it.applicationType === ApplicationType.Closed_Retail_Application.value
  );
  const AC700CRAAMT = AC700CR.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC700CRACT = AC700CR.length;
  const AC700CRAAVG = AC700CRACT > 0 ? AC700CRAAMT / AC700CRACT : 0;

  // Closed-Wholesale
  const AC700CWA = reverseComplete.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      it.reverseMortgageType === ReverseMortgageType.HECM_Standard.value &&
      it.applicationType === ApplicationType.Closed_Wholesale_Application.value
  );
  const AC700CWAAMT = AC700CWA.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC700CWACT = AC700CWA.length;
  const AC700CWAAVG = AC700CWACT > 0 ? AC700CWAAMT / AC700CWACT : 0;

  // AC710
  // Brokered
  const AC710BROKERED = reverseComplete.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      it.reverseMortgageType === ReverseMortgageType.HECM_Saver.value &&
      it.applicationType === ApplicationType.Brokered_Loan.value
  );
  const AC710BRAMT = AC710BROKERED.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC710BRCT = AC710BROKERED.length;
  const AC710BRAVG = AC710BRCT > 0 ? AC710BRAMT / AC710BRCT : 0;

  // Closed-Retail
  const AC710CR = reverseComplete.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      it.reverseMortgageType === ReverseMortgageType.HECM_Saver.value &&
      it.applicationType === ApplicationType.Closed_Retail_Application.value
  );
  const AC710CRAAMT = AC710CR.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC710CRACT = AC710CR.length;
  const AC710CRAAVG = AC710CRACT > 0 ? AC710CRAAMT / AC710CRACT : 0;

  // Closed-Wholesale
  const AC710CWA = reverseComplete.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      it.reverseMortgageType === ReverseMortgageType.HECM_Saver.value &&
      it.applicationType === ApplicationType.Closed_Wholesale_Application.value
  );
  const AC710CWAAMT = AC710CWA.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC710CWACT = AC710CWA.length;
  const AC710CWAAVG = AC710CWACT > 0 ? AC710CWAAMT / AC710CWACT : 0;

  // AC720
  // Brokered
  const AC720BROKERED = reverseComplete.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      it.reverseMortgageType === ReverseMortgageType.Propriatary_Other.value &&
      it.applicationType === ApplicationType.Brokered_Loan.value
  );
  const AC720BRAMT = AC720BROKERED.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC720BRCT = AC720BROKERED.length;
  const AC720BRAVG = AC720BRCT > 0 ? AC720BRAMT / AC720BRCT : 0;

  // Closed-Retail
  const AC720CR = reverseComplete.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      it.reverseMortgageType === ReverseMortgageType.Propriatary_Other.value &&
      it.applicationType === ApplicationType.Closed_Retail_Application.value
  );
  const AC720CRAAMT = AC720CR.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC720CRACT = AC720CR.length;
  const AC720CRAAVG = AC720CRACT > 0 ? AC720CRAAMT / AC720CRACT : 0;

  // Closed-Wholesale
  const AC720CWA = reverseComplete.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      it.reverseMortgageType === ReverseMortgageType.Propriatary_Other.value &&
      it.applicationType === ApplicationType.Closed_Wholesale_Application.value
  );
  const AC720CWAAMT = AC720CWA.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC720CWACT = AC720CWA.length;
  const AC720CWAAVG = AC720CWACT > 0 ? AC720CWAAMT / AC720CWACT : 0;

  // AC790
  const AC790BRAMT = AC700BRAMT + AC710BRAMT + AC720BRAMT;
  const AC790BRCT = AC700BRCT + AC710BRCT + AC720BRCT;
  const AC790BRAVG = AC790BRCT > 0 ? AC790BRAMT / AC790BRCT : 0;
  const AC790CRAAMT = AC700CRAAMT + AC710CRAAMT + AC720CRAAMT;
  const AC790CRACT = AC700CRACT + AC710CRACT + AC720CRACT;
  const AC790CRAAVG = AC790CRACT > 0 ? AC790CRAAMT / AC790CRACT : 0;
  const AC790CWAAMT = AC700CWAAMT + AC710CWAAMT + AC720CWAAMT;
  const AC790CWACT = AC700CWACT + AC710CWACT + AC720CWACT;
  const AC790CWAAVG = AC790CWACT > 0 ? AC790CWAAMT / AC790CWACT : 0;

  // AC800
  // Brokered
  const AC800BROKERED = reverseComplete.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      it.reverseMortgagePurpose === ReverseMortgagePurpose.Home_Purchase.value &&
      it.applicationType === ApplicationType.Brokered_Loan.value
  );
  const AC800BRAMT = AC800BROKERED.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC800BRCT = AC800BROKERED.length;
  const AC800BRAVG = AC800BRCT > 0 ? AC800BRAMT / AC800BRCT : 0;

  // Closed-Retail
  const AC800CR = reverseComplete.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      it.reverseMortgagePurpose === ReverseMortgagePurpose.Home_Purchase.value &&
      it.applicationType === ApplicationType.Closed_Retail_Application.value
  );
  const AC800CRAAMT = AC800CR.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC800CRACT = AC800CR.length;
  const AC800CRAAVG = AC800CRACT > 0 ? AC800CRAAMT / AC800CRACT : 0;

  // Closed-Wholesale
  const AC800CWA = reverseComplete.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      it.reverseMortgagePurpose === ReverseMortgagePurpose.Home_Purchase.value &&
      it.applicationType === ApplicationType.Closed_Wholesale_Application.value
  );
  const AC800CWAAMT = AC800CWA.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC800CWACT = AC800CWA.length;
  const AC800CWAAVG = AC800CWACT > 0 ? AC800CWAAMT / AC800CWACT : 0;

  // AC810
  // Brokered
  const AC810BROKERED = reverseComplete.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      it.reverseMortgagePurpose === ReverseMortgagePurpose.Other.value &&
      it.applicationType === ApplicationType.Brokered_Loan.value
  );
  const AC810BRAMT = AC810BROKERED.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC810BRCT = AC810BROKERED.length;
  const AC810BRAVG = AC810BRCT > 0 ? AC810BRAMT / AC810BRCT : 0;

  // Closed-Retail
  const AC810CR = reverseComplete.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      it.reverseMortgagePurpose === ReverseMortgagePurpose.Other.value &&
      it.applicationType === ApplicationType.Closed_Retail_Application.value
  );
  const AC810CRAAMT = AC810CR.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC810CRACT = AC810CR.length;
  const AC810CRAAVG = AC810CRACT > 0 ? AC810CRAAMT / AC810CRACT : 0;

  // Closed-Wholesale
  const AC810CWA = reverseComplete.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      it.reverseMortgagePurpose === ReverseMortgagePurpose.Other.value &&
      it.applicationType === ApplicationType.Closed_Wholesale_Application.value
  );
  const AC810CWAAMT = AC810CWA.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC810CWACT = AC810CWA.length;
  const AC810CWAAVG = AC810CWACT > 0 ? AC810CWAAMT / AC810CWACT : 0;

  // AC890
  const AC890BRAMT = AC800BRAMT + AC810BRAMT;
  const AC890BRCT = AC800BRCT + AC810BRCT;
  const AC890BRAVG = AC890BRCT > 0 ? AC890BRAMT / AC890BRCT : 0;
  const AC890CRAAMT = AC800CRAAMT + AC810CRAAMT;
  const AC890CRACT = AC800CRACT + AC810CRACT;
  const AC890CRAAVG = AC890CRACT > 0 ? AC890CRAAMT / AC890CRACT : 0;
  const AC890CWAAMT = AC800CWAAMT + AC810CWAAMT;
  const AC890CWACT = AC800CWACT + AC810CWACT;
  const AC890CWAAVG = AC890CWACT > 0 ? AC890CWAAMT / AC890CWACT : 0;

  // AC620
  const AC620BRAMT = reverseComplete
    .filter(
      (it) =>
        it.loanStatus === LoanStatus.Closed_Funded.value && it.applicationType === ApplicationType.Brokered_Loan.value
    )
    .reduce((acc, nx) => acc + nx.brokerFeesCollected, 0);

  // AC630
  const AC630CRAAMT = reverseComplete
    .filter(
      (it) =>
        it.loanStatus === LoanStatus.Closed_Funded.value &&
        it.applicationType === ApplicationType.Closed_Retail_Application.value
    )
    .reduce((acc, nx) => acc + nx.brokerFeesCollected, 0);
  const AC630CWAAMT = reverseComplete
    .filter(
      (it) =>
        it.loanStatus === LoanStatus.Closed_Funded.value &&
        it.applicationType === ApplicationType.Closed_Wholesale_Application.value
    )
    .reduce((acc, nx) => acc + nx.brokerFeesCollected, 0);

  // AC900
  const AC900BRACT = completedWithinQuarter.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value && it.applicationType === ApplicationType.Brokered_Loan.value
  ).length;

  // AC910
  const AC910CRACT = completedWithinQuarter.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      it.applicationType === ApplicationType.Closed_Retail_Application.value
  ).length;
  const AC910CWACT = completedWithinQuarter.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      it.applicationType === ApplicationType.Closed_Wholesale_Application.value
  ).length;

  // AC920
  // Brokered
  const AC920BROKERED = completedWithinQuarter.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      it.loanType === LoanType.QM.value &&
      it.applicationType === ApplicationType.Brokered_Loan.value
  );
  const AC920BRAMT = AC920BROKERED.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC920BRCT = AC920BROKERED.length;
  const AC920BRAVG = AC920BRCT > 0 ? AC920BRAMT / AC920BRCT : 0;

  // Closed-Retail
  const AC920CR = completedWithinQuarter.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      it.loanType === LoanType.QM.value &&
      it.applicationType === ApplicationType.Closed_Retail_Application.value
  );
  const AC920CRAAMT = AC920CR.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC920CRACT = AC920CR.length;
  const AC920CRAAVG = AC920CRACT > 0 ? AC920CRAAMT / AC920CRACT : 0;

  // Closed-Wholesale
  const AC920CWA = completedWithinQuarter.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      it.loanType === LoanType.QM.value &&
      it.applicationType === ApplicationType.Closed_Wholesale_Application.value
  );
  const AC920CWAAMT = AC920CWA.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC920CWACT = AC920CWA.length;
  const AC920CWAAVG = AC920CWACT > 0 ? AC920CWAAMT / AC920CWACT : 0;

  // AC930
  // Brokered
  const AC930BROKERED = completedWithinQuarter.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      it.loanType === LoanType.Non_QM.value &&
      it.applicationType === ApplicationType.Brokered_Loan.value
  );
  const AC930BRAMT = AC930BROKERED.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC930BRCT = AC930BROKERED.length;
  const AC930BRAVG = AC930BRCT > 0 ? AC930BRAMT / AC930BRCT : 0;

  // Closed-Retail
  const AC930CR = completedWithinQuarter.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      it.loanType === LoanType.Non_QM.value &&
      it.applicationType === ApplicationType.Closed_Retail_Application.value
  );
  const AC930CRAAMT = AC930CR.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC930CRACT = AC930CR.length;
  const AC930CRAAVG = AC930CRACT > 0 ? AC930CRAAMT / AC930CRACT : 0;

  // Closed-Wholesale
  const AC930CWA = completedWithinQuarter.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      it.loanType === LoanType.Non_QM.value &&
      it.applicationType === ApplicationType.Closed_Wholesale_Application.value
  );
  const AC930CWAAMT = AC930CWA.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC930CWACT = AC930CWA.length;
  const AC930CWAAVG = AC930CWACT > 0 ? AC930CWAAMT / AC930CWACT : 0;

  // AC940
  // Brokered
  const AC940BROKERED = completedWithinQuarter.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      it.loanType === LoanType.Not_Subject_To_QM.value &&
      it.applicationType === ApplicationType.Brokered_Loan.value
  );
  const AC940BRAMT = AC940BROKERED.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC940BRCT = AC940BROKERED.length;
  const AC940BRAVG = AC940BRCT > 0 ? AC940BRAMT / AC940BRCT : 0;

  // Closed-Retail
  const AC940CR = completedWithinQuarter.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      it.loanType === LoanType.Not_Subject_To_QM.value &&
      it.applicationType === ApplicationType.Closed_Retail_Application.value
  );
  const AC940CRAAMT = AC940CR.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC940CRACT = AC940CR.length;
  const AC940CRAAVG = AC940CRACT > 0 ? AC940CRAAMT / AC940CRACT : 0;

  // Closed-Wholesale
  const AC940CWA = completedWithinQuarter.filter(
    (it) =>
      it.loanStatus === LoanStatus.Closed_Funded.value &&
      it.loanType === LoanType.Not_Subject_To_QM.value &&
      it.applicationType === ApplicationType.Closed_Wholesale_Application.value
  );
  const AC940CWAAMT = AC940CWA.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC940CWACT = AC940CWA.length;
  const AC940CWAAVG = AC940CWACT > 0 ? AC940CWAAMT / AC940CWACT : 0;

  // AC990
  const AC990BRAMT = AC920BRAMT + AC930BRAMT + AC940BRAMT;
  const AC990BRCT = AC920BRCT + AC930BRCT + AC940BRCT;
  const AC990BRAVG = AC990BRCT > 0 ? AC990BRAMT / AC990BRCT : 0;
  const AC990CRAAMT = AC920CRAAMT + AC930CRAAMT + AC940CRAAMT;
  const AC990CRACT = AC920CRACT + AC930CRACT + AC940CRACT;
  const AC990CRAAVG = AC990CRACT > 0 ? AC990CRAAMT / AC990CRACT : 0;
  const AC990CWAAMT = AC920CWAAMT + AC930CWAAMT + AC940CWAAMT;
  const AC990CWACT = AC920CWACT + AC930CWACT + AC940CWACT;
  const AC990CWAAVG = AC990CWACT > 0 ? AC990CWAAMT / AC990CWACT : 0;

  // AC1000
  const AC1000 = completedWithinQuarter.filter((it) => it.isRepurchase);
  const AC1000AMT = AC1000.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC1000CT = AC1000.length;
  const AC1000AVG = AC1000CT > 0 ? AC1000AMT / AC1000CT : 0;

  // AC1100
  const AC1100AMT = AC600BRAMT + AC610CRAAMT + AC610CWAAMT + AC620BRAMT + AC630CRAAMT + AC630CWAAMT;

  // AC1200
  const AC1200 = completedWithinQuarter.filter(
    (it) => it.serviceLoanType === ServiceLoanType.Retained.value && it.loanStatus === LoanStatus.Closed_Funded.value
  );
  const AC1200AMT = state === 'CT' ? 0 : AC1200.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC1200CT = state === 'CT' ? 0 : AC1200.length;
  const AC1200AVG = AC1200CT > 0 ? AC1200AMT / AC1200CT : 0;

  // AC1210
  const AC1210 = completedWithinQuarter.filter(
    (it) => it.serviceLoanType === ServiceLoanType.Released.value && it.loanStatus === LoanStatus.Closed_Funded.value
  );
  const AC1210AMT = state === 'CT' ? 0 : AC1210.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
  const AC1210CT = state === 'CT' ? 0 : AC1210.length;
  const AC1210AVG = AC1210CT > 0 ? AC1210AMT / AC1210CT : 0;

  // AC1290
  const AC1290AMT = AC1200AMT + AC1210AMT;
  const AC1290CT = AC1200CT + AC1210CT;
  const AC1290AVG = AC1290CT > 0 ? AC1290AMT / AC1290CT : 0;

  const employees = loanOriginators
    .filter((it) => it.states.includes(state))
    .map((emp, idx) => {
      const files = completedWithinQuarter.filter(
        (fi) => fi.loanOriginatorName === emp.value && fi.loanStatus === LoanStatus.Closed_Funded.value
      );
      const amount = files.reduce((acc, nx) => acc + nx.closingLoanAmount, 0);
      const count = files.length;
      const average = count > 0 ? amount / count : 0;
      return {
        id: `ACMLO${idx + 1}`,
        fullName: emp.value,
        license: emp.license,
        files,
        count,
        amount,
        amountFmt: numberFormatter.format(amount),
        average,
        averageFmt: numberFormatter.format(average),
      };
    });
  const totalAmount = employees.reduce((acc, nx) => acc + nx.amount, 0);
  const totalCount = employees.reduce((acc, nx) => acc + nx.count, 0);
  const totalAverageSize = employees.reduce((acc, nx) => acc + nx.average, 0);

  const reportFiles = getFilesByStatus(allFiles);

  const result = {
    allFiles,
    quarter,
    year,
    state,
    employees,
    totalEmployees: employees.length,
    totalAmount: numberFormatter.format(totalAmount),
    totalCount,
    totalAverageSize: numberFormatter.format(totalAverageSize),
    inProcess: AC080DRCT + AC080TPCT,
    closedAndFunded: AC070DRCT + AC070TPCT,
    explanatoryNotes,
    reportFiles,
    AC010DRAMT,
    AC010DRCT,
    AC010DRAVG,
    AC010TPAMT,
    AC010TPCT,
    AC010TPAVG,
    AC020DRAMT,
    AC020DRCT,
    AC020DRAVG,
    AC020TPAMT,
    AC020TPCT,
    AC020TPAVG,
    AC030DRAMT,
    AC030DRCT,
    AC030DRAVG,
    AC030TPAMT,
    AC030TPCT,
    AC030TPAVG,
    AC040DRAMT,
    AC040DRCT,
    AC040DRAVG,
    AC040TPAMT,
    AC040TPCT,
    AC040TPAVG,
    AC050DRAMT,
    AC050DRCT,
    AC050DRAVG,
    AC050TPAMT,
    AC050TPCT,
    AC050TPAVG,
    AC060DRAMT,
    AC060DRCT,
    AC060DRAVG,
    AC060TPAMT,
    AC060TPCT,
    AC060TPAVG,
    AC062DRAMT,
    AC062DRCT,
    AC062DRAVG,
    AC062TPAMT,
    AC062TPCT,
    AC062TPAVG,
    AC064DRAMT,
    AC064DRCT,
    AC064DRAVG,
    AC064TPAMT,
    AC064TPCT,
    AC064TPAVG,
    AC065DRAMT,
    AC065TPAMT,
    AC066DRAMT,
    AC066DRCT,
    AC066DRAVG,
    AC066TPAMT,
    AC066TPCT,
    AC066TPAVG,
    AC070DRAMT,
    AC070DRCT,
    AC070DRAVG,
    AC070TPAMT,
    AC070TPCT,
    AC070TPAVG,
    AC080DRAMT,
    AC080DRCT,
    AC080DRAVG,
    AC080TPAMT,
    AC080TPCT,
    AC080TPAVG,
    AC090DRAMT,
    AC090DRCT,
    AC090DRAVG,
    AC090TPAMT,
    AC090TPCT,
    AC090TPAVG,
    AC100BRAMT,
    AC100BRCT,
    AC100BRAVG,
    AC100CRAAMT,
    AC100CRACT,
    AC100CRAAVG,
    AC100CWAAMT,
    AC100CWACT,
    AC100CWAAVG,
    AC110BRAMT,
    AC110BRCT,
    AC110BRAVG,
    AC110CRAAMT,
    AC110CRACT,
    AC110CRAAVG,
    AC110CWAAMT,
    AC110CWACT,
    AC110CWAAVG,
    AC120BRAMT,
    AC120BRCT,
    AC120BRAVG,
    AC120CRAAMT,
    AC120CRACT,
    AC120CRAAVG,
    AC120CWAAMT,
    AC120CWACT,
    AC120CWAAVG,
    AC130BRAMT,
    AC130BRCT,
    AC130BRAVG,
    AC130CRAAMT,
    AC130CRACT,
    AC130CRAAVG,
    AC130CWAAMT,
    AC130CWACT,
    AC130CWAAVG,
    AC190BRAMT,
    AC190BRCT,
    AC190BRAVG,
    AC190CRAAMT,
    AC190CRACT,
    AC190CRAAVG,
    AC190CWAAMT,
    AC190CWACT,
    AC190CWAAVG,
    AC200BRAMT,
    AC200BRCT,
    AC200BRAVG,
    AC200CRAAMT,
    AC200CRACT,
    AC200CRAAVG,
    AC200CWAAMT,
    AC200CWACT,
    AC200CWAAVG,
    AC210BRAMT,
    AC210BRCT,
    AC210BRAVG,
    AC210CRAAMT,
    AC210CRACT,
    AC210CRAAVG,
    AC210CWAAMT,
    AC210CWACT,
    AC210CWAAVG,
    AC220BRAMT,
    AC220BRCT,
    AC220BRAVG,
    AC220CRAAMT,
    AC220CRACT,
    AC220CRAAVG,
    AC220CWAAMT,
    AC220CWACT,
    AC220CWAAVG,
    AC290BRAMT,
    AC290BRCT,
    AC290BRAVG,
    AC290CRAAMT,
    AC290CRACT,
    AC290CRAAVG,
    AC290CWAAMT,
    AC290CWACT,
    AC290CWAAVG,
    AC300BRAMT,
    AC300BRCT,
    AC300BRAVG,
    AC300CRAAMT,
    AC300CRACT,
    AC300CRAAVG,
    AC300CWAAMT,
    AC300CWACT,
    AC300CWAAVG,
    AC310BRAMT,
    AC310BRCT,
    AC310BRAVG,
    AC310CRAAMT,
    AC310CRACT,
    AC310CRAAVG,
    AC310CWAAMT,
    AC310CWACT,
    AC310CWAAVG,
    AC320BRAMT,
    AC320BRCT,
    AC320BRAVG,
    AC320CRAAMT,
    AC320CRACT,
    AC320CRAAVG,
    AC320CWAAMT,
    AC320CWACT,
    AC320CWAAVG,
    AC390BRAMT,
    AC390BRCT,
    AC390BRAVG,
    AC390CRAAMT,
    AC390CRACT,
    AC390CRAAVG,
    AC390CWAAMT,
    AC390CWACT,
    AC390CWAAVG,
    AC400BRAMT,
    AC400BRCT,
    AC400BRAVG,
    AC400CRAAMT,
    AC400CRACT,
    AC400CRAAVG,
    AC400CWAAMT,
    AC400CWACT,
    AC400CWAAVG,
    AC500BRAMT,
    AC500BRCT,
    AC500BRAVG,
    AC500CRAAMT,
    AC500CRACT,
    AC500CRAAVG,
    AC500CWAAMT,
    AC500CWACT,
    AC500CWAAVG,
    AC510BRAMT,
    AC510BRCT,
    AC510BRAVG,
    AC510CRAAMT,
    AC510CRACT,
    AC510CRAAVG,
    AC510CWAAMT,
    AC510CWACT,
    AC510CWAAVG,
    AC520BRAMT,
    AC520BRCT,
    AC520BRAVG,
    AC520CRAAMT,
    AC520CRACT,
    AC520CRAAVG,
    AC520CWAAMT,
    AC520CWACT,
    AC520CWAAVG,
    AC590BRAMT,
    AC590BRCT,
    AC590BRAVG,
    AC590CRAAMT,
    AC590CRACT,
    AC590CRAAVG,
    AC590CWAAMT,
    AC590CWACT,
    AC590CWAAVG,
    AC600BRAMT,
    AC610CRAAMT,
    AC610CWAAMT,
    AC700BRAMT,
    AC700BRCT,
    AC700BRAVG,
    AC700CRAAMT,
    AC700CRACT,
    AC700CRAAVG,
    AC700CWAAMT,
    AC700CWACT,
    AC700CWAAVG,
    AC710BRAMT,
    AC710BRCT,
    AC710BRAVG,
    AC710CRAAMT,
    AC710CRACT,
    AC710CRAAVG,
    AC710CWAAMT,
    AC710CWACT,
    AC710CWAAVG,
    AC720BRAMT,
    AC720BRCT,
    AC720BRAVG,
    AC720CRAAMT,
    AC720CRACT,
    AC720CRAAVG,
    AC720CWAAMT,
    AC720CWACT,
    AC720CWAAVG,
    AC790BRAMT,
    AC790BRCT,
    AC790BRAVG,
    AC790CRAAMT,
    AC790CRACT,
    AC790CRAAVG,
    AC790CWAAMT,
    AC790CWACT,
    AC790CWAAVG,
    AC800BRAMT,
    AC800BRCT,
    AC800BRAVG,
    AC800CRAAMT,
    AC800CRACT,
    AC800CRAAVG,
    AC800CWAAMT,
    AC800CWACT,
    AC800CWAAVG,
    AC810BRAMT,
    AC810BRCT,
    AC810BRAVG,
    AC810CRAAMT,
    AC810CRACT,
    AC810CRAAVG,
    AC810CWAAMT,
    AC810CWACT,
    AC810CWAAVG,
    AC890BRAMT,
    AC890BRCT,
    AC890BRAVG,
    AC890CRAAMT,
    AC890CRACT,
    AC890CRAAVG,
    AC890CWAAMT,
    AC890CWACT,
    AC890CWAAVG,
    AC620BRAMT,
    AC630CRAAMT,
    AC630CWAAMT,
    AC900BRACT,
    AC910CRACT,
    AC910CWACT,
    AC920BRAMT,
    AC920BRCT,
    AC920BRAVG,
    AC920CRAAMT,
    AC920CRACT,
    AC920CRAAVG,
    AC920CWAAMT,
    AC920CWACT,
    AC920CWAAVG,
    AC930BRAMT,
    AC930BRCT,
    AC930BRAVG,
    AC930CRAAMT,
    AC930CRACT,
    AC930CRAAVG,
    AC930CWAAMT,
    AC930CWACT,
    AC930CWAAVG,
    AC940BRAMT,
    AC940BRCT,
    AC940BRAVG,
    AC940CRAAMT,
    AC940CRACT,
    AC940CRAAVG,
    AC940CWAAMT,
    AC940CWACT,
    AC940CWAAVG,
    AC990BRAMT,
    AC990BRCT,
    AC990BRAVG,
    AC990CRAAMT,
    AC990CRACT,
    AC990CRAAVG,
    AC990CWAAMT,
    AC990CWACT,
    AC990CWAAVG,
    AC1000AMT,
    AC1000CT,
    AC1000AVG,
    AC1100AMT,
    AC1200AMT,
    AC1200CT,
    AC1200AVG,
    AC1210AMT,
    AC1210CT,
    AC1210AVG,
    AC1290AMT,
    AC1290CT,
    AC1290AVG,
  };

  const formattedResult = Object.keys(result)
    .map((it) => {
      const value = result[it];
      const shouldFormatCurrency = it.includes('AVG') || it.includes('AMT');
      return {
        [it]: shouldFormatCurrency ? numberFormatter.format(value) : value,
      };
    })
    .reduce((acc, nx) => ({ ...acc, ...nx }), {});

  return {
    formatted: formattedResult,
    raw: result,
  };
};
