import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useQueryClient, useMutation } from 'react-query';
import { API, graphqlOperation } from 'aws-amplify';
import { useAuthContext } from './AuthContext';
// import { useAlertContext } from './AlertContext';
import { cacheKeys } from '../conf';
import { updateFile, createFileStatusLog } from '../graphql/mutations';
import { convertJSDateTimeForServer } from '../utils/dateUtils';
import { normalizeData } from '../utils/formUtils';
import { StringBoolean } from '../constants/enums';
import { boolToStringBoolean } from '../utils/enumUtils';

const FileMutateContext = React.createContext({
  isLoading: false,
  isSuccess: false,
  isError: false,
  data: null,
  error: null,
  updateFile: () => {},
  deleteFile: () => {},
  createRMFile: () => {},
});

export const FileMutateProvider = ({ children }) => {
  const navigate = useNavigate();
  const { tenantId, isAdmin } = useAuthContext();
  const queryClient = useQueryClient();
  // const { openAlert } = useAlertContext();

  const { isLoading, isSuccess, data, isError, error, mutateAsync, reset } = useMutation(
    (input) =>
      API.graphql(
        graphqlOperation(updateFile, {
          input: {
            ...input,
          },
        })
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([cacheKeys.getFiles, tenantId]);
      },
    }
  );

  const doStatusLogCreate = useCallback(
    async (fileId, loanStatus, state) => {
      const dateTime = convertJSDateTimeForServer(new Date());
      const log = {
        tenantId,
        fileId,
        dateTime,
        loanStatus,
        state,
        isDeleted: boolToStringBoolean(false),
      };
      try {
        const res = await API.graphql(
          graphqlOperation(createFileStatusLog, {
            input: {
              ...log,
            },
          })
        );
        return res;
      } catch (e) {
        // console.error(e);
        return null;
      }
    },
    [tenantId]
  );

  const doFileUpdate = useCallback(
    async (d, shouldLogStatusChange, isReverseMortgage) => {
      const normalizedData = normalizeData(d, isReverseMortgage, isAdmin);
      if (shouldLogStatusChange) {
        await doStatusLogCreate(d.id, normalizedData.loanStatus, normalizedData.subjectPropState);
      }
      await mutateAsync(normalizedData);
      navigate(`/file/${normalizedData.id}?message=File updated successfully&result=success`);
    },
    [doStatusLogCreate, isAdmin, mutateAsync, navigate]
  );

  /**
   * We have to update all parts of the composite index key
   */
  const doFileDelete = useCallback(
    async (d) => {
      const { id, borrowerFirstName, borrowerLastName, applicationDate, interestRate, loanOriginatorName } = d;
      reset();
      await mutateAsync({
        id,
        borrowerFirstName,
        borrowerLastName,
        applicationDate,
        interestRate,
        loanOriginatorName,
        isDeleted: StringBoolean.True.value,
      });
      navigate(`/?message=File deleted successfully&result=success`);
    },
    [mutateAsync, navigate, reset]
  );

  const exportValue = useMemo(
    () => ({
      isLoading,
      isSuccess,
      isError,
      data,
      error,
      updateFile: doFileUpdate,
      deleteFile: doFileDelete,
    }),
    [data, doFileDelete, doFileUpdate, error, isError, isLoading, isSuccess]
  );

  return <FileMutateContext.Provider value={exportValue}>{children}</FileMutateContext.Provider>;
};

FileMutateProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useFileMutateContext = () => React.useContext(FileMutateContext);
