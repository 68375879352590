import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import { searchFiles } from '../graphql/queries';
import FormSearch from '../forms/FormSearch';
import TableFiles from '../tables/TableFiles';
import TableInProcess from '../tables/TableInProcess';
import Main from '../components/Main';
import { useAlertContext } from '../context/AlertContext';
import { useAuthContext } from '../context/AuthContext';
import { useFileContext } from '../context/FileContext';
import { boolToStringBoolean } from '../utils/enumUtils';
import { capitalizeString } from '../utils/index';
import { ModelSortDirection } from '../constants/enums';

const doSearch = async (
  {
    tenantId,
    firstName,
    lastName,
    loanOfficer,
    lender,
    interestRate,
    loanType,
    appStartDate,
    appEndDate,
    closeStartDate,
    closeEndDate,
  },
  setResults,
  setIsSearching,
  setIsSuccess,
  openAlert
) => {
  try {
    setResults([]);
    setIsSearching(true);
    const res = await API.graphql(
      graphqlOperation(searchFiles, {
        tenantId,
        isDeleted: { eq: boolToStringBoolean(false) },
        filter: {
          ...(firstName ? { borrowerFirstName: { eq: capitalizeString(firstName) } } : {}),
          ...(lastName ? { borrowerLastName: { eq: capitalizeString(lastName) } } : {}),
          ...(loanOfficer ? { loanOriginatorName: { eq: loanOfficer } } : {}),
          ...(interestRate ? { interestRate: { eq: interestRate } } : {}),
          ...(appStartDate && appEndDate ? { applicationDate: { between: [appStartDate, appEndDate] } } : {}),
          ...(lender ? { lender: { eq: lender } } : {}),
          ...(loanType ? { lender: { eq: lender } } : {}),
          ...(closeStartDate && closeEndDate ? { closingDate: { between: [closeStartDate, closeEndDate] } } : {}),
        },
        limit: 100000,
        sortDirection: ModelSortDirection.DESC,
      })
    );
    setResults(res.data.searchFiles.items);
    setIsSearching(false);
    setIsSuccess(true);
  } catch (e) {
    openAlert('error', `Search error: ${e}`);
  }
};

const Files = () => {
  const { isLoading, getInProcessFiles } = useFileContext();
  const location = useLocation();
  const [loadMessageDisplayed, setLoadMessageDisplayed] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [results, setResults] = useState([]);
  const { tenantId } = useAuthContext();
  const { openAlert } = useAlertContext();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams && !loadMessageDisplayed) {
      const message = searchParams.get('message');
      const result = searchParams.get('result');
      if (message && result) {
        setLoadMessageDisplayed(true);
        openAlert(result, message);
      }
    }
  }, [openAlert, location, loadMessageDisplayed]);

  return (
    <Main pagetitle="Files">
      <FormSearch
        doSearch={(params) => doSearch({ ...params, tenantId }, setResults, setIsSearching, setIsSuccess, openAlert)}
        isLoading={isSearching}
        setResults={setResults}
        setIsSuccess={setIsSuccess}
      />
      {(isSearching || isSuccess) && <TableFiles isLoading={isSearching} data={results} />}
      <TableInProcess isLoading={isLoading} data={getInProcessFiles} />
    </Main>
  );
};

export default Files;
