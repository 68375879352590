import React, { createContext, useContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

// the object is mostly unnecessary and really just to make it clear what this context provides by way of props and functions
const AlertContext = createContext({
  isOpen: false,
  toggle: () => {},
});

export const AlertProvider = ({ children }) => {
  const [alertState, setAlertState] = useState({
    isOpen: false,
    severity: 'info',
    text: '',
  }); // you can set this to true to start with it open

  const openAlert = (severity, text) => {
    setAlertState({
      isOpen: true,
      severity,
      text,
    });
  };

  const closeAlert = (severity, text) => {
    setAlertState({
      isOpen: false,
      severity,
      text,
    });
  };

  const value = useMemo(
    () => ({
      alertState,
      openAlert,
      closeAlert,
    }),
    [alertState]
  );

  return <AlertContext.Provider value={value}>{children}</AlertContext.Provider>;
};

AlertProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAlertContext = () => useContext(AlertContext);
