import React, { useState, useRef, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { DateRangePicker, LocalizationProvider } from '@mui/lab';
import { Autocomplete, Box, Button, FormGroup, Paper, TextField, Typography } from '@mui/material';
import { useAlertContext } from '../context/AlertContext';
import { lenderOptions, loanTypeOptions } from '../selectOptions';
import loanOfficerOptions from '../loanOriginators';

const Files = (props) => {
  const { doSearch, isLoading, setIsSuccess, setResults } = props;

  const { openAlert } = useAlertContext();

  // Set application date value
  const [applicationDate, setApplicationDateValue] = useState([null, null]);

  // Set application date value
  const [closingDate, setClosingDateValue] = useState([null, null]);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [loanOfficer, setLoanOfficer] = useState(null);
  const [lender, setLender] = useState(null);
  const [interestRate, setInterestRate] = useState('');
  const [loanType, setLoanType] = useState(null);

  const clearForm = useCallback(() => {
    setApplicationDateValue([null, null]);
    setClosingDateValue([null, null]);
    setFirstName('');
    setLastName('');
    setInterestRate('');
    setLoanType(null);
    setLoanOfficer(null);
    setLender(null);
    setIsSuccess(false);
    setResults([]);
  }, [setIsSuccess, setResults]);

  const theForm = useRef();

  const isFormValid = useMemo(() => {
    const isValid = !!firstName || !!lastName || !!loanOfficer || !!lender || !!interestRate || !!loanType;
    if (!isValid) {
      const hasAppDate = !!applicationDate.find((it) => !!it);
      const hasClosingDate = !!closingDate.find((it) => !!it);
      return hasAppDate || hasClosingDate;
    }
    return isValid;
  }, [firstName, lastName, applicationDate, closingDate, interestRate, lender, loanOfficer, loanType]);

  return (
    <Paper
      component="form"
      autoComplete="off"
      sx={{
        padding: '20px',

        // FormGroup
        '.MuiFormGroup-root': {
          flexDirection: 'row',
        },

        // Textfield
        '.MuiTextField-root': {
          margin: '20px 20px 0 0',
        },

        // Autocomplete
        '.MuiAutocomplete-root': {
          margin: '20px 20px 0 0',
          '.MuiFormControl-root': {
            margin: 0,
          },
        },
      }}
      onSubmit={(e) => {
        e.preventDefault();
        if (isFormValid) {
          const [appStartDate, appEndDate] = applicationDate;
          const [closeStartDate, closeEndDate] = closingDate;
          const lO = loanOfficer ? loanOfficer.value : null;
          const le = lender ? lender.value : null;
          const lT = loanType ? loanType.value : null;
          doSearch({
            firstName,
            lastName,
            loanOfficer: lO,
            lender: le,
            interestRate,
            loanType: lT,
            appStartDate,
            appEndDate,
            closeStartDate,
            closeEndDate,
          });
        } else {
          openAlert('info', 'Please enter or select at least one search criteria');
        }
      }}
      ref={theForm}
    >
      <Typography component="h3" variant="h6">
        Search
      </Typography>
      <FormGroup>
        <TextField
          id="applicantFirstName"
          label="Applicant first name"
          size="small"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <TextField
          id="applicantLastName"
          label="Applicant last name"
          size="small"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <Autocomplete
          id="loanOfficer"
          size="small"
          disablePortal
          options={loanOfficerOptions}
          renderInput={(params) => <TextField {...params} label="Loan officer" />}
          sx={{
            width: '200px',
          }}
          value={loanOfficer}
          onChange={(_, val) => setLoanOfficer(val)}
        />
        <Autocomplete
          id="lender"
          size="small"
          disablePortal
          options={lenderOptions}
          renderInput={(params) => <TextField {...params} label="Lender" />}
          sx={{
            width: '230px',
          }}
          value={lender}
          onChange={(_, val) => setLender(val)}
        />
        <TextField
          id="interestRate"
          type="number"
          label="Interest rate"
          size="small"
          inputProps={{
            min: 0,
            step: 0.01,
          }}
          sx={{
            width: '125px',
            '.MuiInputLabel-root': {
              paddingRight: '10px',
              backgroundColor: '#fff',
            },
            input: {
              paddingRight: '30px',
            },
            ':after': {
              content: `'%'`,
              position: 'absolute',
              top: '9px',
              right: '16px',
              fontWeight: 'bold',
            },
          }}
          value={interestRate}
          onChange={(e) => setInterestRate(e.target.value)}
        />
        <Autocomplete
          id="loanType"
          size="small"
          disablePortal
          options={loanTypeOptions}
          renderInput={(params) => <TextField {...params} label="Loan type" />}
          sx={{
            width: '215px',
          }}
          value={loanType}
          onChange={(_, val) => setLoanType(val)}
        />
      </FormGroup>
      <FormGroup>
        <Box
          sx={{
            margin: '20px 30px 0 0',
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateRangePicker
              id="applicationDate"
              startText="From"
              endText="To"
              value={applicationDate}
              onChange={(d) => {
                setApplicationDateValue(d);
              }}
              calendars={3}
              renderInput={(fromProps, toProps) => (
                <>
                  <Box
                    sx={{
                      marginRight: '10px',
                    }}
                  >
                    Application date
                  </Box>
                  <TextField
                    {...fromProps}
                    size="small"
                    style={{
                      width: '120px',
                      margin: 0,
                    }}
                  />
                  <ArrowForwardIcon
                    sx={{
                      alignSelf: 'center',
                      margin: '0 7px',
                      color: '#c4c4c4',
                    }}
                  />
                  <TextField
                    {...toProps}
                    size="small"
                    style={{
                      width: '120px',
                      margin: 0,
                    }}
                  />
                </>
              )}
            />
          </LocalizationProvider>
        </Box>
        <Box
          sx={{
            marginTop: '20px',
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateRangePicker
              id="closingDate"
              startText="From"
              endText="To"
              value={closingDate}
              onChange={(d) => {
                setClosingDateValue(d);
              }}
              calendars={3}
              renderInput={(fromProps, toProps) => (
                <>
                  <Box
                    sx={{
                      marginRight: '10px',
                    }}
                  >
                    Completion/closing date
                  </Box>
                  <TextField
                    {...fromProps}
                    size="small"
                    style={{
                      width: '120px',
                      margin: 0,
                    }}
                  />
                  <ArrowForwardIcon
                    sx={{
                      alignSelf: 'center',
                      margin: '0 7px',
                      color: '#c4c4c4',
                    }}
                  />
                  <TextField
                    {...toProps}
                    size="small"
                    style={{
                      width: '120px',
                      margin: '0 20px 0 0',
                    }}
                  />
                </>
              )}
            />
          </LocalizationProvider>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '20px',
            '.MuiButton-root + .MuiButton-root': {
              marginLeft: '16px',
            },
          }}
        >
          <Button variant="contained" disableElevation type="submit" disabled={isLoading}>
            Search
          </Button>
          <Button variant="outlined" disableElevation disabled={isLoading} onClick={() => clearForm()}>
            Clear
          </Button>
        </Box>
      </FormGroup>
    </Paper>
  );
};

Files.propTypes = {
  doSearch: PropTypes.func.isRequired,
  setIsSuccess: PropTypes.func.isRequired,
  setResults: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default Files;
