import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

const AppContext = React.createContext({
  isAppLoading: true,
  setIsAppLoading: () => {},
});

export const AppProvider = ({ children }) => {
  const [isAppLoading, setIsAppLoading] = useState(true);

  const exportValue = useMemo(
    () => ({
      isAppLoading,
      setIsAppLoading,
    }),
    [isAppLoading]
  );

  return <AppContext.Provider value={exportValue}>{children}</AppContext.Provider>;
};

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAppContext = () => React.useContext(AppContext);
