import { XMLParser } from 'fast-xml-parser';
import { v4 as uuidv4 } from 'uuid';
import { AmortizationType, LienPriorityType, LoanPurpose, MortgageType, UsageType } from 'constants/enums';
import { capitalizeString } from './index';
import { pascalCaseToEnumValue } from './enumUtils';
import { getNowAsUtcIso } from './dateUtils';
import loanOriginators from '../loanOriginators';
import { StringBoolean } from '../constants/enums';

const parser = new XMLParser({
  preserveOrder: true,
  trimValues: true,
  numberParseOptions: {
    leadingZeros: false,
  },
});

// const extractCreateDateTime = (parsedXml) =>
//   parsedXml?.MESSAGE[0]?.ABOUT_VERSIONS[0]?.ABOUT_VERSION[0]?.CreatedDatetime[0]['#text'];

const getDeal = (parsedXml) => parsedXml?.MESSAGE[1]?.DEAL_SETS[0]?.DEAL_SET[0]?.DEALS[0]?.DEAL;

const extractCollaterals = (parsedXml) => {
  const deal = getDeal(parsedXml);
  if (!deal) {
    throw new Error('No Deal included in this File');
  }
  const collaterals = deal.find((it) => Object.keys(it).includes('COLLATERALS')).COLLATERALS;
  const collateral = collaterals.find((it) => Object.keys(it).includes('COLLATERAL')).COLLATERAL;
  if (!collaterals || !collateral) {
    throw new Error('No Collaterals included in this File');
  }
  const subjectProperty = collateral.find((it) => Object.keys(it).includes('SUBJECT_PROPERTY')).SUBJECT_PROPERTY;
  if (!subjectProperty) {
    throw new Error('No Subject Property included in this File');
  }
  // 1. Get Address
  const address = subjectProperty.find((it) => Object.keys(it).includes('ADDRESS')).ADDRESS;
  if (!address) {
    throw new Error('No Address included in this File');
  }
  const subjectPropAddress = address.find((it) => Object.keys(it).includes('AddressLineText')).AddressLineText[0][
    '#text'
  ];
  const subjectPropCity = address.find((it) => Object.keys(it).includes('CityName')).CityName[0]['#text'];
  const subjectPropCounty = address.find((it) => Object.keys(it).includes('CountyName')).CountyName[0]['#text'];
  const subjectPropZip = address.find((it) => Object.keys(it).includes('PostalCode')).PostalCode[0]['#text'];
  const subjectPropState = address.find((it) => Object.keys(it).includes('StateCode')).StateCode[0]['#text'];
  // 2. Get Property Detail
  const propertyDetail = subjectProperty.find((it) => Object.keys(it).includes('PROPERTY_DETAIL')).PROPERTY_DETAIL;
  if (!propertyDetail) {
    throw new Error('No Property Detail included in this File');
  }
  const financedUnitCount = propertyDetail.find((it) => Object.keys(it).includes('FinancedUnitCount'))
    .FinancedUnitCount[0]['#text'];
  const usageType = propertyDetail.find((it) => Object.keys(it).includes('PropertyUsageType')).PropertyUsageType[0][
    '#text'
  ];
  // 2. Get Property Valuations
  const subjectPropValuation = subjectProperty.find((it) => Object.keys(it).includes('PROPERTY_VALUATIONS'))
    .PROPERTY_VALUATIONS[0].PROPERTY_VALUATION[0].PROPERTY_VALUATION_DETAIL[0].PropertyValuationAmount[0]['#text'];
  if (!subjectPropValuation) {
    throw new Error('No Property Valuation included in this File');
  }
  return {
    subjectPropAddress,
    subjectPropCity,
    subjectPropCounty,
    subjectPropZip,
    subjectPropState,
    financedUnitCount,
    usageType: pascalCaseToEnumValue(usageType, UsageType),
    subjectPropValuation,
  };
};

const extractLoan = (parsedXml) => {
  const deal = getDeal(parsedXml);
  if (!deal) {
    throw new Error('No Deal included in this File');
  }
  const loan = deal
    .find((it) => Object.keys(it).includes('LOANS'))
    .LOANS.find((it) => Object.keys(it).includes('LOAN')).LOAN;
  if (!loan) {
    throw new Error('No Loan included in this File');
  }
  const amortization = loan
    .find((it) => Object.keys(it).includes('AMORTIZATION'))
    ?.AMORTIZATION.find((it) => Object.keys(it).includes('AMORTIZATION_RULE'))?.AMORTIZATION_RULE;
  const amortizationType = amortization.find((it) => Object.keys(it).includes('AmortizationType'))?.AmortizationType[0][
    '#text'
  ];
  const amortizationPeriod = amortization.find((it) => Object.keys(it).includes('LoanAmortizationPeriodCount'))
    ?.LoanAmortizationPeriodCount[0]['#text'];

  const hoepa = loan
    .find((it) => Object.keys(it).includes('HMDA_LOAN'))
    ?.HMDA_LOAN.find((it) => Object.keys(it).includes('HMDA_LOAN_DETAIL'))
    ?.HMDA_LOAN_DETAIL.find((it) => Object.keys(it).includes('HMDA_HOEPALoanStatusIndicator'))
    ?.HMDA_HOEPALoanStatusIndicator[0]['#text'];

  const maturity = loan.find((it) => Object.keys(it).includes('MATURITY'))?.MATURITY;
  const term =
    maturity
      ?.find((it) => Object.keys(it).includes('MATURITY_RULE'))
      ?.MATURITY_RULE?.find((it) => Object.keys(it).includes('LoanMaturityPeriodCount'))?.LoanMaturityPeriodCount[0][
      '#text'
    ] ?? amortizationPeriod;

  const termsOfLoan = loan.find((it) => Object.keys(it).includes('TERMS_OF_LOAN'))?.TERMS_OF_LOAN;
  const lienPriorityType = termsOfLoan.find((it) => Object.keys(it).includes('LienPriorityType'))?.LienPriorityType[0][
    '#text'
  ];
  const loanPurpose = termsOfLoan.find((it) => Object.keys(it).includes('LoanPurposeType'))?.LoanPurposeType[0][
    '#text'
  ];
  let loanPurposeSubType = null;

  if (loanPurpose === 'Refinance') {
    loanPurposeSubType = loan
      .find((it) => Object.keys(it).includes('REFINANCE'))
      ?.REFINANCE?.find((it) => Object.keys(it).includes('RefinanceCashOutDeterminationType'))
      ?.RefinanceCashOutDeterminationType[0]['#text'];
  }

  const mortgageType = termsOfLoan.find((it) => Object.keys(it).includes('MortgageType'))?.MortgageType[0]['#text'];
  const loanAmount = termsOfLoan.find((it) => Object.keys(it).includes('NoteAmount'))?.NoteAmount[0]['#text'];
  const interestRate = termsOfLoan.find((it) => Object.keys(it).includes('NoteRatePercent'))?.NoteRatePercent[0][
    '#text'
  ];

  const loanDetail = loan.find((it) => Object.keys(it).includes('LOAN_DETAIL'))?.LOAN_DETAIL;
  const appDate = loanDetail?.find((it) => Object.keys(it).includes('ApplicationReceivedDate'))
    ?.ApplicationReceivedDate[0]?.['#text'];

  return {
    applicationDate: appDate || null,
    amortizationType: pascalCaseToEnumValue(amortizationType, AmortizationType),
    amortizationPeriod,
    isHoepa: hoepa,
    term,
    lienPriorityType: pascalCaseToEnumValue(lienPriorityType, LienPriorityType),
    loanPurpose: loanPurposeSubType
      ? pascalCaseToEnumValue(`${loanPurposeSubType}Refinance`, LoanPurpose)
      : pascalCaseToEnumValue(loanPurpose, LoanPurpose),
    mortgageType: pascalCaseToEnumValue(mortgageType, MortgageType),
    loanAmount,
    interestRate,
  };
};

const getPhone = (contactPoints) => {
  const phoneContactPoints = contactPoints.filter((it) => {
    const p = it.CONTACT_POINT?.find((i) =>
      Object.keys(i).includes('CONTACT_POINT_TELEPHONE')
    )?.CONTACT_POINT_TELEPHONE;
    return !!p;
  });
  let phone = null;
  if (phoneContactPoints.length > 1) {
    const mobileCp = phoneContactPoints.find((it) => {
      const cp = it.CONTACT_POINT.find((i) => Object.keys(i).includes('CONTACT_POINT_DETAIL'))?.CONTACT_POINT_DETAIL[0]
        ?.ContactPointRoleType[0]?.['#text'];
      return cp === 'Mobile';
    });
    phone = mobileCp?.CONTACT_POINT.find((i) => Object.keys(i).includes('CONTACT_POINT_TELEPHONE'))
      ?.CONTACT_POINT_TELEPHONE[0]?.ContactPointTelephoneValue[0]?.['#text'];
  } else {
    phone = phoneContactPoints[0]?.CONTACT_POINT.find((i) => Object.keys(i).includes('CONTACT_POINT_TELEPHONE'))
      ?.CONTACT_POINT_TELEPHONE[0]?.ContactPointTelephoneValue[0]?.['#text'];
  }
  return phone;
};

const getEmail = (contactPoints) => {
  const email = contactPoints.find((it) => {
    const e = it.CONTACT_POINT?.find((i) => Object.keys(i).includes('CONTACT_POINT_EMAIL'))?.CONTACT_POINT_EMAIL;
    return !!e;
  })?.CONTACT_POINT[0]?.CONTACT_POINT_EMAIL[0]?.ContactPointEmailValue[0]['#text'];
  return email;
};

const getCoBorrower = (borrowers, primaryBorrower) => {
  const defaultValue = {
    coBorrowerFirstName: null,
    coBorrowerLastName: null,
    coBorrowerEmail: null,
    coBorrowerPhone: null,
    coBorrowerDob: null,
  };
  if (borrowers.length > 1) {
    const coBorrowerData = borrowers[1]?.PARTY;

    const individual = coBorrowerData?.find((it) => Object.keys(it).includes('INDIVIDUAL'))?.INDIVIDUAL;
    const names = individual?.find((it) => Object.keys(it).includes('NAME'))?.NAME;
    const firstName = names?.find((it) => Object.keys(it).includes('FirstName'))?.FirstName[0]['#text'];
    const lastName = names?.find((it) => Object.keys(it).includes('LastName'))?.LastName[0]['#text'];

    const contactPoints = individual?.find((it) => Object.keys(it).includes('CONTACT_POINTS'))?.CONTACT_POINTS;
    const phone = getPhone(contactPoints);
    const email = getEmail(contactPoints);

    const borrower = coBorrowerData
      ?.find((it) => Object.keys(it).includes('ROLES'))
      ?.ROLES?.find((it) => Object.keys(it).includes('ROLE'))
      ?.ROLE?.find((it) => Object.keys(it).includes('BORROWER'))?.BORROWER;

    const borrowerDetail = borrower?.find((it) => Object.keys(it).includes('BORROWER_DETAIL'))?.BORROWER_DETAIL;

    const dob = borrowerDetail?.find((it) => Object.keys(it).includes('BorrowerBirthDate'))?.BorrowerBirthDate[0]?.[
      '#text'
    ];

    const address = borrower
      ?.find((it) => Object.keys(it).includes('RESIDENCES'))
      ?.RESIDENCES?.find((it) => Object.keys(it).includes('RESIDENCE'))
      ?.RESIDENCE?.find((it) => Object.keys(it).includes('ADDRESS'))?.ADDRESS;

    const borrowerAddress = address?.find((it) => Object.keys(it).includes('AddressLineText'))?.AddressLineText?.[0]?.[
      '#text'
    ];
    const borrowerCity = address?.find((it) => Object.keys(it).includes('CityName'))?.CityName?.[0]?.['#text'];
    const borrowerCountry = address?.find((it) => Object.keys(it).includes('CountryCode'))?.CountryCode?.[0]?.['#text'];
    const borrowerState = address?.find((it) => Object.keys(it).includes('StateCode'))?.StateCode?.[0]?.['#text'];
    const borrowerZip = address?.find((it) => Object.keys(it).includes('PostalCode'))?.PostalCode?.[0]?.['#text'];

    // only return co-borrower if address is same as primary
    if (
      borrowerAddress === primaryBorrower.borrowerAddress &&
      borrowerCity === primaryBorrower.borrowerCity &&
      borrowerCountry === primaryBorrower.borrowerCountry &&
      borrowerState === primaryBorrower.borrowerState &&
      borrowerZip === primaryBorrower.borrowerZip
    ) {
      return {
        coBorrowerFirstName: capitalizeString(firstName),
        coBorrowerLastName: capitalizeString(lastName),
        coBorrowerEmail: email,
        coBorrowerPhone: phone,
        coBorrowerDob: dob,
      };
    }

    return defaultValue;
  }
  return defaultValue;
};

const getPrimaryBorrower = (borrowers) => {
  const borrowerParty = borrowers[0]?.PARTY;
  const individual = borrowerParty?.find((it) => Object.keys(it).includes('INDIVIDUAL'))?.INDIVIDUAL;
  const names = individual?.find((it) => Object.keys(it).includes('NAME'))?.NAME;
  const firstName = names?.find((it) => Object.keys(it).includes('FirstName'))?.FirstName[0]['#text'];
  const lastName = names?.find((it) => Object.keys(it).includes('LastName'))?.LastName[0]['#text'];

  const contactPoints = individual?.find((it) => Object.keys(it).includes('CONTACT_POINTS'))?.CONTACT_POINTS;
  const email = getEmail(contactPoints);
  const phone = getPhone(contactPoints);

  const borrower = borrowerParty
    ?.find((it) => Object.keys(it).includes('ROLES'))
    ?.ROLES?.find((it) => Object.keys(it).includes('ROLE'))
    ?.ROLE?.find((it) => Object.keys(it).includes('BORROWER'))?.BORROWER;

  const borrowerDetail = borrower?.find((it) => Object.keys(it).includes('BORROWER_DETAIL'))?.BORROWER_DETAIL;

  const dob = borrowerDetail?.find((it) => Object.keys(it).includes('BorrowerBirthDate'))?.BorrowerBirthDate[0]?.[
    '#text'
  ];

  const address = borrower
    ?.find((it) => Object.keys(it).includes('RESIDENCES'))
    ?.RESIDENCES?.find((it) => Object.keys(it).includes('RESIDENCE'))
    ?.RESIDENCE?.find((it) => Object.keys(it).includes('ADDRESS'))?.ADDRESS;

  const borrowerAddress = address?.find((it) => Object.keys(it).includes('AddressLineText'))?.AddressLineText?.[0]?.[
    '#text'
  ];
  const borrowerCity = address?.find((it) => Object.keys(it).includes('CityName'))?.CityName?.[0]?.['#text'];
  const borrowerCountry = address?.find((it) => Object.keys(it).includes('CountryCode'))?.CountryCode?.[0]?.['#text'];
  const borrowerState = address?.find((it) => Object.keys(it).includes('StateCode'))?.StateCode?.[0]?.['#text'];
  const borrowerZip = address?.find((it) => Object.keys(it).includes('PostalCode'))?.PostalCode?.[0]?.['#text'];

  return {
    borrowerFirstName: capitalizeString(firstName),
    borrowerLastName: capitalizeString(lastName),
    borrowerDob: dob,
    borrowerEmail: email,
    borrowerPhone: phone,
    borrowerAddress,
    borrowerCity,
    borrowerCountry,
    borrowerState,
    borrowerZip,
  };
};

const getAllBorrowers = (parties) => {
  const borrowers = parties.filter((it) => {
    const borrowerRole = it.PARTY?.find((i) => Object.keys(i).includes('ROLES'))
      ?.ROLES.find((i) => Object.keys(i).includes('ROLE'))
      ?.ROLE.find((i) => Object.keys(i).includes('ROLE_DETAIL'))?.ROLE_DETAIL[0]?.PartyRoleType[0]['#text'];
    return borrowerRole && borrowerRole === 'Borrower';
  });
  return borrowers;
};

const getLoanOfficer = (parties) => {
  const officers = parties.filter((it) => {
    const officerRole = it.PARTY?.find((i) => Object.keys(i).includes('ROLES'))
      ?.ROLES.find((i) => Object.keys(i).includes('ROLE'))
      ?.ROLE.find((i) => Object.keys(i).includes('ROLE_DETAIL'))?.ROLE_DETAIL[0]?.PartyRoleType[0]['#text'];
    return officerRole && officerRole === 'LoanOriginator';
  });
  const individual = officers?.[0]?.PARTY?.find((it) => Object.keys(it).includes('INDIVIDUAL'))?.INDIVIDUAL;
  const name = individual?.find((it) => Object.keys(it).includes('NAME'))?.NAME?.[0]?.FullName?.[0]?.['#text'];

  return {
    loanOriginatorName: capitalizeString(name),
  };
};

const extractParties = (parsedXml) => {
  const deal = getDeal(parsedXml);
  if (!deal) {
    throw new Error('No Deal included in this File');
  }
  const parties = deal.find((it) => Object.keys(it).includes('PARTIES'))?.PARTIES;
  if (!parties || !parties.length) {
    throw new Error('No Parties included in this File');
  }
  const borrowers = getAllBorrowers(parties);
  const borrower = getPrimaryBorrower(borrowers);
  const coBorrrower = getCoBorrower(borrowers, borrower);

  const loanOfficer = getLoanOfficer(parties);
  const { loanOriginatorName } = loanOfficer;

  return {
    ...borrower,
    ...coBorrrower,
    loanOriginatorName: loanOriginators.find((it) => it.label === loanOriginatorName || it.alias === loanOriginatorName)
      ?.value,
  };
};

export const parseFile = (xml, tenantId) => {
  try {
    const parsed = parser.parse(xml);
    // const applicationDate = extractCreateDateTime(parsed);
    const collaterals = extractCollaterals(parsed);
    const loan = extractLoan(parsed);
    const parties = extractParties(parsed);
    return {
      id: uuidv4(),
      tenantId,
      // applicationDate,
      importDate: getNowAsUtcIso(),
      isDeleted: StringBoolean.False.value,
      ...collaterals,
      ...loan,
      ...parties,
    };
  } catch {
    // console.error(error);
    return null;
  }
};
