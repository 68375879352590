import React from 'react';
import Main from '../components/Main';

const NoMatch = () => (
  <Main pagetitle="Error 404">
    <p>Uh oh, it looks like this page doesn&apos;t exist.</p>
  </Main>
);

export default NoMatch;
