import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { Autocomplete, Box, Paper, FormGroup, TextField, Typography, CircularProgress } from '@mui/material';
import { isEmpty } from 'utils/index';
import { checkShouldShowLoanStatusWarning, updateFormState, getInitialFileFormState } from 'utils/formUtils';
import { LoanStatus, State } from 'constants/enums';
import ActionModal from '../components/ActionModal';
import {
  applicationTypeOptions,
  financedUnitCountOptions,
  lenderOptions,
  loanStatusOptions,
  loanTypeOptions,
  receivedFromOptions,
  reverseMortgagePurposeOptions,
  reverseMortgageTypeOptions,
  serviceLoanTypeOptions,
  stateOptions,
  usageTypeOptions,
} from '../selectOptions';
import loanOfficerOptions from '../loanOriginators';
import { useAlertContext } from '../context/AlertContext';

const FormReverseMortgage = (props) => {
  const { mode, data, isLoading, formId, onSubmit } = props;
  const { openAlert } = useAlertContext();

  // Set file close modal state
  const [fileCloseModalOpen, setFileCloseModalOpen] = useState(false);

  const [fileCreateModalOpen, setFileCreateModalOpen] = useState(false);

  const [shouldLogStatusChange, setShouldLogStatusChange] = useState(false);

  const [formState, setFormState] = useState(getInitialFileFormState({}));

  const hasData = useMemo(() => !isEmpty(data) || mode === 'CREATE', [data, mode]);

  const editable = useMemo(() => mode === 'EDIT' || mode === 'CREATE', [mode]);

  const [isAllRequired, setIsAllRequired] = useState(false);

  const [isMinRequired, setIsMinRequired] = useState(false);

  useEffect(() => {
    setFormState(
      getInitialFileFormState({
        ...(data || {
          isReverseMortgage: true,
        }),
      })
    );
  }, [data, mode]);

  const {
    applicationDate,
    applicationType,
    borrowerEmail,
    borrowerFirstName,
    borrowerLastName,
    borrowerPhone,
    brokerFeesCollected,
    closingDate,
    closingLoanAmount,
    financedUnitCount,
    lender,
    loanAmount,
    loanAmountLocked,
    loanOriginatorName,
    loanStatus,
    loanType,
    receivedFrom,
    reverseMortgagePurpose,
    reverseMortgageType,
    serviceLoanType,
    subjectPropAddress,
    subjectPropCity,
    subjectPropCounty,
    subjectPropState,
    subjectPropValuation,
    subjectPropZip,
    usageType,
  } = formState;

  const fileCloseModalProps = useMemo(
    () => ({
      modalCtaText: 'Change Loan Status and Lock File',
      modalMessage: `Changing the loan status to ${loanStatus?.label} will lock the file and only an admin will be able to edit it going forward.`,
      modalTextAlign: 'center',
      modalWidth: '500px',
      modalOpen: fileCloseModalOpen,
      // set isLocked true as that's what this modal is for
      modalOnClick: () => {
        setFileCloseModalOpen(false);
        onSubmit({ ...formState, isLocked: true }, shouldLogStatusChange, true);
      },
      modalOnCancel: () => setFileCloseModalOpen(false),
    }),
    [fileCloseModalOpen, formState, loanStatus?.label, onSubmit, shouldLogStatusChange]
  );

  const fileCreateModalProps = useMemo(
    () => ({
      modalCtaText: 'Create reverse mortgage',
      modalMessage: `Confirm creation of reverse mortgage file?`,
      modalTextAlign: 'center',
      modalWidth: '400px',
      modalOpen: fileCreateModalOpen,
      modalOnClick: () => {
        setFileCreateModalOpen(false);
        onSubmit({ ...formState, isLocked: false }, false, true);
      },
      modalOnCancel: () => setFileCreateModalOpen(false),
    }),
    [fileCreateModalOpen, formState, onSubmit]
  );

  const isServiceLoanDisabled = useMemo(() => {
    if (subjectPropState?.value === State.CT.value) return true;
    return !editable;
  }, [editable, subjectPropState]);

  const isServiceLoanRequired = useMemo(() => subjectPropState?.value !== State.CT.value, [subjectPropState]);

  return isLoading || !hasData ? (
    <Box
      sx={{
        minHeight: '206px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <>
      <Box
        onSubmit={(e) => {
          e.preventDefault();
          if (!!closingDate && !isEmpty(loanStatus) && loanStatus.value === LoanStatus.In_Process.value) {
            openAlert('error', 'Loan status cannot be in process if a completion/closing date has been entered');
            return false;
          }
          if (e.target.reportValidity()) {
            if (mode === 'EDIT') {
              const shouldLock = checkShouldShowLoanStatusWarning(loanStatus);
              if (shouldLock) {
                setFileCloseModalOpen(true);
              } else {
                onSubmit(formState, shouldLogStatusChange, true);
              }
            } else {
              setFileCreateModalOpen(true);
            }
          }
          return true;
        }}
        id={formId}
        component="form"
        sx={{
          // Paper
          '.MuiPaper-root': {
            padding: '20px',
            '+ .MuiPaper-root': {
              marginTop: '20px',
            },
            '&.MuiAutocomplete-paper': {
              padding: 0,
            },
          },

          // FormGroup
          '.MuiFormGroup-root': {
            flexDirection: 'row',
            '+ .MuiFormGroup-root': {
              marginTop: '20px',
            },
            h6: {
              flex: '0 0 100%',
              fontWeight: 'bold',
              color: '#145ca4',
            },
          },

          // Textfield
          '.MuiTextField-root': {
            margin: '20px 20px 0 0',
          },

          // Autocomplete
          '.MuiAutocomplete-root': {
            margin: '20px 20px 0 0',
            '.MuiFormControl-root': {
              margin: 0,
            },
          },

          // Required
          '.MuiInputLabel-asterisk': {
            color: '#d32f2f',
          },

          // Disabled Styling
          'label.Mui-disabled': {
            color: '#666666',
          },
          legend: {
            color: '#666666',
          },
          '.MuiFormControlLabel-label': {
            '&.Mui-disabled': {
              color: '#212121 !important',
            },
          },
          '.MuiInputBase-input': {
            '&.Mui-disabled': {
              color: '#212121',
            },
          },
          '.MuiRadio-root': {
            '&.Mui-disabled': {
              '& svg': {
                fill: 'rgba(0, 0, 0, 0.6)',
              },
            },
          },
        }}
      >
        <Paper>
          <Typography component="h3" variant="h6">
            Status
          </Typography>

          <FormGroup>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                id="applicationDate"
                label="Application date"
                value={applicationDate}
                disabled={!editable}
                onChange={(v) => {
                  updateFormState('applicationDate', v, formState, setFormState);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    required
                    error={isAllRequired ? !applicationDate : undefined}
                    sx={{
                      width: '185px',
                    }}
                  />
                )}
              />
            </LocalizationProvider>
            <Autocomplete
              id="loanStatus"
              size="small"
              disablePortal
              options={loanStatusOptions}
              value={loanStatus}
              disabled={!editable}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Loan status"
                  required
                  error={isAllRequired ? !loanStatus?.value : undefined}
                />
              )}
              sx={{
                width: '440px',
              }}
              onChange={(_, v) => {
                // if (loanStatus !=== 'In process') { Add required prop to all form fields
                setIsAllRequired(v.value !== LoanStatus.In_Process.value);
                // if (loanStatus === 'Closed and funded') { add inputProps={{ min: 1 }} to closingLoanAmount and brokerFeesCollected }
                setIsMinRequired(v.value === LoanStatus.Closed_Funded.value);
                setShouldLogStatusChange(true);
                updateFormState('loanStatus', v, formState, setFormState);
              }}
            />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                id="closingDate"
                label="Completion/closing date"
                value={closingDate}
                disabled={!editable}
                onChange={(v) => {
                  updateFormState('closingDate', v, formState, setFormState);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    sx={{
                      width: '245px',
                    }}
                    required={isAllRequired}
                    error={isAllRequired ? !closingDate : undefined}
                  />
                )}
              />
            </LocalizationProvider>
            <Autocomplete
              id="lender"
              size="small"
              disablePortal
              options={lenderOptions}
              value={lender}
              disabled={!editable}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Lender"
                  required={isAllRequired}
                  error={isAllRequired ? !lender?.value : undefined}
                />
              )}
              sx={{
                width: '230px',
              }}
              onChange={(_, v) => {
                updateFormState('lender', v, formState, setFormState);
              }}
            />
          </FormGroup>
        </Paper>

        <Paper>
          <Typography component="h3" variant="h6">
            Borrower
          </Typography>
          <FormGroup>
            <TextField
              id="borrowerFirstName"
              label="First Name"
              value={borrowerFirstName}
              size="small"
              disabled={!editable}
              required
              sx={{
                width: '150px',
              }}
              onChange={(e) => {
                updateFormState('borrowerFirstName', e.target.value, formState, setFormState);
              }}
              error={isAllRequired ? !borrowerFirstName : undefined}
            />
            <TextField
              id="borrowerLastName"
              label="Last Name"
              value={borrowerLastName}
              size="small"
              disabled={!editable}
              required
              sx={{
                width: '150px',
              }}
              onChange={(e) => {
                updateFormState('borrowerLastName', e.target.value, formState, setFormState);
              }}
              error={isAllRequired ? !borrowerLastName : undefined}
            />
            <TextField
              id="borrowerPhone"
              type="number"
              label="Phone"
              value={borrowerPhone}
              size="small"
              disabled={!editable}
              required
              sx={{
                width: '150px',
              }}
              onChange={(e) => {
                updateFormState('borrowerPhone', e.target.value, formState, setFormState);
              }}
              error={isAllRequired ? !borrowerPhone : undefined}
            />
            <TextField
              id="borrowerEmail"
              label="Email"
              value={borrowerEmail}
              size="small"
              disabled={!editable}
              required
              sx={{
                width: '260px',
              }}
              onChange={(e) => {
                updateFormState('borrowerEmail', e.target.value, formState, setFormState);
              }}
              error={isAllRequired ? !borrowerEmail : undefined}
            />
          </FormGroup>
        </Paper>

        <Paper>
          <Typography component="h3" variant="h6">
            Subject Property
          </Typography>
          <FormGroup>
            <TextField
              id="subjectPropAddress"
              label="Address"
              value={subjectPropAddress}
              size="small"
              disabled={!editable}
              required
              sx={{
                width: '185px',
              }}
              onChange={(e) => {
                updateFormState('subjectPropAddress', e.target.value, formState, setFormState);
              }}
              error={isAllRequired ? !subjectPropAddress : undefined}
            />
            <TextField
              id="subjectPropCity"
              label="City"
              value={subjectPropCity}
              size="small"
              disabled={!editable}
              required
              sx={{
                width: '150px',
              }}
              onChange={(e) => {
                updateFormState('subjectPropCity', e.target.value, formState, setFormState);
              }}
              error={isAllRequired ? !subjectPropCity : undefined}
            />
            <TextField
              id="subjectPropCounty"
              label="County"
              value={subjectPropCounty}
              size="small"
              disabled={!editable}
              required
              sx={{
                width: '150px',
              }}
              onChange={(e) => {
                updateFormState('subjectPropCounty', e.target.value, formState, setFormState);
              }}
              error={isAllRequired ? !subjectPropCounty : undefined}
            />
            <TextField
              id="subjectPropZip"
              label="ZIP"
              value={subjectPropZip}
              size="small"
              disabled={!editable}
              required
              inputProps={{
                maxLength: 6,
              }}
              sx={{
                width: '90px',
              }}
              onChange={(e) => {
                updateFormState('subjectPropZip', e.target.value, formState, setFormState);
              }}
              error={isAllRequired ? !subjectPropZip : undefined}
            />
            <Autocomplete
              id="subjectPropState"
              size="small"
              disablePortal
              value={subjectPropState}
              options={stateOptions}
              disabled={!editable}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="State"
                  required
                  error={isAllRequired ? !subjectPropState?.value : undefined}
                  sx={{
                    width: '100px',
                  }}
                />
              )}
              onChange={(_, v) => {
                const subjectPropStateValue = {
                  ...formState,
                  ...(v?.value === State.CT.value ? { serviceLoanType: null } : {}),
                  subjectPropState: v,
                };
                setFormState(subjectPropStateValue);
              }}
            />
          </FormGroup>

          <FormGroup>
            <Autocomplete
              id="financedUnitCount"
              size="small"
              disablePortal
              value={financedUnitCount}
              options={financedUnitCountOptions}
              disabled={!editable}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Financed unit count"
                  required
                  error={isAllRequired ? !financedUnitCount?.value : undefined}
                  sx={{
                    width: '200px',
                  }}
                />
              )}
              onChange={(_, v) => {
                updateFormState('financedUnitCount', v, formState, setFormState);
              }}
            />
            <TextField
              id="subjectPropValuation"
              type="number"
              label="Valuation"
              value={subjectPropValuation}
              size="small"
              disabled={!editable}
              inputProps={{ min: 1 }}
              required
              sx={{
                width: '120px',
                '.MuiInputLabel-root': {
                  backgroundColor: '#fff',
                },
                ':after': {
                  content: `'$'`,
                  position: 'absolute',
                  top: '9px',
                  left: '16px',
                },
                input: {
                  paddingLeft: '28px',
                },
              }}
              onChange={(e) => {
                updateFormState('subjectPropValuation', e.target.value, formState, setFormState);
              }}
              error={isAllRequired ? !subjectPropValuation || subjectPropValuation < 1 : undefined}
            />
            <Autocomplete
              id="usageType"
              size="small"
              disablePortal
              value={usageType}
              options={usageTypeOptions}
              disabled={!editable}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Usage"
                  required
                  error={isAllRequired ? !usageType?.value : undefined}
                  sx={{
                    width: '235px',
                  }}
                />
              )}
              onChange={(_, v) => {
                updateFormState('usageType', v, formState, setFormState);
              }}
            />
          </FormGroup>
        </Paper>

        <Paper>
          <Typography component="h3" variant="h6">
            Application
          </Typography>
          <FormGroup>
            <TextField
              id="loanAmount"
              type="number"
              label="Loan amount"
              value={loanAmount}
              size="small"
              disabled={loanAmountLocked ? true : !editable}
              inputProps={{ min: 1 }}
              required
              sx={{
                width: '140px',
                '.MuiInputLabel-root': {
                  backgroundColor: '#fff',
                },
                ':after': {
                  content: `'$'`,
                  position: 'absolute',
                  top: '9px',
                  left: '16px',
                },
                input: {
                  paddingLeft: '28px',
                },
              }}
              onChange={(e) => {
                updateFormState('loanAmount', e.target.value, formState, setFormState);
              }}
              error={isAllRequired ? !loanAmount || loanAmount < 1 : undefined}
            />
            <Autocomplete
              id="reverseMortgageType"
              size="small"
              disablePortal
              value={reverseMortgageType}
              options={reverseMortgageTypeOptions}
              disabled={!editable}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Reverse mortgage type"
                  required
                  error={isAllRequired ? !reverseMortgageType?.value : undefined}
                  sx={{
                    width: '225px',
                  }}
                />
              )}
              onChange={(_, v) => {
                updateFormState('reverseMortgageType', v, formState, setFormState);
              }}
            />
            <Autocomplete
              id="serviceLoanType"
              size="small"
              disablePortal
              value={serviceLoanType}
              options={serviceLoanTypeOptions}
              disabled={isServiceLoanDisabled}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Service loan type"
                  required={isServiceLoanRequired}
                  error={isAllRequired ? !serviceLoanType?.value : undefined}
                  sx={{
                    width: '185px',
                  }}
                />
              )}
              onChange={(_, v) => {
                updateFormState('serviceLoanType', v, formState, setFormState);
              }}
            />
            <Autocomplete
              id="reverseMortgagePurpose"
              size="small"
              disablePortal
              value={reverseMortgagePurpose}
              options={reverseMortgagePurposeOptions}
              disabled={!editable}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Reverse mortgage purpose"
                  required
                  error={isAllRequired ? !reverseMortgagePurpose?.value : undefined}
                  sx={{
                    width: '255px',
                  }}
                />
              )}
              onChange={(_, v) => {
                updateFormState('reverseMortgagePurpose', v, formState, setFormState);
              }}
            />
          </FormGroup>
        </Paper>

        <Paper>
          <Typography component="h3" variant="h6">
            Compliance
          </Typography>
          <FormGroup>
            <TextField
              id="closingLoanAmount"
              type="number"
              label="Closing principal limit"
              value={closingLoanAmount}
              size="small"
              disabled={!editable}
              sx={{
                width: '190px',
                '.MuiInputLabel-root': {
                  backgroundColor: '#fff',
                },
                ':after': {
                  content: `'$'`,
                  position: 'absolute',
                  top: '9px',
                  left: '16px',
                },
                input: {
                  paddingLeft: '28px',
                },
              }}
              onChange={(e) => {
                updateFormState('closingLoanAmount', e.target.value, formState, setFormState);
              }}
              required={isAllRequired}
              inputProps={{ min: isAllRequired ? 1 : 0 }}
              error={isAllRequired ? !closingLoanAmount || closingLoanAmount < 1 : undefined}
            />
            <TextField
              id="brokerFeesCollected"
              type="number"
              label="Broker fees collected"
              value={brokerFeesCollected}
              size="small"
              disabled={!editable}
              sx={{
                width: '185px',
                '.MuiInputLabel-root': {
                  backgroundColor: '#fff',
                },
                ':after': {
                  content: `'$'`,
                  position: 'absolute',
                  top: '9px',
                  left: '16px',
                },
                input: {
                  paddingLeft: '28px',
                },
              }}
              onChange={(e) => {
                updateFormState('brokerFeesCollected', e.target.value, formState, setFormState);
              }}
              required={isMinRequired}
              inputProps={{ min: isMinRequired ? 1 : 0 }}
              error={isMinRequired ? !brokerFeesCollected || brokerFeesCollected < 1 : undefined}
            />
            <Autocomplete
              id="loanType"
              size="small"
              disablePortal
              // value={loanType}
              value={loanTypeOptions[2]}
              options={loanTypeOptions}
              // disabled={!editable}
              disabled
              hidden
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Loan type"
                  required
                  error={isAllRequired ? !loanType?.value : undefined}
                  sx={{
                    width: '215px',
                  }}
                />
              )}
              onChange={(_, v) => {
                updateFormState('loanType', v, formState, setFormState);
              }}
            />
            <Autocomplete
              id="applicationType"
              size="small"
              disablePortal
              value={applicationType}
              options={applicationTypeOptions}
              disabled={!editable}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Application type"
                  required
                  error={isAllRequired ? !applicationType?.value : undefined}
                  sx={{
                    width: '295px',
                  }}
                />
              )}
              onChange={(_, v) => {
                updateFormState('applicationType', v, formState, setFormState);
              }}
            />
            <Autocomplete
              id="receivedFrom"
              size="small"
              disablePortal
              value={receivedFrom}
              options={receivedFromOptions}
              disabled={!editable}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Received from"
                  required
                  error={isAllRequired ? !receivedFrom?.value : undefined}
                  sx={{
                    width: '200px',
                  }}
                />
              )}
              onChange={(_, v) => {
                updateFormState('receivedFrom', v, formState, setFormState);
              }}
            />
            <Autocomplete
              id="loanOriginatorName"
              size="small"
              disablePortal
              value={loanOriginatorName}
              options={loanOfficerOptions}
              disabled={!editable}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Loan officer"
                  required
                  error={isAllRequired ? !loanOriginatorName?.value : undefined}
                  sx={{
                    width: '200px',
                  }}
                />
              )}
              onChange={(_, v) => {
                updateFormState('loanOriginatorName', v, formState, setFormState);
              }}
            />
          </FormGroup>
        </Paper>
      </Box>

      <ActionModal {...fileCloseModalProps} />
      <ActionModal {...fileCreateModalProps} />
    </>
  );
};

FormReverseMortgage.propTypes = {
  mode: PropTypes.oneOf(['VIEW', 'EDIT', 'CREATE']).isRequired,
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.shape(),
  formId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
};

FormReverseMortgage.defaultProps = {
  data: {},
  onSubmit: null,
};

export default FormReverseMortgage;
