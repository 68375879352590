import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Paper, Typography } from '@mui/material';
import { isEmpty } from '../utils/index';
import { getFormattedDate } from '../utils/dateUtils';
import { enumValueToLabel } from '../utils/enumUtils';
import { LoanType, AmortizationType } from '../constants/enums';

// Table columns
const filesTableColumns = [
  {
    field: 'borrowerFirstName',
    headerName: 'First Name',
    flex: 1,
  },
  {
    field: 'borrowerLastName',
    headerName: 'Last Name',
    flex: 1,
  },
  {
    field: 'loanType',
    headerName: 'Loan type',
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    renderCell: (val) => enumValueToLabel(val.value, LoanType),
  },
  {
    field: 'lender',
    headerName: 'Lender',
    flex: 1,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'interestRate',
    headerName: 'Interest rate',
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    renderCell: (val) => `${val.value}%`,
  },
  {
    field: 'closingDate',
    type: 'date',
    headerName: 'Completion/closing date',
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    renderCell: (val) => getFormattedDate(val.value),
  },
  {
    field: 'loanAmount',
    headerName: 'Loan amount',
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    renderCell: (n) =>
      !isEmpty(n.value) ? Number(n.value).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '',
  },
  {
    field: 'amortizationType',
    headerName: 'Amortization',
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    renderCell: (val) => (val.value ? enumValueToLabel(val.value, AmortizationType) : ''),
  },
  {
    field: 'subjectPropState',
    headerName: 'State',
    flex: 1,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'viewButton',
    headerName: '',
    flex: 1,
    renderCell: (d) => (
      <Button variant="contained" disableElevation component={Link} to={`/file/${d.row.id}`} target="_blank">
        View
      </Button>
    ),
    disableClickEventBubbling: true,
    align: 'center',
    headerClassName: 'table-heading--empty',
  },
];

const TableFiles = (props) => {
  const { isLoading, data } = props;
  // Sets initial table page size on load
  const [pageSize, setPageSize] = useState(10);

  return (
    <Paper
      sx={{
        position: 'relative',
        padding: '30px 20px 20px 20px',
        marginTop: '-4px',
        borderRadius: '0 0 4px 4px',
        '&::before': {
          content: '""',
          width: 'calc(100% - 40px)',
          height: '1px',
          display: 'block',
          position: 'absolute',
          top: '14px',
          left: '20px',
          backgroundColor: 'rgba(0, 0, 0, 0.23)',
        },

        // Tables
        '.MuiDataGrid-root': {
          '.MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
            '&:focus, &:focus-within': {
              outline: 'none',
            },
          },
          '.table-heading--empty *': {
            display: 'none',
          },
          '.MuiDataGrid-cell--textLeft': {
            paddingLeft: '16px',
          },
          '.MuiDataGrid-cell--textRight': {
            paddingLeft: '16px',
          },
          '.MuiDataGrid-columnsContainer': {
            borderRadius: '4px 4px 0 0',
            backgroundColor: '#dbdbdb',
          },
          '.MuiDataGrid-overlay': {
            paddingTop: '64px',
          },
        },
      }}
    >
      <Typography component="h3" variant="h6" sx={{ paddingBottom: '10px' }}>
        Results
      </Typography>
      <DataGrid
        loading={isLoading}
        columns={filesTableColumns}
        rows={data}
        disableSelectionOnClick
        rowHeight={55}
        autoHeight
        pageSize={pageSize}
        onPageSizeChange={(ps) => setPageSize(ps)}
        rowsPerPageOptions={[10, 25, 50, 100]}
        pagination
      />
    </Paper>
  );
};

TableFiles.propTypes = {
  isLoading: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.shape()),
};

TableFiles.defaultProps = {
  isLoading: false,
  data: [],
};

export default TableFiles;
