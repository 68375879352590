/* eslint-disable no-nested-ternary */
import React from 'react';
import FormReverseMortgage from '../forms/FormReverseMortgage';
import Main from '../components/Main';
import PageActionsFile from '../components/PageActionsFile';
import { useFileCreateContext } from '../context/FileCreateContext';

const CreateReverseMortgage = () => {
  const { isLoading, createFile } = useFileCreateContext();
  return (
    <Main pagetitle="Create Reverse Mortgage">
      <PageActionsFile mode="CREATE" data={null} isLoading={isLoading} formId="createFile" />
      <FormReverseMortgage mode="CREATE" data={null} isLoading={isLoading} onSubmit={createFile} formId="createFile" />
    </Main>
  );
};
export default CreateReverseMortgage;
