/* eslint-disable no-nested-ternary */
import React, { useMemo, useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { isEmpty } from 'utils/index';
import { useAlertContext } from '../context/AlertContext';
import FormFile from '../forms/FormFile';
import FormReverseMortgage from '../forms/FormReverseMortgage';
import Main from '../components/Main';
import PageActionsFile from '../components/PageActionsFile';
import { useFileContext } from '../context/FileContext';

const File = () => {
  const location = useLocation();
  const [loadMessageDisplayed, setLoadMessageDisplayed] = useState(false);
  const params = useParams();
  const { isLoading, getFile } = useFileContext();
  const { openAlert } = useAlertContext();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams && !loadMessageDisplayed) {
      const message = searchParams.get('message');
      const result = searchParams.get('result');
      if (message && result) {
        setLoadMessageDisplayed(true);
        openAlert(result, message);
      }
    }
  }, [openAlert, location, loadMessageDisplayed]);

  const { id } = params;
  const record = getFile(id);

  const hasData = useMemo(() => !isEmpty(record), [record]);

  const title = useMemo(() => {
    if (isLoading || !hasData) return `Loading File...`;
    return record && record.isReverseMortgage
      ? `Reverse mortgage file for ${record.borrowerFirstName} ${record.borrowerLastName}`
      : `Forward mortgage ${
          record.importFile
            ? `import: ${record.importFile}`
            : `for ${record.borrowerFirstName} ${record.borrowerLastName}`
        }`;
  }, [isLoading, record, hasData]);

  return (
    <Main pagetitle={title}>
      {isLoading || !hasData ? null : (
        <PageActionsFile mode="VIEW" data={record} isLoading={isLoading} formId="editFile" />
      )}
      {isLoading ? null : record && record.isReverseMortgage ? (
        <FormReverseMortgage mode="VIEW" data={record} isLoading={isLoading} onSubmit={null} formId="editFile" />
      ) : (
        <FormFile mode="VIEW" data={record} isLoading={isLoading} onSubmit={null} formId="editFile" />
      )}
    </Main>
  );
};
export default File;
