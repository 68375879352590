const { toString } = Object.prototype;

/**
 * Check that a value is null.
 * @param s {*} value to test
 * @returns {boolean} True if is null, else false
 */
export const isNull = (s) => s === null;

/**
 * Gets the `toStringTag` of `value`.
 *
 * @author [jdalton](https://github.com/lodash/lodash/blob/master/.internal/getTag.js)
 *
 * @private
 * @param {*} value The value to query.
 * @returns {string} Returns the `toStringTag`.
 */
const getTag = (value) => {
  if (value === null) {
    return value === undefined ? '[object Undefined]' : '[object Null]';
  }
  return toString.call(value);
};

/**
 * Checks if `value` is the
 * [language type](http://www.ecma-international.org/ecma-262/7.0/#sec-ecmascript-language-types)
 * of `Object`. (e.g. arrays, functions, objects, regexes, `new Number(0)`, and `new String('')`)
 *
 * @author [jdalton](https://github.com/lodash/lodash/blob/master/isObject.js)
 *
 * @param {*} value The value to check.
 * @returns {boolean} Returns `true` if `value` is an object, else `false`.
 */
export const isObject = (value) => {
  if (value === undefined) return false;
  const type = typeof value;
  return value !== null && (type === 'object' || type === 'function');
};

/**
 * Checks if `value` is classified as a `Function` object.
 *
 * @author [jdalton](https://github.com/lodash/lodash/blob/master/isFunction.js)
 *
 * @param {*} value The value to check.
 * @returns {boolean} Returns `true` if `value` is a function, else `false`.
 */
export const isFunction = (value) => {
  if (!isObject(value)) return false;
  const tag = getTag(value);
  return (
    tag === '[object Function]' ||
    tag === '[object AsyncFunction]' ||
    tag === '[object GeneratorFunction]' ||
    tag === '[object Proxy]'
  );
};

/**
 * Checks if `value` is a `String`
 *
 * @param {*} value
 * @returns {boolean}
 */
export const isString = (value) => typeof value === 'string';

/**
 * Checks if `value` is an `Array`
 *
 * @param {*} value
 * @returns {boolean}
 */
export const isArray = (value) => Array.isArray(value);

/**
 * Checks if `value` is empty
 *
 * @param {null|undefined|String|Array|Object} value
 * @returns {boolean}
 */
export const isEmpty = (value) => {
  switch (true) {
    case value === null: {
      return true;
    }
    case value === undefined: {
      return true;
    }
    case isString(value): {
      return value === '';
    }
    case isArray(value): {
      return value.length === 0;
    }
    case isObject(value) && !isFunction(value): {
      return Object.keys(value).length === 0;
    }
    case typeof value === 'boolean': {
      return false;
    }
    default: {
      return false;
    }
  }
};

export const capitalizeFirstWord = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export const capitalizeString = (str) =>
  str
    .split(' ')
    .map((word) => capitalizeFirstWord(word))
    .join(' ')
    .split('-')
    .map((word) => capitalizeFirstWord(word))
    .join('-');

export const getFullName = (f) => (f ? `${f.borrowerFirstName} ${f.borrowerLastName}` : '');
