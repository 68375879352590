import { StringBoolean } from '../constants/enums';

export const stringBooleanToBool = (s) => s === StringBoolean.True.value;
export const boolToStringBoolean = (s) => (s ? StringBoolean.True.value : StringBoolean.False.value);

export const pascalCaseToEnumValue = (str, e) => {
  const replaced = str
    .replace(/\.?([A-Z]+)/g, (_, y) => `_${y}`)
    .replace(/^_/, '')
    .replace('_/', '_')
    .replace('/_', '_')
    .replace('__', '_');
  return e[replaced]?.value ?? str;
};

export const enumValueToLabel = (str, e) => e[str]?.label;
