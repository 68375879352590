import React from 'react';
import { Link } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Paper } from '@mui/material';
import { useFileContext } from '../context/FileContext';
import { getFormattedDate } from '../utils/dateUtils';

// Table columns
const importsTableColumns = [
  {
    field: 'borrowerFirstName',
    headerName: 'First Name',
    flex: 1,
  },
  {
    field: 'borrowerLastName',
    headerName: 'Last Name',
    flex: 1,
  },
  {
    field: 'importFile',
    headerName: 'File name',
    flex: 1,
  },
  {
    field: 'importDate',
    headerName: 'Import date',
    flex: 1,
    renderCell: (val) => getFormattedDate(val.value),
  },
  {
    field: 'subjectPropState',
    headerName: 'Subject Prop State',
    flex: 1,
  },
  {
    field: 'viewButton',
    headerName: '',
    flex: 1,
    renderCell: (d) => (
      <Button variant="contained" disableElevation component={Link} to={`/file/${d.row.id}`} target="_blank">
        View
      </Button>
    ),
    disableClickEventBubbling: true,
    align: 'center',
    headerClassName: 'table-heading--empty',
  },
];

export default function TableImports() {
  // Sets initial table page size on load
  const [pageSize, setPageSize] = React.useState(10);
  const { isLoading, /* isError, isSuccess, error, */ getImports } = useFileContext();
  const importRows = React.useMemo(() => getImports, [getImports]);

  const [sortModel, setSortModel] = React.useState([
    {
      field: 'importDate',
      sort: 'desc',
    },
  ]);

  return (
    <Paper
      sx={{
        minHeight: '300px',
        position: 'relative',

        // Tables
        '.MuiDataGrid-root': {
          '.MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
            '&:focus, &:focus-within': {
              outline: 'none',
            },
          },
          '.table-heading--empty *': {
            display: 'none',
          },
          '.MuiDataGrid-cell--textLeft': {
            paddingLeft: '16px',
          },
          '.MuiDataGrid-cell--textRight': {
            paddingLeft: '16px',
          },
          '.MuiDataGrid-columnsContainer': {
            borderRadius: '4px 4px 0 0',
            backgroundColor: '#dbdbdb',
          },
          '.MuiDataGrid-overlay': {
            paddingTop: '64px',
          },
        },
      }}
    >
      <DataGrid
        loading={isLoading}
        columns={importsTableColumns}
        rows={importRows}
        disableSelectionOnClick
        rowHeight={55}
        autoHeight
        pageSize={pageSize}
        onPageSizeChange={(ps) => setPageSize(ps)}
        rowsPerPageOptions={[10, 25, 50, 100]}
        pagination
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
      />
    </Paper>
  );
}
