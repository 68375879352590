/* eslint-disable no-nested-ternary */
import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import Box from '@mui/material/Box';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Autocomplete, Button, TextField, CircularProgress } from '@mui/material';
import ActionModal from './ActionModal';
import { stateOptions, quarterOptions } from '../selectOptions';
import { yearOptions, getDatesForSpecifiedQuarter } from '../utils/dateUtils';
import { updateFormState } from '../utils/formUtils';
import { useReportContext } from '../context/ReportContext';
import { useAuthContext } from '../context/AuthContext';
import { Quarter } from '../constants/enums';

const getValues = (data) => {
  const { state, year, quarter } = data;
  return {
    state: state.value,
    year: year.value,
    quarter: quarter.value,
  };
};

const getPreviousYearAndQuarter = (selectedQuarter, selectedYear) => {
  switch (selectedQuarter) {
    case Quarter.Q1.value: {
      return {
        quarter: Quarter.Q4.value,
        year: `${selectedYear - 1}`,
      };
    }
    case Quarter.Q2.value: {
      return {
        quarter: Quarter.Q1.value,
        year: `${selectedYear}`,
      };
    }
    case Quarter.Q3.value: {
      return {
        quarter: Quarter.Q2.value,
        year: `${selectedYear}`,
      };
    }
    case Quarter.Q4.value: {
      return {
        quarter: Quarter.Q3.value,
        year: `${selectedYear}`,
      };
    }
    default: {
      return null;
    }
  }
};

const PageActionsReports = (props) => {
  const { isAdmin } = useAuthContext();
  const { locked, setLockedState } = props;
  const {
    createReport: doReportGenerate,
    isGenerating,
    isSaving,
    reportUrl,
    reportDataAndFile,
    getReports,
    saveReport,
  } = useReportContext();
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [isConfirmSaveOpen, setIsConfirmSaveOpen] = useState(false);
  const [formState, setFormState] = useState({
    state: null,
    year: null,
    quarter: null,
  });
  const { state, year, quarter } = formState;

  const reportTitle = useMemo(
    () => `Report Preview for ${state?.value} ${quarter?.value} ${year?.value}`,
    [quarter, state, year]
  );

  const reportName = useMemo(
    () => `Mortgage Call Report for ${state?.value} ${quarter?.value} ${year?.value}.pdf`,
    [quarter, state, year]
  );

  const yOpts = useMemo(() => yearOptions(), []);

  const isModalDisabled = useMemo(() => {
    if (isGenerating || isSaving) return true;
    const { endDate: eD } = getDatesForSpecifiedQuarter(quarter?.value, year?.value);
    const now = DateTime.now();
    if (now > eD) {
      return !(now >= eD.plus({ days: 5 }));
    }
    return true;
  }, [isGenerating, isSaving, quarter, year]);

  const reportExists = useMemo(
    () =>
      getReports.filter((r) => r.quarter === quarter?.value && r.year === year?.value && r.state === state?.value)
        .length,
    [getReports, quarter, state, year]
  );

  const hasPreviousReportBeenSubmitted = useMemo(() => {
    const previousQ = getPreviousYearAndQuarter(quarter?.value, year?.value);
    if (!previousQ) return false;
    const len = getReports.filter(
      (r) => r.quarter === previousQ.quarter && r.year === previousQ.year && r.state === state?.value
    ).length;
    return !len;
  }, [getReports, quarter, state, year]);

  const modalMessage = useMemo(() => {
    if (hasPreviousReportBeenSubmitted)
      return `You cannot save the report for ${state?.value}, ${quarter?.value}, ${year?.value} because the report for the previous quarter has not been saved`;
    return `Save ${reportTitle}? Once this report is saved, you will not be able to change or delete it.`;
  }, [hasPreviousReportBeenSubmitted, reportTitle, quarter, state, year]);

  const reportGenerateModalProps = useMemo(
    () => ({
      modalTitle: reportExists ? null : reportTitle,
      modalContent:
        isGenerating || isSaving ? (
          <Box
            sx={{
              minHeight: '200px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        ) : reportExists ? null : (
          <Box
            sx={{
              minHeight: '75vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <embed
              type="application/pdf"
              name={reportName}
              src={reportUrl}
              title={reportTitle}
              style={{
                flex: 1,
                minHeight: '70vh',
                border: 'none',
                marginTop: '24px',
              }}
            />
          </Box>
        ),
      modalMessage: reportExists
        ? 'A report has already been saved for this quarter so another cannot be generated.'
        : null,
      modalMessageType: reportExists ? 'error' : '',
      modalCtaText: 'Save report',
      modalWidth: reportExists ? null : '90%',
      modalOpen: isPreviewOpen,
      modalOnClick: async () => {
        URL.revokeObjectURL(reportUrl);
        // const { reportData, fileBlob } = reportDataAndFile;
        // await saveReport(reportData, fileBlob);
        setIsPreviewOpen(false);
        setIsConfirmSaveOpen(true);
      },
      modalOnCancel: () => {
        setIsPreviewOpen(false);
        URL.revokeObjectURL(reportUrl);
      },
      modalCtaDisabled: isModalDisabled,
      showCta: !reportExists,
      modalCancelText: reportExists ? 'Close' : 'Cancel',
    }),
    [isGenerating, isModalDisabled, isPreviewOpen, isSaving, reportExists, reportName, reportTitle, reportUrl]
  );

  const confirmReportSaveModalProps = useMemo(
    () => ({
      modalMessage,
      modalMessageType: 'error',
      modalCtaText: 'Save report',
      modalWidth: '35vw',
      modalOpen: isConfirmSaveOpen,
      modalOnClick: async () => {
        const { reportData, fileBlob } = reportDataAndFile;
        await saveReport(reportData, fileBlob);
        setIsConfirmSaveOpen(false);
      },
      modalOnCancel: () => {
        setIsConfirmSaveOpen(false);
      },
      showCta: !hasPreviousReportBeenSubmitted,
      modalCancelText: hasPreviousReportBeenSubmitted ? 'Close' : 'Cancel',
    }),
    [hasPreviousReportBeenSubmitted, isConfirmSaveOpen, modalMessage, reportDataAndFile, saveReport]
  );

  return (
    <>
      <Box
        component="form"
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '30px',
          '.MuiInputLabel-asterisk': {
            display: 'none',
          },
          '.MuiDataGrid-cell--withRenderer': {
            marginRight: '10px',
          },
        }}
        id="generateForm"
        onSubmit={(e) => {
          e.preventDefault();
          if (e.target.reportValidity()) {
            setIsPreviewOpen(true);
            if (!reportExists) {
              doReportGenerate(getValues(formState));
            }
          }
        }}
      >
        <Autocomplete
          id="state"
          size="small"
          required
          disablePortal
          options={stateOptions}
          renderInput={(params) => <TextField {...params} label="State" required />}
          sx={{
            minWidth: '200px',
            display: 'inline-flex',
            marginRight: '20px',
          }}
          value={state}
          onChange={(_, v) => {
            updateFormState('state', v, formState, setFormState);
          }}
        />
        <Autocomplete
          id="quarter"
          size="small"
          required
          disablePortal
          options={quarterOptions}
          renderInput={(params) => <TextField {...params} label="Quarter" required />}
          sx={{
            minWidth: '200px',
            display: 'inline-flex',
            marginRight: '20px',
          }}
          value={quarter}
          onChange={(_, v) => {
            updateFormState('quarter', v, formState, setFormState);
          }}
        />
        <Autocomplete
          id="year"
          size="small"
          required
          disablePortal
          options={yOpts}
          renderInput={(params) => <TextField {...params} label="Year" required />}
          sx={{
            minWidth: '200px',
            display: 'inline-flex',
            marginRight: '20px',
          }}
          value={year}
          onChange={(_, v) => {
            updateFormState('year', v, formState, setFormState);
          }}
        />
        <Button variant="contained" disableElevation type="submit" form="generateForm">
          Generate Report
        </Button>

        {isAdmin && locked ? (
          <Button
            variant="contained"
            startIcon={<LockOpenOutlinedIcon />}
            disableElevation
            onClick={() => setLockedState(false)}
            sx={{
              marginLeft: 'auto',
            }}
          >
            Unlock
          </Button>
        ) : isAdmin ? (
          <Button
            variant="contained"
            startIcon={<LockOutlinedIcon />}
            disableElevation
            onClick={() => setLockedState(true)}
            sx={{
              marginLeft: 'auto',
            }}
          >
            Save and Lock
          </Button>
        ) : (
          ''
        )}
      </Box>

      <ActionModal {...reportGenerateModalProps} />
      <ActionModal {...confirmReportSaveModalProps} />
    </>
  );
};

PageActionsReports.propTypes = {
  locked: PropTypes.bool.isRequired,
  setLockedState: PropTypes.func.isRequired,
};

export default PageActionsReports;
