import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import PropTypes from 'prop-types';
import { Auth, API, graphqlOperation } from 'aws-amplify';
import { onAuthUIStateChange, AuthState } from '@aws-amplify/ui-components';
import { useAppContext } from 'context/AppContext';
import { cacheKeys } from '../conf';
import { getUser } from '../graphql/queries';

const AuthContext = React.createContext({
  isAuthenticated: false,
  authState: AuthState.SignedOut,
  authData: {},
  userData: {},
  tenantId: null,
  changeTenant: () => {},
  error: null,
  userFullName: null,
  refresh: () => {},
  isAdmin: false,
});

export const AuthProvider = ({ children }) => {
  const queryClient = useQueryClient();
  const { setIsAppLoading } = useAppContext();

  const [authState, setAuthState] = useState(AuthState.SignedOut);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [tenantId, setTenantId] = useState(null);
  const [userId, setUserId] = useState(null);
  const [authData, setAuthData] = useState(null);

  useEffect(() => {
    setIsAppLoading(true);
    const unsubscribe = onAuthUIStateChange((nextAuthState, data) => {
      setAuthState(nextAuthState);
      if (nextAuthState === AuthState.SignedIn && !!data) {
        const {
          attributes: { sub },
          signInUserSession: { idToken },
        } = data;
        // Because of Amplify's auth rules, we have to send the idToken with api requests
        API.configure({
          graphql_headers: () => ({
            Authorization: idToken.jwtToken,
          }),
        });
        setAuthData(data);
        setUserId(sub);
        setIsAuthenticated(true);
      }
    });
    return () => unsubscribe();
  }, [setIsAppLoading]);

  const {
    isError,
    isSuccess,
    error: queryError,
    data: response,
  } = useQuery(cacheKeys.getUser, () => API.graphql(graphqlOperation(getUser, { id: userId })), {
    enabled: isAuthenticated && !!userId,
    onSuccess: (res) => {
      const {
        getUser: { tenants, isAdmin: iA, isSystemAdmin },
      } = res.data;
      setTenantId(tenants[0]);
      setIsAppLoading(false);
      if (iA || isSystemAdmin) {
        setIsAdmin(true);
      }
    },
  });

  const userData = useMemo(() => (isSuccess ? response.data.getUser : {}), [isSuccess, response]);

  const logOut = useCallback(async () => {
    await Auth.signOut();
    queryClient.clear();
    setIsAuthenticated(false);
  }, [queryClient, setIsAuthenticated]);

  const refresh = useCallback(async () => {
    const currentSession = await Auth.currentSession();
    const identityJwt = currentSession.getIdToken().getJwtToken();
    API.configure({
      graphql_headers: () => ({
        Authorization: identityJwt,
      }),
    });
  }, []);

  const exportValue = useMemo(
    () => ({
      isAuthenticated,
      authState,
      authData,
      userData,
      tenantId,
      error: isError ? queryError : null,
      logOut,
      refresh,
      isAdmin,
    }),
    [authData, authState, isAdmin, isAuthenticated, isError, logOut, queryError, refresh, tenantId, userData]
  );

  return <AuthContext.Provider value={exportValue}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAuthContext = () => React.useContext(AuthContext);
