import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useAuthContext } from 'context/AuthContext';
import Files from './views/Files';
import Imports from './views/Imports';
import Reports from './views/Reports';
import File from './views/File';
import CreateFile from './views/CreateFile';
import FileEdit from './views/FileEdit';
import Auth from './views/Auth';
// import ReverseMortgage from './views/ReverseMortgage';
import CreateReverseMortgage from './views/CreateReverseMortgage';
import NoMatch from './views/NoMatch';
import { AlertProvider } from './context/AlertContext';
import { FileProvider } from './context/FileContext';
import { FileMutateProvider } from './context/FileMutateContext';
import { FileCreateProvider } from './context/FileCreateContext';
import { ReportProvider } from './context/ReportContext';

const App = () => {
  const { isAuthenticated } = useAuthContext();

  return isAuthenticated ? (
    <Router>
      <Routes>
        <Route
          exact
          path="/"
          element={
            <FileProvider>
              <AlertProvider>
                <Files />
              </AlertProvider>
            </FileProvider>
          }
        />
        <Route
          exact
          path="/imports"
          element={
            <FileProvider>
              <AlertProvider>
                <Imports />
              </AlertProvider>
            </FileProvider>
          }
        />
        <Route
          exact
          path="/reports"
          element={
            <AlertProvider>
              <ReportProvider>
                <Reports />
              </ReportProvider>
            </AlertProvider>
          }
        />
        <Route
          exact
          path="/file/:id"
          element={
            <FileProvider>
              <AlertProvider>
                <File />
              </AlertProvider>
            </FileProvider>
          }
        />
        <Route
          exact
          path="/file/:id/edit"
          element={
            <FileProvider>
              <AlertProvider>
                <FileMutateProvider>
                  <FileEdit />
                </FileMutateProvider>
              </AlertProvider>
            </FileProvider>
          }
        />
        <Route
          exact
          path="/create-file"
          element={
            <AlertProvider>
              <FileCreateProvider>
                <CreateFile />
              </FileCreateProvider>
            </AlertProvider>
          }
        />
        <Route
          exact
          path="/create-reverse-mortgage"
          element={
            <AlertProvider>
              <FileCreateProvider>
                <CreateReverseMortgage />
              </FileCreateProvider>
            </AlertProvider>
          }
        />
        <Route
          path="*"
          element={
            <AlertProvider>
              <NoMatch />
            </AlertProvider>
          }
        />
      </Routes>
    </Router>
  ) : (
    <Auth />
  );
};

export default App;
