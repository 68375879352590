/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'utils/index';
import FormFile from '../forms/FormFile';
import FormReverseMortgage from '../forms/FormReverseMortgage';
import Main from '../components/Main';
import PageActionsFile from '../components/PageActionsFile';
import { useFileContext } from '../context/FileContext';
import { useFileMutateContext } from '../context/FileMutateContext';

const FileEdit = () => {
  const { isLoading, getFile } = useFileContext();
  const { isLoading: isMutating, updateFile } = useFileMutateContext();
  const params = useParams();
  const { id } = params;

  const record = getFile(id);

  const hasData = useMemo(() => !isEmpty(record), [record]);

  const title = useMemo(() => {
    if (isLoading || !hasData) return `Loading File...`;
    return record && record.isReverseMortgage
      ? `Reverse mortgage file for ${record.borrowerFirstName} ${record.borrowerLastName}`
      : `Forward mortgage ${
          record.importFile
            ? `import: ${record.importFile}`
            : `for ${record.borrowerFirstName} ${record.borrowerLastName}`
        }`;
  }, [isLoading, record, hasData]);

  return (
    <Main pagetitle={title}>
      {isLoading || isMutating || !hasData ? null : (
        <PageActionsFile mode="EDIT" data={record} isLoading={isLoading || isMutating} formId="editFile" />
      )}
      {isLoading ? null : record.isReverseMortgage ? (
        <FormReverseMortgage
          mode="EDIT"
          data={record}
          isLoading={isLoading || isMutating}
          onSubmit={updateFile}
          formId="editFile"
        />
      ) : (
        <FormFile
          mode="EDIT"
          data={record}
          isLoading={isLoading || isMutating}
          onSubmit={updateFile}
          formId="editFile"
        />
      )}
    </Main>
  );
};

export default FileEdit;
