import { DateTime } from 'luxon';
import config from '../conf';

export const getNowDate = () => DateTime.fromJSDate(new Date()).setZone(config.timeZone).toISODate();

export const getNowAsUtcIso = () => DateTime.fromJSDate(new Date()).setZone('UTC').toISO();

export const convertJSDateForServer = (d) => DateTime.fromJSDate(d).setZone('UTC').toISODate();

export const convertJSDateTimeForServer = (d) => DateTime.fromJSDate(d).setZone('UTC').toISO();

/**
 * Converts datetime to app's time zone and
 * formats to the app's default Date format
 * @param datetime {string} ISO datetime string
 * @returns {string} The formatted date
 */
export const getFormattedDate = (datetime) =>
  datetime ? DateTime.fromISO(datetime).setZone(config.timeZone).toFormat(config.formats.date) : '';

/**
 * Converts datetime to app's time zone and
 * formats to the app's default Time format
 * @param datetime {string} ISO datetime string
 * @returns {string} The formatted date
 */
export const getFormattedTime = (datetime) =>
  datetime ? DateTime.fromISO(datetime).setZone(config.timeZone).toFormat(config.formats.time) : '';

/**
 * Converts date string to app's default format
 * @param dateStr {string} yyyy-MM-dd formatted date string
 * @returns {string} The formatted date
 */
export const getFormattedDateFromString = (dateStr) => {
  if (!dateStr) return null;
  const date = DateTime.fromFormat(dateStr.substr(0, 10), config.formats.dateSystem);
  return date.isValid ? date.toFormat(config.formats.date) : null;
};

export const getDatesForCurrentQuarter = () => {
  const now = DateTime.now();
  const quarter = now.quarter();
  return {
    startDate: now.startOf('quarter'),
    endDate: now.endOf('quarter'),
    quarter: `Q${quarter}`,
    year: now.year(),
  };
};

const getFirstMonthForQuarter = (q) => {
  switch (q) {
    case 'Q4':
      return 10;
    case 'Q3':
      return 7;
    case 'Q2':
      return 4;
    case 'Q1':
    default:
      return 1;
  }
};

export const getDatesForSpecifiedQuarter = (quarter, year) => {
  if (!quarter || !year) return {};
  const dt = DateTime.fromObject({
    year,
    day: 1,
    month: getFirstMonthForQuarter(quarter),
  });
  return {
    startDate: dt,
    endDate: dt.endOf('quarter'),
    quarter,
  };
};

export const yearOptions = () => {
  const dt = DateTime.now();
  const years = Array.from(Array(10)).map((_, idx) => {
    const shifted = dt.minus({ years: idx });
    return {
      label: `${shifted.year}`,
      value: `${shifted.year}`,
    };
  });
  return years;
};
