import React from 'react';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import PropTypes from 'prop-types';
import { AppBar, Box, Button, Toolbar, Typography } from '@mui/material';
import { useAuthContext } from 'context/AuthContext';

const Header = ({ pagetitle }) => {
  const { userData, logOut } = useAuthContext();
  return (
    <AppBar
      position="fixed"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        zIndex: 9999,
      }}
    >
      <Toolbar
        sx={{
          flex: '0 0 285px',
          display: 'flex',
          backgroundColor: '#145ca4',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            '& svg': {
              height: '30px',
            },
            h1: {
              marginLeft: '16px',
              fontWeight: 'bold',
            },
          }}
        >
          <svg viewBox="0 0 500 500" fill="#ffffff">
            <path
              d="M493.1,163.6c-0.9,50.5-7.5,99.5-24.1,146.7c-22.9,65.3-61.2,118.3-122.3,153c-34.4,19.6-71.7,30.2-110.9,34.4
            c-27.5,2.9-55,3.8-82.3-2.4c-77-17.5-135.4-81.5-145.3-159.8c-9.7-76.2,27.5-151.1,94.7-188.2c38.8-21.4,73.3-48.2,105.3-78.7
            c15.4-14.7,30.9-29.6,47.9-42.4c61.6-46.4,172.2-33.6,219.6,55.5C489.4,107.6,494.6,135.2,493.1,163.6z M255.6,371.8
            c-0.1,13.2,10.7,24.4,24,24.6c13.3,0.3,24.9-11.2,24.8-24.5c-0.1-13.2-11.1-24.2-24.4-24.2C266.8,347.6,255.8,358.6,255.6,371.8z
            M341,164.9c0,13.3,10.9,24.2,24.2,24.3c13.2,0.1,24.3-10.8,24.5-24.1c0.2-13.5-11-24.7-24.6-24.6C351.8,140.6,341,151.5,341,164.9z
            M231.1,238.2c0.1-13.4-10.5-24.3-23.9-24.5c-13.5-0.2-24.7,10.9-24.6,24.5c0.1,13.4,10.9,24.1,24.3,24.1
            C220.3,262.3,231.1,251.5,231.1,238.2z"
            />
          </svg>
          <Typography component="h1" variant="h5">
            MCR Potato
          </Typography>
        </Box>
      </Toolbar>
      <Toolbar
        sx={{
          flexGrow: 1,
        }}
      >
        <Typography
          component="h2"
          variant="h6"
          noWrap
          sx={{
            paddingLeft: '12px',
          }}
        >
          {pagetitle}
        </Typography>
      </Toolbar>
      <Toolbar>
        <Button variant="contained" endIcon={<LogoutOutlinedIcon />} disableElevation onClick={() => logOut()}>
          {userData.userName}
        </Button>
      </Toolbar>
    </AppBar>
  );
};

Header.propTypes = {
  pagetitle: PropTypes.string.isRequired,
};

// Header.defaultProps = {
//   pagetitle: '',
// };

export default Header;
