import React, { useState } from 'react';
import { BackupOutlined } from '@material-ui/icons';
import { DropzoneArea } from 'material-ui-dropzone';
import { Box, Button, Paper, CircularProgress } from '@mui/material';
import { parseFile } from '../utils/import';
import { isNull } from '../utils/index';
import { useFileImportContext } from '../context/FileImportContext';
import { useAuthContext } from '../context/AuthContext';

const readFile = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsText(file);
  });

const processFiles = async (files, tenantId, imports, setImports) => {
  const results = await Promise.all(
    files.map(async (it) => {
      try {
        const fileName = it.name;
        const contents = await readFile(it);
        const fileData = parseFile(contents, tenantId);
        if (!fileData) return null;
        const fileKey = `${tenantId}/${fileData.id}/${fileName}`;
        return {
          fileName,
          fileData,
          fileKey,
          fileObject: it,
        };
      } catch (e) {
        return null;
      }
    })
  );
  setImports(imports.concat(results.filter((it) => !isNull(it))));
};

const removeFile = async (file, imports, setImports) => {
  setImports(imports.filter((it) => it.fileName !== file.name));
};

const doImport = (imports, doFilesCreate) => doFilesCreate(imports);

const FormImport = () => {
  const { tenantId } = useAuthContext();
  const { isLoading, doFilesCreate } = useFileImportContext();
  const [imports, setImports] = useState([]);
  // dropzone component doesn't have a mechanism to clear its state so we use
  // a key that we can increment which makes React re-render the component :(
  const [dzKey, setDzKey] = useState(0);

  return (
    <Paper
      component="form"
      autoComplete="off"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        marginBottom: '30px',
        '> .MuiBox-root': {
          width: {
            xs: '100%',
            md: '70%',
            lg: '50%',
            xl: '40%',
          },
        },
      }}
    >
      {isLoading ? (
        <Box
          sx={{
            minHeight: '206px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box
            sx={{
              '.MuiDropzoneArea-root': {
                minHeight: '150px',
                display: 'flex',
                flexDirection: 'column',
                borderColor: '#c4c4c4',
              },
              '.MuiDropzoneArea-textContainer': {
                flex: '1 1 auto',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              },
              '.MuiTypography-h5': {
                fontSize: '16px',
                margin: '0 24px 5px 24px',
              },
              '.MuiDropzoneArea-icon': {
                fill: 'rgba(0, 0, 0, 0.54)',
                marginBottom: '-10px',
              },
              '.MuiDropzonePreviewList-root': {
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                bottom: 0,
                left: 0,
                margin: 0,
                backgroundColor: '#fff',
              },
            }}
          >
            <DropzoneArea
              key={dzKey}
              acceptedFiles={['text/xml']}
              filesLimit={1}
              Icon={BackupOutlined}
              dropzoneText="Drag and drop or select a file to upload"
              showFileNames
              showFileNamesInPreview
              useChipsForPreview
              showAlerts={['error']}
              onDelete={(deletedFile) => removeFile(deletedFile, imports, setImports)}
              onDrop={(files) => processFiles(files, tenantId, imports, setImports)}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              variant="contained"
              disableElevation
              sx={{
                minWidth: '140px',
                marginTop: '20px',
                verticalAlign: 'baseline',
              }}
              onClick={async () => {
                await doImport(imports, doFilesCreate);
                setDzKey(dzKey + 1);
                setImports([]);
              }}
              disabled={!imports.length || isLoading}
            >
              Import
            </Button>
          </Box>
        </>
      )}
    </Paper>
  );
};

export default FormImport;
