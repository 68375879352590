import React from 'react';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import { useLocation, Link } from 'react-router-dom';
import { Box, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

export default function NavMain() {
  const location = useLocation();

  return (
    <Box>
      <Drawer
        sx={{
          minWidth: 285,
          '.MuiDrawer-paper': {
            minWidth: 285,
            boxSizing: 'border-box',
            paddingTop: '64px',
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <List>
          <ListItem disablePadding>
            <ListItemButton component={Link} to="/" selected={location.pathname === '/'}>
              <ListItemIcon>
                <FileCopyOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Files" />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton component={Link} to="/imports" selected={location.pathname === '/imports'}>
              <ListItemIcon>
                <UploadFileOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Imports" />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton component={Link} to="/reports" selected={location.pathname === '/reports'}>
              <ListItemIcon>
                <AssignmentOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Reports" />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton component={Link} to="/create-file" selected={location.pathname === '/create-file'}>
              <ListItemIcon>
                <NoteAddOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Create File" />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton
              component={Link}
              to="/create-reverse-mortgage"
              selected={location.pathname === '/create-reverse-mortgage'}
            >
              <ListItemIcon>
                <HomeWorkOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Create Reverse Mortgage" />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
    </Box>
  );
}
