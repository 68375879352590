const awsConfig = {
  aws_project_region: 'us-east-1',
  aws_cognito_identity_pool_id: 'us-east-1:c8accb04-c62e-4831-9d41-8981b2db297c',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_Nl1V3rTFr',
  aws_user_pools_web_client_id: '57ojcgsomki36kev1729chrh80',
  oauth: {},
  aws_cognito_username_attributes: [],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: [],
  aws_cognito_mfa_configuration: 'OPTIONAL',
  aws_cognito_mfa_types: [],
  aws_cognito_password_protection_settings: {
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: [],
  aws_appsync_graphqlEndpoint: 'https://i7hhsz4ayzfwtmzauuum5uxekm.appsync-api.us-east-1.amazonaws.com/graphql',
  aws_appsync_region: 'us-east-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_appsync_apiKey: 'da2-fakeApiId123456',
  aws_appsync_dangerously_connect_to_http_endpoint_for_testing: false,
  aws_user_files_s3_bucket: 'mcr-files155750-prod',
  aws_user_files_s3_bucket_region: 'us-east-1',
  aws_user_files_s3_dangerously_connect_to_http_endpoint_for_testing: false,
  aws_cognito_login_mechanisms: ['PREFERRED_USERNAME'],
};

export default awsConfig;
