import React from 'react';
import { AmplifyAuthContainer, AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react';
import { CssBaseline, Box, Typography } from '@mui/material';

const Auth = () => (
  <Box
    component="main"
    sx={{
      background: '#f5f5f5',
    }}
  >
    <CssBaseline />
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -440px)',
        '& svg': {
          height: '76px',
        },
        h1: {
          marginTop: '16px',
          fontWeight: 'bold',
          fontSize: '3rem',
          color: '#145ca4',
        },
      }}
    >
      <svg viewBox="0 0 500 500" fill="#145ca4">
        <path
          d="M493.1,163.6c-0.9,50.5-7.5,99.5-24.1,146.7c-22.9,65.3-61.2,118.3-122.3,153c-34.4,19.6-71.7,30.2-110.9,34.4
        c-27.5,2.9-55,3.8-82.3-2.4c-77-17.5-135.4-81.5-145.3-159.8c-9.7-76.2,27.5-151.1,94.7-188.2c38.8-21.4,73.3-48.2,105.3-78.7
        c15.4-14.7,30.9-29.6,47.9-42.4c61.6-46.4,172.2-33.6,219.6,55.5C489.4,107.6,494.6,135.2,493.1,163.6z M255.6,371.8
        c-0.1,13.2,10.7,24.4,24,24.6c13.3,0.3,24.9-11.2,24.8-24.5c-0.1-13.2-11.1-24.2-24.4-24.2C266.8,347.6,255.8,358.6,255.6,371.8z
        M341,164.9c0,13.3,10.9,24.2,24.2,24.3c13.2,0.1,24.3-10.8,24.5-24.1c0.2-13.5-11-24.7-24.6-24.6C351.8,140.6,341,151.5,341,164.9z
        M231.1,238.2c0.1-13.4-10.5-24.3-23.9-24.5c-13.5-0.2-24.7,10.9-24.6,24.5c0.1,13.4,10.9,24.1,24.3,24.1
        C220.3,262.3,231.1,251.5,231.1,238.2z"
        />
      </svg>
      <Typography component="h1" variant="h5">
        MCR Potato
      </Typography>
    </Box>
    <AmplifyAuthContainer>
      <AmplifyAuthenticator>
        <AmplifySignIn slot="sign-in" hideSignUp />
      </AmplifyAuthenticator>
    </AmplifyAuthContainer>
  </Box>
);

export default Auth;
